import React from 'react'
import { color } from 'ui/src/styles/index'

export const CouponUsedIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.419 12.9905C24.872 12.4195 23.715 12.3585 23.661 12.3555C22.977 12.3205 22.305 12.3805 21.664 12.4965V9.9465H25.746C26.117 9.9465 26.419 10.2475 26.419 10.6195V12.9905ZM24.804 24.5975C24.804 25.0115 24.413 25.3605 23.951 25.3605H20.321V14.4975C21.282 14.1455 22.424 13.8715 23.578 13.9345C23.587 13.9355 24.072 13.9685 24.804 14.1495V24.5975ZM9.187 24.5975V17.6435C9.851 17.8895 10.617 18.0565 11.486 18.0565H11.5C11.518 18.0585 11.594 18.0635 11.724 18.0635C12.061 18.0635 12.769 18.0155 13.711 17.7635V25.3605H10.04C9.578 25.3605 9.187 25.0115 9.187 24.5975ZM7.582 10.6195C7.582 10.2475 7.884 9.9465 8.255 9.9465H12.367V16.4215C11.892 16.4855 11.595 16.4765 11.565 16.4775H11.558C9.375 16.4775 8.018 15.1885 7.582 14.7005V10.6195ZM11.599 7.3885C11.418 7.2075 11.418 6.8995 11.598 6.7195C11.78 6.5365 12.087 6.5375 12.27 6.7185L13.916 8.3645H12.575L11.599 7.3885ZM21.746 6.7195C21.929 6.5365 22.237 6.5385 22.416 6.7175C22.598 6.8995 22.598 7.2075 22.417 7.3885L21.441 8.3645H20.1L21.746 6.7195ZM19.231 13.2265C19.226 13.2295 19.221 13.2315 19.215 13.2335C17.891 13.7845 16.994 14.3985 16.918 14.4515C15.805 15.2575 14.789 15.7425 13.949 16.0355V9.9465H20.083V12.9055C19.782 13.0065 19.5 13.1155 19.231 13.2265ZM17.831 15.7415C17.841 15.7345 18.184 15.5015 18.739 15.2055V25.3605H15.292V17.1995C16.069 16.8595 16.919 16.4025 17.831 15.7415ZM25.746 8.3645H23.651C24.325 7.5595 24.292 6.3575 23.534 5.5995C22.735 4.8005 21.43 4.7995 20.629 5.6005L17.864 8.3645H16.152L13.387 5.5995C12.588 4.8005 11.283 4.8005 10.48 5.6015C9.724 6.3585 9.692 7.5595 10.365 8.3645H8.255C7.011 8.3645 6 9.3755 6 10.6195V14.9835C6 15.1515 6.054 15.3145 6.153 15.4505C6.192 15.5035 6.689 16.1675 7.606 16.8115V24.5975C7.606 25.8915 8.698 26.9425 10.04 26.9425H23.951C25.294 26.9425 26.385 25.8915 26.385 24.5975V14.6845C26.541 14.7515 26.698 14.8185 26.86 14.8985C27.106 15.0185 27.394 15.0045 27.628 14.8605C27.859 14.7165 28 14.4615 28 14.1885V10.6195C28 9.3755 26.989 8.3645 25.746 8.3645Z"
        fill={color.costaRed}
      />
    </svg>
  )
}
