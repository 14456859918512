import React from 'react'
import { Wrapper } from './RewardHint.styles'

interface Props {
  stampTotal: number
  stampCount: number
}

export default function RewardHint({ stampCount, stampTotal }: Props) {
  const rewardMessage =
    stampCount < stampTotal - 1
      ? `Collect ${stampTotal} beans and get a free cuppa!`
      : `One more bean to go, then it's free drink time!`

  return (
    <Wrapper>
      <span>{rewardMessage}</span>
    </Wrapper>
  )
}
