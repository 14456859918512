import React, { type ReactNode } from 'react'
import cx from 'classnames'

import Icon from '../../../nutrition/NutritionView/components/Icon/icon'
import ICONS from '../../../nutrition/NutritionView/resources/ICONS'

import { filterIconMapping } from './filterIconMapping'
import { StyledCustomiseDrink, DrinkCaption } from './customiseDrink.styles'
import type {
  ActiveVariant,
  AvailableVariants,
  FilterIconMappingCategory,
  VariantType,
  AvailableCustomisations,
  ActiveCustomisations,
  CustomisationType,
  Customisation,
} from '../../types'

type Props = {
  activeVariant: ActiveVariant
  availableVariants: AvailableVariants
  setVariant: (variantType: VariantType, variant: string) => void
  activeCustomisations: ActiveCustomisations
  availableCustomisations: AvailableCustomisations
  setCustomisation: (variantType: CustomisationType, customisation: Customisation) => void
}

export function CustomiseDrink(props: Props) {
  const {
    activeVariant,
    availableVariants,
    setVariant,
    activeCustomisations,
    availableCustomisations: { coffeeTypes, extraShots, syrups, extras },
    setCustomisation,
  } = props
  const { milkFilters, sizeFilters, syrupFilters } = filterIconMapping

  function getVariantButtons(filterList: FilterIconMappingCategory, type: VariantType): ReactNode {
    return filterList.map(filter => {
      const isEnabled = availableVariants[type] && availableVariants[type].includes(filter[0])

      if (!isEnabled) {
        return null
      }

      return (
        <button
          key={filter[0]}
          type="button"
          onClick={() => setVariant(type, isEnabled ? filter[0] : '')}
          value={filter[0]}
          disabled={!isEnabled}
          className={!isEnabled ? 'disabled' : ''}
        >
          <Icon
            icon={ICONS[`${type}_${filter[0]}` as keyof typeof ICONS]}
            w={40}
            h={50}
            wrapped
            className={`iconWrapper ${isEnabled ? 'available' : 'disabled'} ${
              activeVariant[type] && activeVariant[type] === filter[0] ? 'active' : null
            }`}
          />
          <span>{filter[1]}</span>
        </button>
      )
    })
  }

  return (
    <StyledCustomiseDrink>
      <h2>Customise your drink</h2>
      {availableVariants.size.some(v => v) && (
        <>
          <DrinkCaption>Size</DrinkCaption>
          <div className="filterGroup">{getVariantButtons(sizeFilters, 'size')}</div>
        </>
      )}
      {availableVariants.milk.some(v => v) && (
        <>
          <DrinkCaption>Milk</DrinkCaption>
          <div className="filterGroup">{getVariantButtons(milkFilters, 'milk')}</div>
        </>
      )}

      {/* Coffee type */}
      {coffeeTypes.length > 1 && (
        <>
          <DrinkCaption>Blend</DrinkCaption>
          <div className="filterGroup">
            {coffeeTypes.map(coffeeType => (
              <button
                key={coffeeType.id}
                type="button"
                value={coffeeType.name}
                onClick={() => setCustomisation('coffeeType', coffeeType)}
              >
                <Icon
                  icon={ICONS[`coffeeType_${coffeeType.name.toLowerCase()}` as keyof typeof ICONS]}
                  wrapped
                  className={cx('iconWrapper available', {
                    active: activeCustomisations.coffeeType?.name === coffeeType.name,
                  })}
                />
                <span>{coffeeType.name}</span>
              </button>
            ))}
          </div>
        </>
      )}

      {/* Extra Shots */}
      {extraShots.length > 1 && (
        <>
          <DrinkCaption>Extra Shots</DrinkCaption>
          <div className="filterGroup">
            {extraShots.map(extraShot => (
              <button
                key={extraShot.id}
                type="button"
                value={extraShot.name}
                onClick={() => setCustomisation('extraShot', extraShot)}
              >
                <Icon
                  icon={ICONS[`extraShots_${extraShot.name.toLowerCase()}` as keyof typeof ICONS]}
                  wrapped
                  className={cx('iconWrapper available', {
                    active: activeCustomisations.extraShot?.name === extraShot.name,
                  })}
                />
                <span>{extraShot.name}</span>
              </button>
            ))}
          </div>
        </>
      )}

      {/* Syrups */}
      {syrups.length > 1 && (
        <>
          <DrinkCaption>Syrups</DrinkCaption>
          <div className="filterGroup">
            {syrups.map(syrup => {
              const iconId = syrupFilters.find(filter => filter[0] === syrup.name)?.[1]
              const iconKey = iconId ? `syrup_${iconId}` : `extraShots_one`

              return (
                <button
                  key={syrup.id}
                  type="button"
                  value={syrup.name}
                  onClick={() => setCustomisation('syrup', syrup)}
                >
                  <Icon
                    icon={ICONS[iconKey as keyof typeof ICONS]}
                    wrapped
                    className={cx('iconWrapper available', {
                      active: activeCustomisations.syrup?.name === syrup.name,
                    })}
                  />
                  <span>{syrup.name}</span>
                </button>
              )
            })}
          </div>
        </>
      )}

      {/* Other Extras */}
      {extras.length > 1 && (
        <>
          <DrinkCaption>Other Extras</DrinkCaption>
          <div className="filterGroup">
            {extras.map(extra => (
              <button
                key={extra.id}
                type="button"
                value={extra.name}
                onClick={() => setCustomisation('extra', extra)}
              >
                <Icon
                  //  @TODO - this logic will need to be improved when we want to display more than just marshmallows as extras
                  icon={extra.name === 'None' ? ICONS.extras_none : ICONS.extras_marshmallow}
                  wrapped
                  className={cx('iconWrapper available', {
                    active: activeCustomisations.extra?.name === extra.name,
                  })}
                />
                <span>{extra.name}</span>
              </button>
            ))}
          </div>
        </>
      )}
    </StyledCustomiseDrink>
  )
}
