import React from 'react'
import AccountHead from './AccountHead'
import AccountBody from './AccountBody'
import type { Microcopy } from 'utils/src/microcopy'

interface IProps {
  microcopy: Microcopy
}

export default function Account({ microcopy }: IProps) {
  return (
    <>
      <AccountHead />
      <AccountBody microcopy={microcopy} />
    </>
  )
}
