import axios from 'axios'
import settingsHelper from 'utils/src/settings'

async function graphqlRequest(query, env, vars = {}) {
  const request = {
    method: 'POST',
    credentials: 'same-origin',
    headers: { 'content-type': 'application/json' },
    data: JSON.stringify({ query, vars }),
  }

  const response = await axios({
    url: `${settingsHelper.getBaseUrl(env, ['nutrition'])}`,
    ...request,
  })
  const responseBody = await response.data
  if (responseBody.errors) {
    const message = responseBody.errors.map(error => error.message).join('\n')
    throw new Error(message)
  }
  return responseBody.data
}

export async function getCategories(env) {
  const query = `
  query  {
    baristaProductTypes {
      items {
        id
        name
        baristaProductCategories {
          id
          name
          masterProducts {
            id
            productName
            productCode
            productDisplayName
            productDescription
            groupCode
          }
        }
      }
    }
  }`
  const { baristaProductTypes } = await graphqlRequest(query, env, {})

  return baristaProductTypes
}

export async function getProductsNutrition(productList, env) {
  const query = `
  query MasterProduct {
    masterProducts(groupCodes: [${productList.map(item => `"${item}"`)}]){
      items {
        id
        productName
        productDescription
        productDisplayName
        productCode
        groupCode
        nutritionProducts {
          id
          productName
          productCode
          effectiveFromDate
          ingredients
          variations {
            coffeeType
            milkSuffix
            milkType
            serviceDelivery
            size
          }
          dietaryChoices {
            suitableForVegetarians
            suitableForVegans
          }
          allergens {
            celery
            cereals {
              wheat
              rye
              barley
              oat
            }
            crustacean
            egg
            fish
            lupin
            milk
            mollusc
            mustard
            peanut
            sesame
            soya
            sulphite
            treeNuts
            treeNutSource
          }
          nutritionPer100g {
            carbohydrates
            energykCal
            energykJ
            fat
            fibre
            protein
            salt
            saturates
            sugars
            vitaminB6
            vitaminB12
            zinc
            vitaminC
          }
          nutritionPerPortion {
            portionWeight
            carbohydrates
            energykCal
            energykJ
            fat
            fibre
            protein
            salt
            saturates
            sugars
            vitaminB6
            vitaminB12
            vitaminC
            zinc
            portionWeight
          }
        }
      }
    }
  }
`
  const {
    masterProducts: { items },
  } = await graphqlRequest(query, env, {})

  return items
}
