import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'

import Layout from '@cw-monorepo/cw-gatsby/src/layout/layout'
import SEO from '@cw-monorepo/cw-gatsby/src/components/seo'
import { CssSpinner } from 'ui/src/CssSpinner'
import { LinkCta } from 'ui/src/link'
import { above, color, costaTextBold } from 'ui/src/styles'
import beansHeaderBgDesktop from '@cw-monorepo/cw-gatsby/src/images/account-area-hero-desktop.jpg'
import beansHeaderBgMobile from '@cw-monorepo/cw-gatsby/src/images/account-area-hero-mobile.jpg'

const hero = {
  __typename: 'ContentfulHero',
  heading: '',
  intro: ' ',
  megaHero: false,
  background: {
    file: {
      contentType: '',
      url: beansHeaderBgDesktop,
    },
  },
  mobileBackground: {
    file: {
      contentType: '',
      url: beansHeaderBgMobile,
    },
  },
}

interface IProps {
  location: Location
  locale?: string
  seoTitle: string
  heading: string
  copy: string
  buttonLabel: string
}

export function AccountChangedLayout(props: IProps) {
  const { location, seoTitle, heading, copy, buttonLabel } = props
  const { instance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const loaded = !isAuthenticated && inProgress === InteractionStatus.None

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      handleLogout()
    }
  }, [inProgress, isAuthenticated])

  async function handleLogout() {
    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: window.location.href,
      })
    } catch (error) {
      window.location.assign('/costa-club/account-home')
    }
  }

  return (
    <Layout hero={hero} location={location} noBackground={false}>
      <SEO title={seoTitle} noindex />
      {loaded ? (
        <Section>
          <Heading>{heading}</Heading>
          <p>{copy}</p>
          <LinkCta
            internalLink={{ path: '/costa-club/login' }}
            title={buttonLabel}
            alignment="center"
          />
        </Section>
      ) : (
        <CssSpinner />
      )}
    </Layout>
  )
}

const Section = styled.section`
  background-color: ${color.offWhite};
  margin-top: -250px;
  margin: -250px 10% 0;
  padding: 40px 10%;
  text-align: center;

  ${above.mobile`
    margin-left: 20%;
    margin-right: 20%;
  `}
`

const Heading = styled.h1`
  ${costaTextBold}
  color: ${color.costaRed};
`
