import React from 'react'
import styled from 'styled-components'
import { color, above, costaTextBold } from 'ui/src/styles/index'

import ProductItem from '../ProductItem/productItem'

export default class ProductList extends React.Component {
  state = { loading: false }

  render() {
    const { category, setActiveProduct, productPage } = this.props
    const { loading } = this.state

    return (
      <StyledProduct data-cy="category-list">
        <h2 className="categoryHeader" data-cy="category-header">
          {category.name}
        </h2>{' '}
        {loading && <div className="loading">Loading..</div>}
        <div className="container">
          {category.masterProducts.map(product => (
            <ProductItem
              product={product}
              key={product.id}
              setActiveProduct={setActiveProduct}
              productPage={productPage}
            />
          ))}
        </div>
      </StyledProduct>
    )
  }
}

const StyledProduct = styled.div`
  margin-top: 48px;

  ${above.tablet`
    margin-top: 72px;
    width: 560px;
  `}

  ${above.tabletLarge`
    width: 720px;
  `}

  ${above.desktopLarge`
    width: 1100px;
    margin-top: 96px;
  `}

  .container {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    ${above.mobile`
      margin: 0 16px;
    `}

    ${above.tablet`
      width: 100%;
      margin: 0;
      column-gap: 10px;
    `}

    ${above.tabletLarge`
      max-width: 720px;
      column-gap: 0;
    `}

    ${above.desktopLarge`
      max-width: 1100px;
    `}
  }

  .buttonContainer {
    text-align: center;
    margin-top: 44px;

    > button {
      background: ${color.costaRed};
    }

    ${above.tablet`
      text-align: left;
    `}
  }

  .categoryHeader {
    ${costaTextBold};
    font-size: 36px;
    line-height: 1.1;
    color: ${color.costaRed};
    margin: 48px 0 32px;

    ${above.mobile`
      margin: 48px 24px 32px;
    `}

    ${above.tablet`
      font-size: 48px;
      margin: 0 0 48px;
      max-width: none;
    `}
  }

  .loading {
    color: ${color.lightRed};
    font-size: 18px;
    text-align: center;
  }
`
