import React from 'react'
import RewardHint from './RewardHint'
import RewardMessage from './RewardMessage'
import Stamps from './Stamps'
import {
  AboveDesktop,
  BottomWrapper,
  CoffeeCupIcon,
  GridWrapper,
  SmallText,
  StampCountWrapper,
  ViewHistoryCTA,
  Wrapper,
} from './StampsCard.styles'
import { Heading } from './LoyaltyCard.styles'

import coffeeCupIcon from '../../../assets/stamp-coffee-cup.png'

interface Props {
  rewardCount: number
  stampTotal: number
  stamps: string[]
}

export default function StampsCard({ rewardCount, stamps, stampTotal = 8 }: Props) {
  return (
    <Wrapper>
      <AboveDesktop>
        <Heading>Beans total</Heading>
        <RewardHint stampCount={stamps.length} stampTotal={stampTotal} />
      </AboveDesktop>

      <GridWrapper>
        <StampCountWrapper>
          <span>{stamps.length}</span>
          <SmallText>/{stampTotal}</SmallText>
        </StampCountWrapper>
        <Stamps totalCount={stampTotal} stamps={stamps} />
        <CoffeeCupIcon src={coffeeCupIcon} alt="Coffee Cup" />
      </GridWrapper>

      <BottomWrapper>
        {rewardCount > 0 ? (
          <RewardMessage rewardCount={rewardCount} />
        ) : (
          <ViewHistoryCTA
            onClick={() => {
              document?.getElementById('tabs')?.scrollIntoView({ behavior: 'smooth' })
              document?.getElementById('history')?.click()
            }}
          >
            View history
          </ViewHistoryCTA>
        )}
      </BottomWrapper>
    </Wrapper>
  )
}
