import React from 'react'
import { color } from 'ui/src/styles/index'

interface SvgProps {
  active: boolean
}

export const HistoryIcon = (props: SvgProps) => {
  const { active } = props
  const activeColor = active ? color.lightRed : color.costaRed

  return (
    <svg width="48" height="52" viewBox="0 0 48 52">
      <g fill="none" fillRule="evenodd" strokeLinecap="round">
        <g stroke={activeColor} strokeWidth="3.333">
          <g>
            <g>
              <path
                d="M5.92 10.511c3.989-4.314 9.657-7.009 15.945-7.009 12.076 0 21.865 9.938 21.865 22.197 0 12.258-9.79 22.196-21.865 22.196C9.789 47.895 0 37.957 0 25.7"
                transform="translate(-511.000000, -1159.000000) translate(467.000000, 1160.999967) translate(46.000066, 0.000000)"
              />
              <path
                strokeLinejoin="round"
                d="M5.08 0L3.511 12.993 14.398 12.446M21.684 11.405L21.684 26.086 31.619 35.238"
                transform="translate(-511.000000, -1159.000000) translate(467.000000, 1160.999967) translate(46.000066, 0.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
