import React from 'react'
import { color } from 'ui/src/styles/index'

export const StampBeanSkeletonIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26">
      <path
        d="M19.624 19.617c-2.297 2.296-5.16 3.738-8.062 4.06a9.823 9.823 0 0 1-3.258-.167c1.4-.807 2.211-1.74 2.434-2.023.427-.448 1.978-2.408 2.38-7.509.08-1.229.642-4.78 2.316-6.497.024-.03 2.435-2.8 5.998-3 .016.016.034.03.05.044 3.652 3.65 2.82 10.42-1.858 15.092M6.376 6.382c2.297-2.297 5.161-3.738 8.062-4.06.404-.046.802-.068 1.191-.068 1.066 0 2.071.174 2.995.498-2.987 1.024-4.803 3.142-4.86 3.214-2.538 2.597-2.886 7.654-2.898 7.851-.364 4.634-1.745 6.096-1.743 6.096a1.204 1.204 0 0 0-.147.164c-.012.017-1.196 1.543-3.507 2.183a7.426 7.426 0 0 1-.95-.787c-3.653-3.649-2.82-10.419 1.857-15.09M23.08 2.93c-.123-.123-.259-.228-.388-.343-.039-.043-.083-.077-.128-.114C20.371.576 17.43-.279 14.188.08 10.78.46 7.44 2.13 4.78 4.787-.777 10.34-1.61 18.54 2.921 23.067 4.841 24.983 7.442 26 10.361 26c.476 0 .96-.027 1.451-.08 3.408-.38 6.75-2.052 9.41-4.709 5.555-5.55 6.39-13.752 1.857-18.28"
        fill={color.secondaryCoreLighter}
        fillRule="evenodd"
      />
    </svg>
  )
}
