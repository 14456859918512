export const printValue = data => {
  switch (data) {
    case 'No':
      return 'No'

    case 'Yes':
      return 'Yes'

    case 'Cross Contamination Risk':
      return 'C'

    default:
      return data
  }
}

export const infoTableRows = nutritionDataObject => {
  const rows = [
    [
      'Energy (kJ)',
      nutritionDataObject.nutritionPer100g.energykJ,
      nutritionDataObject.nutritionPerPortion.energykJ,
    ],
    [
      'Energy (kcal)',
      nutritionDataObject.nutritionPer100g.energykCal,
      nutritionDataObject.nutritionPerPortion.energykCal,
    ],
    [
      'Fat (g)',
      nutritionDataObject.nutritionPer100g.fat,
      nutritionDataObject.nutritionPerPortion.fat,
    ],
    [
      'of which is saturates (g)',
      nutritionDataObject.nutritionPer100g.saturates,
      nutritionDataObject.nutritionPerPortion.saturates,
    ],
    [
      'Carbohydrate (g)',
      nutritionDataObject.nutritionPer100g.carbohydrates,
      nutritionDataObject.nutritionPerPortion.carbohydrates,
    ],
    [
      'of which is sugars (g)',
      nutritionDataObject.nutritionPer100g.sugars,
      nutritionDataObject.nutritionPerPortion.sugars,
    ],
    [
      'Protein (g)',
      nutritionDataObject.nutritionPer100g.protein,
      nutritionDataObject.nutritionPerPortion.protein,
    ],
    [
      'Salt (g)',
      Number.parseFloat(nutritionDataObject.nutritionPer100g.salt).toFixed(2),
      Number.parseFloat(nutritionDataObject.nutritionPerPortion.salt).toFixed(2),
    ],
  ]
  if (
    nutritionDataObject.nutritionPer100g.fibre !== null &&
    nutritionDataObject.nutritionPer100g.fibre !== undefined &&
    nutritionDataObject.nutritionPer100g.fibre !== 0
  ) {
    rows.push([
      'Fibre (g)',
      nutritionDataObject.nutritionPer100g.fibre,
      nutritionDataObject.nutritionPerPortion.fibre,
    ])
  }
  if (
    nutritionDataObject.nutritionPer100g.zinc !== null &&
    nutritionDataObject.nutritionPer100g.zinc !== undefined &&
    nutritionDataObject.nutritionPer100g.zinc !== 0
  ) {
    rows.push([
      'Zinc (mg)',
      nutritionDataObject.nutritionPer100g.zinc,
      nutritionDataObject.nutritionPerPortion.zinc,
    ])
  }
  if (
    nutritionDataObject.nutritionPer100g.vitaminB3 !== null &&
    nutritionDataObject.nutritionPer100g.vitaminB3 !== undefined &&
    nutritionDataObject.nutritionPer100g.vitaminB3 !== 0
  ) {
    rows.push([
      'Vitamin B3 (mg)',
      nutritionDataObject.nutritionPer100g.vitaminB3,
      nutritionDataObject.nutritionPerPortion.vitaminB3,
    ])
  }
  if (
    nutritionDataObject.nutritionPer100g.vitaminB6 !== null &&
    nutritionDataObject.nutritionPer100g.vitaminB6 !== undefined &&
    nutritionDataObject.nutritionPer100g.vitaminB6 !== 0
  ) {
    rows.push([
      'Vitamin B6 (mg)',
      nutritionDataObject.nutritionPer100g.vitaminB6,
      nutritionDataObject.nutritionPerPortion.vitaminB6,
    ])
  }
  if (
    nutritionDataObject.nutritionPer100g.vitaminB12 !== null &&
    nutritionDataObject.nutritionPer100g.vitaminB12 !== undefined &&
    nutritionDataObject.nutritionPer100g.vitaminB12 !== 0
  ) {
    rows.push([
      'Vitamin B12 (µg)',
      nutritionDataObject.nutritionPer100g.vitaminB12,
      nutritionDataObject.nutritionPerPortion.vitaminB12,
    ])
  }
  if (
    nutritionDataObject.nutritionPer100g.vitaminC !== null &&
    nutritionDataObject.nutritionPer100g.vitaminC !== 0
  ) {
    rows.push([
      'Vitamin C (mg)',
      nutritionDataObject.nutritionPer100g.vitaminC,
      nutritionDataObject.nutritionPerPortion.vitaminC,
    ])
  }
  return rows
  // [
  //   "Caffeine",
  //   nutritionDataObject.nutrition.caffeine,
  //   nutritionDataObject.nutritionPerPortion.caffeine
  // ]
}

export const allergensTableRows = nutritionDataObject => [
  ['Soya Products', printValue(nutritionDataObject.allergens.soya)],
  ['Egg Products', printValue(nutritionDataObject.allergens.egg)],
  ['Milk Products', printValue(nutritionDataObject.allergens.milk)],
  ['Fish Products', printValue(nutritionDataObject.allergens.fish)],
  ['Crustacean Products', printValue(nutritionDataObject.allergens.crustacean)],
  ['Mollusc Products', printValue(nutritionDataObject.allergens.mollusc)],
  ['Sulphite Products', printValue(nutritionDataObject.allergens.sulphite)],
  ['Sesame Products', printValue(nutritionDataObject.allergens.sesame)],
  ['Celery Products', printValue(nutritionDataObject.allergens.celery)],
  ['Mustard Products', printValue(nutritionDataObject.allergens.mustard)],
  ['Lupin Products', printValue(nutritionDataObject.allergens.lupin)],
  ['Peanut Products', printValue(nutritionDataObject.allergens.peanut)],
  ['Tree Nuts Products', printValue(nutritionDataObject.allergens.treeNuts)],
  ['Tree Nut Source (if present)', nutritionDataObject.allergens.treeNutSource],
]

export const cerealsTableRows = nutritionDataObject => [
  ['Wheat Products', printValue(nutritionDataObject.allergens.cereals.wheat)],
  ['Rye Products', printValue(nutritionDataObject.allergens.cereals.rye)],
  ['Barley Products', printValue(nutritionDataObject.allergens.cereals.barley)],
  ['Oat Products', printValue(nutritionDataObject.allergens.cereals.oat)],
]

export const getChildren = (categoryName, data) => {
  return data.items.filter(item => item.name === categoryName)
}
