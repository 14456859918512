exports.components = {
  "component---packages-cw-gatsby-src-pages-404-js": () => import("./../../../../../packages/cw-gatsby/src/pages/404.js" /* webpackChunkName: "component---packages-cw-gatsby-src-pages-404-js" */),
  "component---packages-cw-gatsby-src-sites-uk-menu-js": () => import("./../../../../../packages/cw-gatsby/src/sites/uk/menu.js" /* webpackChunkName: "component---packages-cw-gatsby-src-sites-uk-menu-js" */),
  "component---packages-cw-gatsby-src-sites-uk-stores-nutrition-allergens-data-js": () => import("./../../../../../packages/cw-gatsby/src/sites/uk/stores/nutrition-allergens/data.js" /* webpackChunkName: "component---packages-cw-gatsby-src-sites-uk-stores-nutrition-allergens-data-js" */),
  "component---packages-cw-gatsby-src-templates-page-tsx": () => import("./../../../../../packages/cw-gatsby/src/templates/page.tsx" /* webpackChunkName: "component---packages-cw-gatsby-src-templates-page-tsx" */),
  "component---src-custom-b-2-c-account-costa-club-account-changed-password-tsx": () => import("./../../../src/custom/b2c-account/costa-club/account-changed/password.tsx" /* webpackChunkName: "component---src-custom-b-2-c-account-costa-club-account-changed-password-tsx" */),
  "component---src-custom-b-2-c-account-costa-club-account-home-tsx": () => import("./../../../src/custom/b2c-account/costa-club/account-home.tsx" /* webpackChunkName: "component---src-custom-b-2-c-account-costa-club-account-home-tsx" */),
  "component---src-custom-b-2-c-account-costa-club-login-tsx": () => import("./../../../src/custom/b2c-account/costa-club/login.tsx" /* webpackChunkName: "component---src-custom-b-2-c-account-costa-club-login-tsx" */),
  "component---src-custom-b-2-c-account-costa-club-logout-tsx": () => import("./../../../src/custom/b2c-account/costa-club/logout.tsx" /* webpackChunkName: "component---src-custom-b-2-c-account-costa-club-logout-tsx" */),
  "component---src-custom-b-2-c-account-costa-club-register-tsx": () => import("./../../../src/custom/b2c-account/costa-club/register.tsx" /* webpackChunkName: "component---src-custom-b-2-c-account-costa-club-register-tsx" */)
}

