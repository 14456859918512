import React, { useEffect } from 'react'
import { withPrefix } from 'gatsby'
import { useMsal } from '@azure/msal-react'
import { BrowserAuthError, InteractionStatus, IPublicClientApplication } from '@azure/msal-browser'
import { loginRequest } from 'cw-frontend/src/config/authConfig'
import Layout from '@cw-monorepo/cw-gatsby/src/layout/layout'
import SEO from '@cw-monorepo/cw-gatsby/src/components/seo'
import { CssSpinner } from 'ui/src/CssSpinner'

function LoginPage({ location }: { location: any }) {
  const { instance, inProgress } = useMsal()

  const handleLoginRedirect = (
    instance: IPublicClientApplication,
    inProgress: InteractionStatus
  ) => {
    if (inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest).catch(e => {
        if (e instanceof BrowserAuthError) {
          /**
           * If the user presses the back button when they are on the B2C login
           * by mistake they will end up on the app login url and stuck. This makes
           * sure they are redirected back to the home page
           * */
          window.location.href = withPrefix('/')
        }
      })
    }
  }

  useEffect(() => {
    handleLoginRedirect(instance, inProgress)
  }, [instance, inProgress])

  return (
    <Layout hero={null} location={location} noBackground={false}>
      <SEO title="Login | Costa Club | Costa Coffee" noindex />
      <CssSpinner />
    </Layout>
  )
}

export default LoginPage
