import React from 'react'
import SelectDropdown from '../SelectDropdown/selectDropdown'

function ProductSelect({ activeCategory = null, products = null, setProducts, selectProduct }) {
  return (
    <>
      <SelectDropdown
        title="Category"
        htmlId="category"
        defaultOption="Please select a category"
        options={activeCategory}
        onChange={setProducts}
      />
      <SelectDropdown
        title="Product"
        htmlId="product"
        defaultOption="Please select a product"
        options={products}
        onChange={selectProduct}
      />
    </>
  )
}

export default ProductSelect
