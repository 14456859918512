import { ActiveNutrition, Cereals } from '../types'

export const printValue = (data: string | undefined) => {
  switch (data) {
    case 'No':
      return 'No'

    case 'Yes':
      return 'Yes'

    case 'Cross Contamination Risk':
      return 'C'

    default:
      return data
  }
}

export const infoTableRows = (nutritionDataObject: ActiveNutrition) => {
  const rows = [
    [
      'Energy (kJ)',
      nutritionDataObject.nutritionPer100g.energykJ,
      nutritionDataObject.nutritionPerPortion.energykJ,
    ],
    [
      'Energy (kcal)',
      nutritionDataObject.nutritionPer100g.energykCal,
      nutritionDataObject.nutritionPerPortion.energykCal,
    ],
    [
      'Fat (g)',
      nutritionDataObject.nutritionPer100g.fat,
      nutritionDataObject.nutritionPerPortion.fat,
    ],
    [
      'of which is saturates (g)',
      nutritionDataObject.nutritionPer100g.saturates,
      nutritionDataObject.nutritionPerPortion.saturates,
    ],
    [
      'Carbohydrate (g)',
      nutritionDataObject.nutritionPer100g.carbohydrates,
      nutritionDataObject.nutritionPerPortion.carbohydrates,
    ],
    [
      'of which is sugars (g)',
      nutritionDataObject.nutritionPer100g.sugars,
      nutritionDataObject.nutritionPerPortion.sugars,
    ],
    [
      'Protein (g)',
      nutritionDataObject.nutritionPer100g.protein,
      nutritionDataObject.nutritionPerPortion.protein,
    ],
    [
      'Salt (g)',
      transformSalt(nutritionDataObject.nutritionPer100g.salt),
      transformSalt(nutritionDataObject.nutritionPerPortion.salt),
    ],
  ]

  if (validNutritionValue(nutritionDataObject.nutritionPer100g.fibre)) {
    rows.push([
      'Fibre (g)',
      nutritionDataObject.nutritionPer100g.fibre,
      nutritionDataObject.nutritionPerPortion.fibre,
    ])
  }

  if (validNutritionValue(nutritionDataObject.nutritionPer100g.zinc)) {
    rows.push([
      'Zinc (mg)',
      nutritionDataObject.nutritionPer100g.zinc,
      nutritionDataObject.nutritionPerPortion.zinc,
    ])
  }

  if (validNutritionValue(nutritionDataObject.nutritionPer100g.vitaminB3)) {
    rows.push([
      'Vitamin B3 (mg)',
      nutritionDataObject.nutritionPer100g.vitaminB3,
      nutritionDataObject.nutritionPerPortion.vitaminB3,
    ])
  }

  if (validNutritionValue(nutritionDataObject.nutritionPer100g.vitaminB6)) {
    rows.push([
      'Vitamin B6 (mg)',
      nutritionDataObject.nutritionPer100g.vitaminB6,
      nutritionDataObject.nutritionPerPortion.vitaminB6,
    ])
  }

  if (validNutritionValue(nutritionDataObject.nutritionPer100g.vitaminB12)) {
    rows.push([
      'Vitamin B12 (µg)',
      nutritionDataObject.nutritionPer100g.vitaminB12,
      nutritionDataObject.nutritionPerPortion.vitaminB12,
    ])
  }

  if (validNutritionValue(nutritionDataObject.nutritionPer100g.vitaminC)) {
    rows.push([
      'Vitamin C (mg)',
      nutritionDataObject.nutritionPer100g.vitaminC,
      nutritionDataObject.nutritionPerPortion.vitaminC,
    ])
  }

  return rows
}

export const infoTableRowsJoint = (nutritionDataObject: ActiveNutrition) => {
  const rows = [
    [
      'Energy (kJ)',
      nutritionDataObject.nutritionPer100g.energykJ,
      nutritionDataObject.inStoreNutrition?.energykJ,
      nutritionDataObject.takeawayNutrition?.energykJ,
    ],
    [
      'Energy (kcal)',
      nutritionDataObject.nutritionPer100g.energykCal,
      nutritionDataObject.inStoreNutrition?.energykCal,
      nutritionDataObject.takeawayNutrition?.energykCal,
    ],
    [
      'Fat (g)',
      nutritionDataObject.nutritionPer100g.fat,
      nutritionDataObject.inStoreNutrition?.fat,
      nutritionDataObject.takeawayNutrition?.fat,
    ],
    [
      'of which is saturates (g)',
      nutritionDataObject.nutritionPer100g.saturates,
      nutritionDataObject.inStoreNutrition?.saturates,
      nutritionDataObject.takeawayNutrition?.saturates,
    ],
    [
      'Carbohydrate (g)',
      nutritionDataObject.nutritionPer100g.carbohydrates,
      nutritionDataObject.inStoreNutrition?.carbohydrates,
      nutritionDataObject.takeawayNutrition?.carbohydrates,
    ],
    [
      'of which is sugars (g)',
      nutritionDataObject.nutritionPer100g.sugars,
      nutritionDataObject.inStoreNutrition?.sugars,
      nutritionDataObject.takeawayNutrition?.sugars,
    ],
    [
      'Protein (g)',
      nutritionDataObject.nutritionPer100g.protein,
      nutritionDataObject.inStoreNutrition?.protein,
      nutritionDataObject.takeawayNutrition?.protein,
    ],
    [
      'Salt (g)',
      transformSalt(nutritionDataObject.nutritionPer100g.salt),
      transformSalt(nutritionDataObject.inStoreNutrition?.salt),
      transformSalt(nutritionDataObject.takeawayNutrition?.salt),
    ],
  ]

  if (validNutritionValue(nutritionDataObject.nutritionPer100g.fibre)) {
    rows.push([
      'Fibre (g)',
      nutritionDataObject.nutritionPer100g.fibre,
      nutritionDataObject.inStoreNutrition?.fibre,
      nutritionDataObject.takeawayNutrition?.fibre,
    ])
  }

  if (validNutritionValue(nutritionDataObject.nutritionPer100g.zinc)) {
    rows.push([
      'Zinc (mg)',
      nutritionDataObject.nutritionPer100g.zinc,
      nutritionDataObject.inStoreNutrition?.zinc,
      nutritionDataObject.takeawayNutrition?.zinc,
    ])
  }

  if (validNutritionValue(nutritionDataObject.nutritionPer100g.vitaminB3)) {
    rows.push([
      'Vitamin B3 (mg)',
      nutritionDataObject.nutritionPer100g.vitaminB3,
      nutritionDataObject.inStoreNutrition?.vitaminB3,
      nutritionDataObject.takeawayNutrition?.vitaminB3,
    ])
  }

  if (validNutritionValue(nutritionDataObject.nutritionPer100g.vitaminB6)) {
    rows.push([
      'Vitamin B6 (mg)',
      nutritionDataObject.nutritionPer100g.vitaminB6,
      nutritionDataObject.inStoreNutrition?.vitaminB6,
      nutritionDataObject.takeawayNutrition?.vitaminB6,
    ])
  }

  if (validNutritionValue(nutritionDataObject.nutritionPer100g.vitaminB12)) {
    rows.push([
      'Vitamin B12 (µg)',
      nutritionDataObject.nutritionPer100g.vitaminB12,
      nutritionDataObject.inStoreNutrition?.vitaminB12,
      nutritionDataObject.takeawayNutrition?.vitaminB12,
    ])
  }

  if (validNutritionValue(nutritionDataObject.nutritionPer100g.vitaminC)) {
    rows.push([
      'Vitamin C (mg)',
      nutritionDataObject.nutritionPer100g.vitaminC,
      nutritionDataObject.inStoreNutrition?.vitaminC,
      nutritionDataObject.takeawayNutrition?.vitaminC,
    ])
  }

  return rows
}

export const allergensTableRows = (nutritionDataObject: ActiveNutrition) => {
  const allergensArray = [
    'celery',
    'crustacean',
    'egg',
    'fish',
    'milk',
    'mollusc',
    'mustard',
    'lupin',
    'peanut',
    'sesame',
    'soya',
    'sulphite',
    'treeNuts',
    'treeNutSource',
  ]
  const cerealAllergensArray = ['barley', 'oat', 'rye', 'wheat']

  const capitalizeFirstLetter = (inputString: string) =>
    inputString.charAt(0).toUpperCase() + inputString.slice(1)

  const getLabel = (allergen: string) => {
    switch (allergen) {
      case 'treeNuts':
        return 'Tree Nuts Products'

      case 'treeNutSource':
        return 'Tree Nut Source'

      case 'barley':
      case 'oat':
      case 'rye':
      case 'wheat':
        return `Cereal containing gluten – ${capitalizeFirstLetter(allergen)}`

      default:
        return `${capitalizeFirstLetter(allergen) + ' Products'}`
    }
  }

  const processAllergens = (allergensArray: string[], allergensData: { [key: string]: string }) => {
    return allergensArray
      .filter(allergen => {
        const value = allergensData[allergen]
        // Tree nut source data (when not present) is sometimes 'null' and sometimes ' '
        return value !== 'No' && value !== null && value.trim() !== ''
      })
      .map(allergen => [getLabel(allergen), printValue(allergensData[allergen])])
  }
  const allergens = processAllergens(allergensArray, nutritionDataObject.allergens)
  const cereals = processAllergens(cerealAllergensArray, nutritionDataObject.allergens.cereals)

  return [...allergens, ...cereals]
}

export const cerealsTableRows = (
  nutritionDataObject: Partial<{
    allergens: Partial<{
      cereals: Cereals
    }>
  }>
) => [
  ['Wheat Products', printValue(nutritionDataObject?.allergens?.cereals?.wheat)],
  ['Rye Products', printValue(nutritionDataObject?.allergens?.cereals?.rye)],
  ['Barley Products', printValue(nutritionDataObject?.allergens?.cereals?.barley)],
  ['Oat Products', printValue(nutritionDataObject?.allergens?.cereals?.oat)],
]

interface CategoryData {
  name: string
  webProductCategories: {
    [key: string]: any
    masterProducts: { [key: string]: any; isActive?: boolean }[]
  }[]
}

export const getChildren = (categoryName: string, data: CategoryData[]) =>
  data.filter(item => item.name === categoryName).map(mapCategoryWithActiveProducts)

function mapCategoryWithActiveProducts(category: CategoryData) {
  return {
    ...category,
    webProductCategories: category.webProductCategories.map(productCategory => ({
      ...productCategory,
      masterProducts: productCategory.masterProducts.filter(
        masterProduct => masterProduct.isActive
      ),
    })),
  }
}

function validNutritionValue(value: string | number | null | undefined) {
  return value !== undefined && value !== null && value !== 0
}

function transformSalt(value: string | number | null | undefined) {
  if (value === null || value === undefined) return undefined

  return Number.parseFloat(String(value)).toFixed(2)
}
