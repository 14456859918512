import React, { useEffect } from 'react'
import { PageProps } from 'gatsby'
import { useIsAuthenticated } from '@azure/msal-react'

import Layout from '@cw-monorepo/cw-gatsby/src/layout/layout'
import SEO from '@cw-monorepo/cw-gatsby/src/components/seo'
import { RegisterContent } from 'cw-frontend/src/components/register/content'
import { getMicrocopyObject, useMicrocopy } from 'utils/src/microcopy'

export default function Register(props: PageProps) {
  const { location } = props
  const locale = process.env.GATSBY_DEFAULT_LOCALE ?? ''
  const microcopy = getMicrocopyObject(useMicrocopy(locale))
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    if (isAuthenticated) {
      window.location.assign('/costa-club/account-home')
    }
  }, [isAuthenticated])

  return (
    <Layout hero={null} location={location} noBackground={false}>
      <SEO
        title={microcopy.costaClub.register.seoTitle}
        description={microcopy.costaClub.register.seoDescription}
        noindex
      />
      <RegisterContent microcopy={microcopy} />
    </Layout>
  )
}
