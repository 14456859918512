import type {
  Product,
  ActiveVariant,
  ActiveNutrition,
  ActiveCustomisations,
  Nutrition,
  DietaryChoices,
} from '../../types'

function round(number: number): number {
  return Number(number.toPrecision(3))
}

export function getActiveNutrition({
  product,
  activeVariant,
  activeCustomisations,
}: {
  product: Product
  activeVariant: ActiveVariant
  activeCustomisations: ActiveCustomisations
}): ActiveNutrition | null {
  const { nutrition } = product
  const baseSize = activeVariant.size

  const variantNutrition =
    nutrition.filter(
      variant =>
        variant.variations.size === activeVariant.size &&
        variant.variations.milkType === activeVariant.milk
    ) || null

  if (variantNutrition.length === 0) {
    return null
  }

  if (variantNutrition.length === 1) {
    return addCustomisations({
      baseSize,
      baseNutrition: variantNutrition[0],
      activeCustomisations,
    })
  }

  const takeAwayNutrition = addCustomisations({
    baseSize,
    baseNutrition: findVariantNutrition(variantNutrition, 'Takeaway'),
    activeCustomisations,
  })

  const inStoreNutrition = addCustomisations({
    baseSize,
    baseNutrition: findVariantNutrition(variantNutrition, 'Eat In'),
    activeCustomisations,
  })

  if (!inStoreNutrition) {
    return addCustomisations({
      baseSize,
      baseNutrition: variantNutrition.find(variant => !variant.variations.coffeeType),
      activeCustomisations,
    })
  }

  return {
    ...inStoreNutrition,
    takeawayPortionWeight: takeAwayNutrition?.nutritionPerPortion?.portionWeight || null,
    takeawayNutrition: takeAwayNutrition?.nutritionPerPortion,
    inStorePortionWeight: inStoreNutrition?.nutritionPerPortion?.portionWeight || null,
    inStoreNutrition: inStoreNutrition?.nutritionPerPortion,
  }
}

function findVariantNutrition(variants: Nutrition[], serviceDelivery: string) {
  const filteredVariants = variants.filter(
    variant => variant.variations.serviceDelivery === serviceDelivery
  )
  const variantUnknownCoffee = filteredVariants.find(variant => !variant.variations.coffeeType)

  if (variantUnknownCoffee) return variantUnknownCoffee

  return filteredVariants[0]
}

export function addCustomisations({
  baseSize,
  baseNutrition,
  activeCustomisations,
}: {
  baseSize: string | null
  baseNutrition: Nutrition | undefined
  activeCustomisations: ActiveCustomisations
}): Nutrition | null {
  //  If no active customisations have nutrition data, just return the base nutrition
  const hasActiveCustomisations = (
    Object.keys(activeCustomisations) as (keyof ActiveCustomisations)[]
  ).some(customisationType => activeCustomisations[customisationType]?.nutrition !== null)
  if (!hasActiveCustomisations || !baseNutrition) {
    return baseNutrition ?? null
  }

  //  Otherwise, caclulate the total nutrition per portion including the customisations
  const totalNutritionPerPortion = { ...baseNutrition.nutritionPerPortion }
  const customisationTypes = Object.keys(activeCustomisations) as (keyof ActiveCustomisations)[]

  customisationTypes.forEach(customisationType => {
    if (!activeCustomisations[customisationType]?.nutrition) {
      return
    }
    //  Find the customisation with the same size as the base drink, or just return the first available if a size match is not found
    const customisationNutrition =
      activeCustomisations[customisationType]?.nutrition?.find(
        customisation => customisation.variations.size === baseSize
      ) || activeCustomisations[customisationType]?.nutrition[0]

    if (customisationNutrition?.nutritionPerPortion) {
      const nutritionTypes = Object.keys(customisationNutrition?.nutritionPerPortion || {})
      nutritionTypes.forEach(nutritionType => {
        if (!totalNutritionPerPortion[nutritionType]) {
          totalNutritionPerPortion[nutritionType] = 0
        }
        totalNutritionPerPortion[nutritionType]! +=
          customisationNutrition?.nutritionPerPortion[nutritionType] || 0
        totalNutritionPerPortion[nutritionType] = round(totalNutritionPerPortion[nutritionType]!)
      })
    }
  })

  //  Compile the total allergen data across base drink plus customisations
  const totalAllergens = { ...baseNutrition.allergens }
  customisationTypes.forEach(customisationType => {
    if (!activeCustomisations[customisationType]?.nutrition) {
      return
    }
    //  Find the customisation with the same size as the base drink, or just return the first available if a size match is not found
    const customisationNutrition =
      activeCustomisations[customisationType]?.nutrition.find(
        customisation => customisation.variations.size === baseSize
      ) || activeCustomisations[customisationType]?.nutrition[0]

    const allergenTypes = Object.keys(customisationNutrition?.allergens || {})
    allergenTypes.forEach(allergenType => {
      if (allergenType === 'cereals') {
        return {
          barley: combineAllergens(
            totalAllergens[allergenType].barley,
            customisationNutrition?.allergens[allergenType].barley
          ),
          oat: combineAllergens(
            totalAllergens[allergenType].oat,
            customisationNutrition?.allergens[allergenType].oat
          ),
          rye: combineAllergens(
            totalAllergens[allergenType].rye,
            customisationNutrition?.allergens[allergenType].rye
          ),
          wheat: combineAllergens(
            totalAllergens[allergenType].wheat,
            customisationNutrition?.allergens[allergenType].wheat
          ),
        }
      }
      if (allergenType === 'treeNutSource') {
        //  @TODO - check what is required here - concatenate all tree nut sources?
        return
      }
      totalAllergens[allergenType] = combineAllergens(
        totalAllergens[allergenType],
        customisationNutrition?.allergens[allergenType]
      )
    })
  })

  const totalDietaryChoices = combineDietaryChoices({
    baseSize,
    baseNutrition,
    activeCustomisations,
  })

  return Object.assign({}, baseNutrition, {
    allergens: totalAllergens,
    dietaryChoices: totalDietaryChoices,
    nutritionPerPortion: totalNutritionPerPortion,
  })
}

//  Compare allergens and return the most severe result
export function combineAllergens(
  allergen1: string | undefined,
  allergen2: string | undefined
): string {
  if ([allergen1, allergen2].includes('Yes')) {
    return 'Yes'
  }
  if ([allergen1, allergen2].includes('Cross Contamination Risk')) {
    return 'Cross Contamination Risk'
  }
  return 'No'
}

//  Check for any non-vegan or non-vegetarian customisations
export function combineDietaryChoices({
  baseSize,
  baseNutrition,
  activeCustomisations,
}: {
  baseSize: string | null
  baseNutrition: Nutrition
  activeCustomisations: ActiveCustomisations
}): DietaryChoices {
  const totalDietaryChoices = { ...baseNutrition.dietaryChoices }
  const customisationTypes = Object.keys(activeCustomisations) as (keyof ActiveCustomisations)[]
  customisationTypes.forEach(customisationType => {
    if (!activeCustomisations[customisationType]?.nutrition) {
      return
    }
    //  Find the customisation with the same size as the base drink, or just return the first available if a size match is not found
    const customisationNutrition =
      activeCustomisations[customisationType]?.nutrition.find(
        customisation => customisation.variations.size === baseSize
      ) || activeCustomisations[customisationType]?.nutrition[0]

    //  If the base drink is suitable for vegetarians / vegans but one or more customisation aren't, the value to display becomes the string returned for the first non-vegetarian / non-vegan customisation
    if (
      totalDietaryChoices.suitableForVegans === 'Yes' &&
      customisationNutrition?.dietaryChoices.suitableForVegans &&
      customisationNutrition?.dietaryChoices.suitableForVegans !== 'Yes'
    ) {
      totalDietaryChoices.suitableForVegans =
        customisationNutrition.dietaryChoices.suitableForVegans
    }

    if (
      totalDietaryChoices.suitableForVegetarians === 'Yes' &&
      customisationNutrition?.dietaryChoices.suitableForVegetarians &&
      customisationNutrition?.dietaryChoices.suitableForVegetarians !== 'Yes'
    ) {
      totalDietaryChoices.suitableForVegetarians =
        customisationNutrition.dietaryChoices.suitableForVegetarians
    }
  })

  return totalDietaryChoices
}
