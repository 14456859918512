import React from 'react'
import styled, { keyframes } from 'styled-components'

const skBouncedelay = keyframes`
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1.0); }
`

const Circle = styled.div<{ color?: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: ${props => props.color};
    border-radius: 100%;
    animation: ${skBouncedelay} 1.2s infinite ease-in-out both;
  }

  ${Array.from({ length: 12 }).map(
    (_, i) => `
    &:nth-child(${i + 1}) {
      transform: rotate(${30 * i}deg);
      &:before {
        animation-delay: ${-1.1 + i * 0.1}s;
      }
    }
  `
  )}
`

const SpinnerStyles = styled.div<{ size?: string }>`
  width: ${props => props.size};
  height: ${props => props.size};
  position: relative;
  margin: 0 auto;
`

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
`

function SpinWrapper({ children }: { children: React.ReactNode }) {
  return (
    <SpinnerWrapper>
      <SpinnerContainer>{children}</SpinnerContainer>
    </SpinnerWrapper>
  )
}

interface SpinnerProps {
  color?: string
  size?: string
  wrap?: boolean
}

export function CssSpinner(props: SpinnerProps) {
  const { size = '80px', color = '#333', wrap = false } = props

  const Spinner = () => (
    <SpinnerStyles size={size} role="spinner">
      {Array.from({ length: 12 }).map((_, i) => (
        <Circle key={i} color={color} />
      ))}
    </SpinnerStyles>
  )

  return wrap ? (
    <SpinWrapper>
      <Spinner />
    </SpinWrapper>
  ) : (
    <Spinner />
  )
}
