/**
 * Creates a promise that resolves after a specified delay with jitter.
 *
 * @param {number} ms The base number of milliseconds to wait before the promise is resolved.
 * @returns {Promise<void>} A promise that resolves after the specified delay plus a random jitter.
 */
export const delay = async (ms: number): Promise<void> => {
  const jitter = Math.random() * 1000
  const retryIn = ms + jitter
  console.info(`Retrying in ${retryIn}ms...`)

  return new Promise(resolve => setTimeout(resolve, retryIn))
}
