import React from 'react'
import styled from 'styled-components'

import { color, costaText, costaTextBold, above, costaTextItalic } from 'ui/src/styles/index'
import environmentsHelper from 'utils/src/environments'

import { getCategories, getProductsNutrition } from './helpers/GraphQL'
import PageSelect from './components/PageSelect/pageSelect'
import ProductSelect from './components/ProductSelect/productSelect'
import FoodNutrition from './components/FoodNutrition/foodNutrition'
import DrinkNutrition from './components/DrinkNutrition/drinkNutrition'
import DrinkFilters from './containers/DrinkFilters/drinkFilters'
import NutritionTable from './components/NutritionTable/nutritionTable'
import SelectDropdown from './components/SelectDropdown/selectDropdown'
import AllergenInfo from './components/AllergenInfo/allergenInfo'
import {
  infoTableRows,
  allergensTableRows,
  cerealsTableRows,
  getChildren,
} from './helpers/nutritionTableHelpers'
import infoIcon from '../../../assets/icons/info-icon.svg'

export default class NutritionView extends React.Component {
  static defaultProps = {
    nutritionSmallPrint: '',
  }

  state = {
    activePage: null, // SRTING of "food" or "drink"
    food: null, // ARRAY of food categories and products
    drinks: null, // ARRAY of drink categories and products
    drinkExtras: null, // ARRAY of drink extras products
    drinkIngredients: null, // not in the database as yet - ARRAY of drink ingredient products
    products: null, // ARRAY of products in a selected category
    activeProduct: null, // OBJECT of the selected product
    enabledFilters: {
      size: null,
      milk: null,
      serviceDelivery: null,
    }, // only on drinks page
    activeFilters: {
      size: null,
      milk: null,
      serviceDelivery: null,
    }, // only on drinks page
    disabledFilters: [],
    activeNutrition: null, // OBJECT of the selected product's nutrition
    extrasNutrition: null, // OBJECT of the selected extra's nutrition
    ingredientsNutrition: null, // OBJECT of the selected ingredient's nutrition
    loading: true,
  }

  async componentDidMount() {
    const env = environmentsHelper.getEnvironment()
    const rootData = await getCategories(env)
    const drinksArray = getChildren('Drink', rootData)

    const baristaDrinkCategories = drinksArray[0].baristaProductCategories
    const drinkExtrasIndex = baristaDrinkCategories.findIndex(
      category => category.name === 'Drinks Extras'
    )
    const drinkExtras = baristaDrinkCategories[drinkExtrasIndex].masterProducts
    const updatedDrinkExtras = await getProductsNutrition(drinkExtras.map(p => p.groupCode))

    const drinkIngredientsIndex = baristaDrinkCategories.findIndex(
      category => category.name === 'Drink Ingredients'
    )
    const drinkIngredients = baristaDrinkCategories[drinkIngredientsIndex].masterProducts
    const updatedDrinkIngredients = await getProductsNutrition(
      drinkIngredients.map(p => p.groupCode)
    )

    const food = getChildren('Food', rootData)
    const drinks = getChildren('Drink', rootData)

    this.setState({
      food,
      drinks,
      drinkExtras: updatedDrinkExtras,
      drinkIngredients: updatedDrinkIngredients,
      loading: false,
    })
  }

  filterNutritions = (nutritionsArray, filtersObj) => {
    const filtersArray = Object.entries(filtersObj).filter(filteredBy => filteredBy[1])
    let filteredNutritions = nutritionsArray
    filtersArray.forEach(filter => {
      filteredNutritions = filteredNutritions.filter(nutrition => {
        nutrition.variations.milk = nutrition.variations.milkType
        return nutrition.variations[filter[0]] === filter[1]
      })
    })
    return filteredNutritions
  }

  getAvailableFilters = nutritionsArray => {
    const filters = {}
    const filtersAvailable = nutritionsArray.map(nutrition => nutrition.variations)
    const filtersFlat = [].concat(...filtersAvailable)

    filters.milk = new Set()
    filters.size = new Set()
    filters.serviceDelivery = new Set()

    filtersFlat.forEach(filter => {
      if (filter && filter.milkType) {
        filters.milk.add(filter.milkType)
      }
      if (filter && filter.size) {
        filters.size.add(filter.size)
      }
      if (filter && filter.serviceDelivery) {
        filters.serviceDelivery.add(filter.serviceDelivery)
      }
    })

    return filters
  }

  filtersToArray = filtersObject =>
    [].concat(
      ...Object.keys(filtersObject).map(filter =>
        [...filtersObject[`${filter}`]].map(value => value)
      )
    )

  selectProductHandler = async event => {
    const { products, activePage } = this.state
    const productId = event.target.value

    if (!productId) {
      this.setState({
        activeProduct: null,
        activeNutrition: null,
        enabledFilters: { milk: null, size: null, serviceDelivery: null },
        disabledFilters: [],
        extrasNutrition: null,
        ingredientsNutrition: null,
        activeFilters: { milk: null, size: null, serviceDelivery: null },
      })
      return
    }

    let activeProduct = null
    let activeNutrition = null
    let enabledFilters = null

    const selectedProduct = products.find(product => product.id === productId)
    const updateProducts = await getProductsNutrition([selectedProduct.groupCode])

    for (let i = 0; i < updateProducts.length; i += 1) {
      if (updateProducts[i].id === productId) {
        activeProduct = updateProducts[i]
        activeNutrition = this.findNutrition(activeProduct.nutritionProducts, activePage)
        enabledFilters = this.getAvailableFilters(activeProduct.nutritionProducts)
      }
    }

    this.setState({
      activeProduct,
      activeNutrition,
      enabledFilters,
      disabledFilters: [],
      extrasNutrition: null,
      ingredientsNutrition: null,
      activeFilters: { milk: null, size: null, serviceDelivery: null },
    })
  }

  setFilterHandler = (type, filter) => {
    const { activeFilters, activeProduct, enabledFilters } = this.state
    let nutritions = []
    const updatedActiveFilters = { ...activeFilters }

    if (filter) {
      if (activeFilters[type] !== filter) {
        updatedActiveFilters[type] = filter
        nutritions = this.filterNutritions(activeProduct.nutritionProducts, updatedActiveFilters)
      } else {
        updatedActiveFilters[type] = null
        nutritions = this.filterNutritions(activeProduct.nutritionProducts, updatedActiveFilters)
      }
      let activeNutrition
      if (nutritions.length === 1) {
        activeNutrition = nutritions[0]
      }
      this.getDisabledFilters(activeProduct.nutritionProducts, updatedActiveFilters, enabledFilters)
      this.setState({
        activeFilters: updatedActiveFilters,
        activeNutrition,
      })
    }
  }

  // Extras nutrition
  selectNutritionHandler = (type, e) => {
    const nutritionId = e.target.value
    const { drinkExtras, drinkIngredients } = this.state

    let nutritionData = null
    if (type === 'extras') {
      for (let i = 0; i < drinkExtras.length; i += 1) {
        if (drinkExtras[i].id === nutritionId) {
          nutritionData = drinkExtras[i].nutritionProducts[0]
        }
      }
      this.setState({
        extrasNutrition: nutritionData,
      })
    }

    if (type === 'ingredients') {
      for (let i = 0; i < drinkIngredients.length; i += 1) {
        if (drinkIngredients[i].id === nutritionId) {
          nutritionData = drinkIngredients[i].nutritionProducts[0]
        }
      }
      this.setState({
        ingredientsNutrition: nutritionData,
      })
    }
  }

  findNutrition = (nutritionsArray, page) => {
    if (page === 'food') {
      return nutritionsArray[0]
    }

    if (nutritionsArray.length === 1) {
      this.setState({
        // this needs to go, temporary setState
        enabledFilters: { milk: null, size: null, serviceDelivery: null },
      })
      return nutritionsArray[0]
    }

    return null
  }

  setProductsHandler = e => {
    const { activePage } = this.state
    const categoryId = e.target.value
    const activeCategory = this.state[`${activePage}`]

    let products = null
    for (let i = 0; i < activeCategory[0].baristaProductCategories.length; i += 1) {
      if (activeCategory[0].baristaProductCategories[i].id === categoryId) {
        products = activeCategory[0].baristaProductCategories[i].masterProducts
      }
    }
    this.setState({
      products,
      activeProduct: null,
      nutrition: null,
      activeNutrition: null,
      extrasNutrition: null,
      ingredientsNutrition: null,
      enabledFilters: { milk: null, size: null, serviceDelivery: null },
      activeFilters: { milk: null, size: null, serviceDelivery: null },
      disabledFilters: [],
    })
  }

  setCategoriesHandler = (pageName, activePage) => {
    if (pageName === activePage) {
      return
    }
    this.setState({
      activePage: pageName,
      products: null,
      activeProduct: null,
      activeNutrition: null,
      extrasNutrition: null,
      ingredientsNutrition: null,
      enabledFilters: { milk: null, size: null, serviceDelivery: null },
      activeFilters: { milk: null, size: null, serviceDelivery: null },
      disabledFilters: [],
    })
  }

  getDisabledFilters = (nutritionsArray, activeFilters, enabledFilters) => {
    const disabledFilters = []

    Object.keys(activeFilters).forEach(activeKey => {
      const reducedActiveFilters = { milkType: null, size: null, serviceDelivery: null }
      reducedActiveFilters[`${activeKey}`] = activeFilters[`${activeKey}`]
      const updatedNutritionsArray = this.filterNutritions(nutritionsArray, reducedActiveFilters)

      const updatedAvailableFilters = this.filtersToArray(
        this.getAvailableFilters(updatedNutritionsArray, reducedActiveFilters)
      )

      Object.keys(enabledFilters).forEach(key => {
        if (!reducedActiveFilters[`${key}`]) {
          ;[...enabledFilters[`${key}`]].forEach(filter => {
            if (!updatedAvailableFilters.includes(filter)) {
              disabledFilters.push(filter)
            }
          })
        }
      })
    })
    this.setState({ disabledFilters })
  }

  render() {
    const { nutritionSmallPrint } = this.props
    const {
      loading,
      activePage,
      products,
      drinkExtras,
      drinkIngredients,
      activeProduct,
      activeFilters,
      enabledFilters,
      disabledFilters,
      activeNutrition,
      extrasNutrition,
      ingredientsNutrition,
    } = this.state

    return (
      <NutritionWrapper>
        <StyledNutritionView>
          {loading ? (
            <div className="loading">Loading..</div>
          ) : (
            <PageSelect setCategories={this.setCategoriesHandler} activePage={activePage} />
          )}

          <ProductSelect
            activeCategory={
              this.state[`${activePage}`] && this.state[`${activePage}`][0].baristaProductCategories
            }
            products={products}
            setProducts={this.setProductsHandler}
            selectProduct={this.selectProductHandler}
          />

          {activePage === 'food' && (
            <FoodNutrition
              activeNutrition={activeNutrition}
              productName={activeProduct && activeProduct.productDisplayName}
            />
          )}

          {activePage === 'drinks' && (
            <DrinkFilters
              activeFilters={activeFilters}
              enabledFilters={enabledFilters}
              disabledFilters={disabledFilters}
              setFilter={this.setFilterHandler}
            />
          )}

          {activePage === 'drinks' && activeNutrition && (
            <>
              <DrinkNutrition
                activeProduct={activeProduct}
                activeNutrition={activeNutrition}
                productName={
                  activeProduct && (activeProduct.productDisplayName || activeProduct.productName)
                }
              />

              <SelectDropdown
                title="Extras"
                htmlId="extras"
                defaultOption="Please select a category"
                options={activeProduct && drinkExtras}
                onChange={e => this.selectNutritionHandler('extras', e)}
              />

              {extrasNutrition && (
                <div data-cy="extra-product-info">
                  {(extrasNutrition.dietaryChoices.suitableForVegetarians === 'No' ||
                    extrasNutrition.dietaryChoices.suitableForVegans === 'No' ||
                    extrasNutrition.allergens.treeNuts === 'Yes') && (
                    <AllergenInfo activeNutrition={extrasNutrition} />
                  )}
                  <NutritionTable
                    title="Cereals Containing Gluten"
                    display="twoCol"
                    rows={cerealsTableRows(extrasNutrition)}
                  />
                  <NutritionTable
                    title="Allergens Present"
                    display="twoCol"
                    rows={allergensTableRows(extrasNutrition)}
                  />
                  <NutritionTable
                    title="Nutritional Information"
                    display="threeCol"
                    headings={[
                      null,
                      'per 100g/ml',
                      `per portion (${extrasNutrition.nutritionPerPortion.portionWeight}g)`,
                    ]}
                    rows={infoTableRows(extrasNutrition)}
                  />
                </div>
              )}

              <SelectDropdown
                title="Ingredients"
                htmlId="ingredients"
                defaultOption="Please select a category"
                options={activeProduct && drinkIngredients}
                onChange={e => this.selectNutritionHandler('ingredients', e)}
              />

              {ingredientsNutrition && (
                <div data-cy="ingredient-product-info">
                  {(ingredientsNutrition.vegan ||
                    ingredientsNutrition.vegetarian ||
                    ingredientsNutrition.treeNuts) && (
                    <AllergenInfo activeNutrition={ingredientsNutrition} />
                  )}
                  <NutritionTable
                    title="Cereals Containing Gluten"
                    display="twoCol"
                    rows={cerealsTableRows(ingredientsNutrition)}
                  />
                  <NutritionTable
                    title="Allergens Present"
                    display="twoCol"
                    rows={allergensTableRows(ingredientsNutrition)}
                  />
                  <NutritionTable
                    title="Nutritional Information"
                    display="threeCol"
                    headings={[
                      null,
                      'Per 100g/ml',
                      `Per portion (${ingredientsNutrition.nutritionPerPortion.portionWeight}g)`,
                    ]}
                    rows={infoTableRows(ingredientsNutrition)}
                  />
                </div>
              )}
            </>
          )}
          <CalorieMessage>
            <div className="calorieMessageWrapper">
              <img src={infoIcon} alt="information icon" className="calorieMessageIcon" />
              <p className="calorieMessageText">Adults need around 2000 kcal a day</p>
            </div>
          </CalorieMessage>
          <SmallPrint dangerouslySetInnerHTML={{ __html: nutritionSmallPrint }} />
        </StyledNutritionView>
      </NutritionWrapper>
    )
  }
}

const NutritionWrapper = styled.div`
  margin: 0;

  h1 {
    letter-spacing: normal;
    color: ${color.costaRed};
  }

  h2 {
    font-stretch: normal;
    letter-spacing: normal;
    color: ${color.costaRed};
  }

  h3 {
    color: ${color.costaRed};
    margin-bottom: 10px;
  }

  strong {
    ${costaTextBold};
  }

  .loading {
    color: ${color.lightRed};
    font-size: 18px;
    text-align: center;
  }

  button {
    display: inline-block;
    background: none;
    border: none;
    cursor: pointer;
    ${costaText};
    font-size: 16px;

    &:focus {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
    }
  }
`

const StyledNutritionView = styled.div`
  margin: 0 auto;
  max-width: 824px;
  width: 100%;
  background: ${color.white};
  padding: 41px 20px;

  ${costaText};
  color: ${color.greyDarker};

  box-sizing: border-box;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;

  h1 {
    font-size: 30px;
    line-height: 1.1;
  }
  h3 {
    font-size: 22px;
    line-height: 1.6;
  }

  h5 {
    font-size: 18px;
  }

  ${above.tablet`
    padding: 84px;

    h1 {
       font-size: 50px;
     }

    h3 {
      font-size: 30px;
    }

    h5 {
      font-size: 24px;
    }
  `}
`

const SmallPrint = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.57;
  letter-spacing: normal;
  margin-bottom: 30px;
  p {
    margin-bottom: 30px;
  }

  b {
    ${costaTextBold};
  }
`

const CalorieMessage = styled.div`
  ${costaTextItalic};
  .calorieMessageWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 0px 16px 0px;

    ${above.tablet`
      margin: 0px 0px 32px 0px;
    `}

    ${above.desktopLarge`
      margin: 0px 0px 40px 0px;
      height: 16px;
    `}
  }
  .calorieMessageIcon {
    padding-right: 4px;
  }

  .calorieMessageText {
    font-size: 12px;
    padding-top: 2px;
    ${above.tablet`
      font-size: 14px;
    `}
  }
`
