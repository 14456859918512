import React from 'react'
import styled from 'styled-components'
import { color, costaText, above, costaTextBold } from 'ui/src/styles/index'

import arrowDownRed from '../../../resources/nutrition/arrow-down-red.svg'
import arrowDownDisabled from '../../../resources/nutrition/arrow-down-disabled.svg'

const SelectDropdown = ({
  title = null,
  htmlId = null,
  handleChange = null,
  defaultOption = null,
  options = null,
}) => (
  <StyledSelectDropdown>
    <h5>
      <label htmlFor={htmlId}>{title}</label>
    </h5>
    <select
      name={htmlId}
      id={htmlId}
      onChange={e => handleChange(e.target.value)}
      disabled={!options}
      data-cy="category-dropdown"
    >
      <option>{defaultOption}</option>
      {options &&
        options[0].webProductCategories.map(
          item =>
            item.masterProducts.length > 0 && (
              <option value={item.id} key={item.id}>
                {item.name}
              </option>
            )
        )}
    </select>
  </StyledSelectDropdown>
)

export default SelectDropdown

const StyledSelectDropdown = styled.div`
  text-align: left;
  margin: 40px 0;

  ${above.mobile`
    margin: 48px 24px;
  `}

  ${above.tablet`
    width: 560px;
    margin: 48px 0;
  `}

  h5 {
    ${costaTextBold}
    font-size: 16px;
    display: inline-block;
    margin: 0 30px 8px 0;
    color: ${color.costaRed};
    width: 100px;

    ${above.tablet`
      font-size: 18px;
    `}

    &.disabled {
      color: ${color.greyCore};
    }
  }

  select {
    display: inline-block;
    width: 100%;
    height: 56px;
    border: 2px solid ${color.greyCore};
    border-radius: 30px;
    color: ${color.greyDarker};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 18px;
    background-color: ${color.white};
    ${costaText};
    padding: 3px 50px 0 25px;
    overflow: hidden;
    cursor: pointer;
    background-image: url(${arrowDownRed});
    background-size: 14px 11px;
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;

    &:focus {
      outline: none;
      box-shadow: rgb(207, 168, 202) 0px 0px 8px 2px;
      appearance: none;
      background-color: $white;
    }

    &:disabled {
      color: ${color.greyCore};
      border: 2px solid ${color.greyCore};
      background-color: $white;
      background-image: url(${arrowDownDisabled});
      cursor: not-allowed;
      /* IE11 */
      &::-ms-value {
        background: none;
        color: ${color.greyCore};
      }
    }

    /* IE11 */
    &::-ms-expand {
      display: none;
    }
    &::-ms-value {
      background: none;
      color: ${color.greyDarker};
    }
  }
`
