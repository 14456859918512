import React from 'react'
import { useTransactions } from '../../contexts/TransactionContext'
import { CssSpinner } from 'ui/src/CssSpinner'
import { color } from 'ui/src/styles'
import { Status } from '../../utils/api/fetch'
import { Wrapper } from './AccountHistory.styles'

import { BeanEarnedIcon } from 'cw-frontend/src/svg/BeanEarnedIcon'
import { GreenBeanEarnedIcon } from 'cw-frontend/src/svg/GreenBeanEarnedIcon'
import { FreeDrinkEarnedIcon } from 'cw-frontend/src/svg/FreeDrinkEarnedIcon'
import { FreeDrinkUsedIcon } from 'cw-frontend/src/svg/FreeDrinkUsedIcon'
import { CouponUsedIcon } from 'cw-frontend/src/svg/CouponUsedIcon'
import { PrizeWonIcon } from 'cw-frontend/src/svg/PrizeWonIcon'

const formatDate = (date: string) => {
  const parsedDate = new Date(date)

  if (isNaN(parsedDate.getTime())) return 'Date Unknown'

  return parsedDate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  })
}

const saveButtonState = (state: Status, fetchMore: () => Promise<void>) => {
  switch (state) {
    case Status.Updating:
      return (
        <div className="saveButton">
          <CssSpinner size="30px" color={color.offWhite} />
        </div>
      )
    default:
      return (
        <div className="saveButton" onClick={() => fetchMore()}>
          Load more
        </div>
      )
  }
}

const icon = (key: CostaApi.Loyalty.TransactionType | undefined) => {
  switch (key) {
    case 'freeDrinkRedeemed':
      return <FreeDrinkUsedIcon />
    case 'freeDrinkCoupon':
      return <FreeDrinkEarnedIcon />
    case 'greenBeansEarned':
      return <GreenBeanEarnedIcon />
    case 'bonusBeansEarned':
    case 'gameBeanEarned':
      return <PrizeWonIcon />
    case 'couponUsed':
      return <CouponUsedIcon />
    default:
      return <BeanEarnedIcon />
  }
}

const rewardBuilder = (reward: CostaApi.Loyalty.RewardInternal, index: number) => {
  return (
    <div className="transactionWrapper" key={`reward${index}`}>
      <div className='icon'>{icon(reward.key)}</div>
      <div>
        <p>
          {reward.title}: <span>{reward.label}</span>
        </p>
        {reward.location && <p className="location">{reward.location}</p>}
      </div>
    </div>
  )
}

export default function AccountHistory() {
  const { state, transactions, fetchMoreTransactions, hasMore } = useTransactions()

  return (
    <Wrapper>
      {transactions && Object.keys(transactions).length > 0 ? (
        <>
          <dl className="transactionsList">
            {Object.keys(transactions).map((date, i) => (
              <div key={`transaction${i}`} className="transactionsContainer">
                <dt>
                  <h4>{formatDate(date)}</h4>
                </dt>
                <dd>{transactions[date].map(rewardBuilder)}</dd>
              </div>
            ))}
          </dl>
          {hasMore && (
            <div className="submitGroup">{saveButtonState(state, fetchMoreTransactions)}</div>
          )}
        </>
      ) : (
        <div className="submitGroup">
          <p className="history">no history available</p>
        </div>
      )}
    </Wrapper>
  )
}
