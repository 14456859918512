import React, { createContext, useState, useContext, useEffect } from 'react'
import { getCustomerAccount } from '../utils/api/loyalty'

export interface UserContextType {
  user: CostaApi.Accounts.CustomerAccountResponse | undefined
  setUser: (user: CostaApi.Accounts.CustomerAccountResponse | undefined) => void
  loading: boolean
}

export const UserContext = createContext<UserContextType>({
  user: undefined,
  setUser: () => {},
  loading: true,
})

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [userInfo, setUserInfo] = useState<CostaApi.Accounts.CustomerAccountResponse | undefined>(
    undefined
  )
  const [loading, setLoading] = useState(true)

  const setUser = (data: CostaApi.Accounts.CustomerAccountResponse | undefined) => {
    setUserInfo(data)
  }

  useEffect(() => {
    async function fetchData() {
      const userData = await getCustomerAccount()

      if (userData) {
        setLoading(false)
        setUser(userData)
      }
    }

    fetchData()
  }, [])

  const value = { user: userInfo, setUser, loading }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useAccountDetail = () => useContext(UserContext)
