import React from 'react'
import { HashRouter as Router } from 'react-router-dom'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { AccountProviders } from 'cw-frontend/src/contexts/AccountProviders'
import { useAccountAccess } from 'cw-frontend/src/hooks/useAccountAccess'
import { loginRequest } from 'cw-frontend/src/config/authConfig'
import Account from 'cw-frontend/src/components/account/Account'
import Layout from '@cw-monorepo/cw-gatsby/src/layout/layout'
import SEO from '@cw-monorepo/cw-gatsby/src/components/seo'
import { CssSpinner } from 'ui/src/CssSpinner'

import beansHeaderBgDesktop from '@cw-monorepo/cw-gatsby/src/images/account-area-hero-desktop.jpg'
import beansHeaderBgMobile from '@cw-monorepo/cw-gatsby/src/images/account-area-hero-mobile.jpg'
import { withPrefix } from 'gatsby'
import { getMicrocopyObject, useMicrocopy } from 'utils/src/microcopy'

const hero = {
  __typename: 'ContentfulHero',
  heading: '',
  intro: ' ',
  megaHero: false,
  background: {
    file: {
      contentType: '',
      url: beansHeaderBgDesktop,
    },
  },
  mobileBackground: {
    file: {
      contentType: '',
      url: beansHeaderBgMobile,
    },
  },
}

const AccountContent = ({ location }: { location: any }) => {
  const { ready } = useAccountAccess({ location })
  const microcopy = getMicrocopyObject(useMicrocopy(process.env.GATSBY_DEFAULT_LOCALE))

  return (
    <Layout hero={hero} location={location}>
      <SEO title="Account management | Costa Club | Costa Coffee" noindex />
      {ready ? (
        <Router>
          <AccountProviders>
            <Account microcopy={microcopy} />
          </AccountProviders>
        </Router>
      ) : (
        <CssSpinner />
      )}
    </Layout>
  )
}

function AccountHomePage({ location }: { location: any }) {
  const authRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={() => {
        /**
         * If something goes wrong then lets just redirect back to home
         * as we have no other plan.
         * */
        window.location.href = withPrefix('/')
        return null
      }}
      loadingComponent={() => <CssSpinner wrap />}
    >
      <AccountContent location={location} />
    </MsalAuthenticationTemplate>
  )
}

export default AccountHomePage
