import React from 'react'
import { color } from 'ui/src/styles/index'

export const FreeDrinkEarnedIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_378_2551)">
        <path
          d="M20.9902 20.49C20.5802 20.49 20.2402 20.83 20.2402 21.24V23.24H18.2402C17.8302 23.24 17.4902 23.58 17.4902 23.99C17.4902 24.4 17.8302 24.74 18.2402 24.74H20.2402V26.74C20.2402 27.15 20.5802 27.49 20.9902 27.49C21.4002 27.49 21.7402 27.15 21.7402 26.74V24.74H23.7402C24.1502 24.74 24.4902 24.4 24.4902 23.99C24.4902 23.58 24.1502 23.24 23.7402 23.24H21.7402V21.24C21.7402 20.83 21.4002 20.49 20.9902 20.49Z"
          fill={color.costaRed}
        />
        <path
          d="M20.99 16.98C20.99 16.98 20.89 16.98 20.85 16.98L21.51 9.21H21.59C22.53 9.21 23.3 8.44 23.3 7.5V5.05C23.3 4.11 22.54 3.35 21.61 3.34V2.63C21.61 1.64 20.8 0.83 19.86 0.83L6.49 0C5.5 0 4.69 0.81 4.69 1.8V3.36C3.76 3.36 3 4.13 3 5.07V7.52C3 8.46 3.77 9.23 4.71 9.23H4.79L6.12 24.78C6.21 25.86 7.16 26.7 8.28 26.7H14.53C15.59 29.23 18.08 31 20.99 31C24.86 31 28 27.86 28 23.99C28 20.12 24.86 16.98 20.99 16.98ZM6.19 1.8C6.19 1.64 6.33 1.5 6.44 1.5L19.8 2.35C19.96 2.35 20.1 2.49 20.1 2.65V3.36H6.19V1.8ZM4.71 7.72C4.6 7.72 4.5 7.63 4.5 7.51V5.06C4.5 4.95 4.59 4.85 4.71 4.85H21.6C21.71 4.85 21.81 4.94 21.81 5.06V7.51C21.81 7.62 21.72 7.72 21.6 7.72H4.71ZM7.61 24.65L6.3 9.22H20.01L19.33 17.19C16.27 17.94 13.99 20.7 13.99 23.99C13.99 24.4 14.03 24.8 14.1 25.2H8.28C7.95 25.2 7.64 24.94 7.61 24.65ZM20.08 17.05C20.08 17.05 20.08 17.05 20.07 17.05C20.07 17.05 20.07 17.05 20.08 17.05ZM14.27 25.95C14.27 25.95 14.27 25.96 14.27 25.97C14.27 25.97 14.27 25.96 14.27 25.95ZM20.99 29.5C17.95 29.5 15.49 27.03 15.49 23.99C15.49 20.95 17.96 18.48 20.99 18.48C24.02 18.48 26.49 20.95 26.49 23.99C26.49 27.03 24.02 29.5 20.99 29.5Z"
          fill={color.costaRed}
        />
      </g>
      <defs>
        <clipPath id="clip0_378_2551">
          <rect width="25" height="31" fill="none" transform="translate(3)" />
        </clipPath>
      </defs>
    </svg>
  )
}
