import { Product, ActiveVariant, AvailableVariants } from '../../types'

export function getInitialVariant({
  availableVariants,
}: {
  product: Product
  availableVariants: AvailableVariants
}): ActiveVariant {
  //  Default to the first available size
  const size = availableVariants.size[0] || null

  //  Default to semi-skimmed if it's a valid option, otherwise just the first available
  const milk =
    availableVariants.milk.find(variant => variant === 'Semi-Skimmed') ||
    availableVariants.milk[0] ||
    null

  return {
    size,
    milk,
  }
}
