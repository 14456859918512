import styled from 'styled-components'
import { above, baseBodyText, color, costaTextBold } from 'ui/src/styles'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h4 {
    font-size: 18px;
    line-height: 28px;
    text-transform: uppercase;
    margin: 0 0 16px 0;

    ${above.tablet`
      margin: 0 0 24px 0;
    `}
  }

  .transactionsList {
    width: 100%;

    ${above.tablet`
      width: 600px;
    `}
  }

  .transactionsContainer {
    border-bottom: 2px solid ${color.greyLight};
    margin-bottom: 36px;
  }

  p.history {
    color: ${color.greyCore};
  }

  > dl {
    margin-bottom: 40px;

    .transactionWrapper {
      display: flex;
      flex-direction: row;
      align-items: start;
      gap: 12px;
      color: ${color.greyDarker};
      margin-bottom: 20px;

      .icon {
        padding-top: 5px;
      }

      p {
        font-size: 18px;
        ${baseBodyText}
        display: block;
        margin: 2px 0 0 0;

        span {
          ${costaTextBold}
          display: block;
        }
      }

      p.location {
        font-size: 16px;
      }

      svg {
        width: 32px;
        height: auto;
      }
    }

    dd {
      margin-inline-start: 0;
    }
  }
`
