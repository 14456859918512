import React from 'react'
import { Routes, Route, Outlet, Link, useMatch, useLocation } from 'react-router-dom'
import AccountPreferences from './AccountPreferences'
import AccountHistory from './AccountHistory'
import AccountDetails from './AccountDetails'
import { TabWrapper, Tabs } from './AccountBody.styles'
import type { Microcopy } from 'utils/src/microcopy'

import { HistoryIcon } from 'cw-frontend/src/svg/HistoryIcon'
import { DetailsIcon } from 'cw-frontend/src/svg/DetailsIcon'
import { PreferencesIcon } from 'cw-frontend/src/svg/PreferencesIcon'

const tabs = [
  {
    name: 'history',
    icon: HistoryIcon,
  },
  {
    name: 'details',
    icon: DetailsIcon,
  },
  {
    name: 'preferences',
    icon: PreferencesIcon,
  },
]

function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

interface IProps {
  microcopy: Microcopy
}

export default function AccountBody({ microcopy }: IProps) {
  return (
    <Tabs id="tabs">
      <div className="subHeading">
        Keep your details up-to-date to get news and offers from your Coffee Club.
      </div>
      <Routes>
        <Route element={<Layout />}>
          <Route
            caseSensitive
            path="*"
            element={
              <TabWrapper>
                <AccountHistory />
              </TabWrapper>
            }
          />
          <Route
            caseSensitive
            path="/details"
            element={
              <TabWrapper>
                <AccountDetails microcopy={microcopy} />
              </TabWrapper>
            }
          />
          <Route
            caseSensitive
            path="/preferences"
            element={
              <TabWrapper>
                <AccountPreferences />
              </TabWrapper>
            }
          />
        </Route>
      </Routes>
    </Tabs>
  )
}

function Layout() {
  const location = useLocation()

  return (
    <>
      <div className="tabButtonsWrapper">
        <div className="tabButtons">
          {tabs.map(tab => {
            const isActive =
              useMatch(tab.name) || (tab.name === 'history' && location.pathname === '/')

            return (
              <Link
                id={tab.name}
                key={tab.name}
                to={tab.name}
                className={isActive ? 'tabSelected' : 'tab'}
              >
                <div className="svgWrapper">
                  <tab.icon active={!!isActive} />
                </div>
                <div className="caption">{capitalize(tab.name)}</div>
              </Link>
            )
          })}
        </div>
      </div>
      <Outlet />
    </>
  )
}
