import styled from 'styled-components'
import { color, costaTextBold, above, costaText } from 'ui/src/styles/index'

export const Wrapper = styled.div`
  width: 239px;
  ${costaText};
  font-size: 16px;
  line-height: 24px;
  color: ${color.costaRed};
  text-align: center;
  margin-bottom: 16px;

  & strong {
    ${costaTextBold};
  }

  ${above.tablet`
    width: auto;
    font-size: 18px;
    line-height: 28px;
  `}

  ${above.desktop`
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 32px;
  `}

  .linebreak {
    ${above.desktop`
      display: none;
    `}
  }
`
