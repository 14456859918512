import React from 'react'
import SelectDropdown from '../SelectDropdown/selectDropdown'

function ProductSelect({ activeCategory = null, activePage, selectCategory }) {
  return (
    <SelectDropdown
      title="Name"
      htmlId="category"
      defaultOption={`All ${activePage}`}
      options={activeCategory}
      handleChange={selectCategory}
    />
  )
}

export default ProductSelect
