import styled from 'styled-components'
import { above } from 'ui/src/styles'

export const StyledAccountHeader = styled.div`
  background: none;
  position: relative;
  width: 100%;
  margin: -250px auto 0;

  ${above.tablet`
    overflow: visible;
    max-width: 100%;
    margin: -100px auto 0;
  `}

  ${above.tabletLarge`
    max-width: 824px;
    margin-bottom: 18px;
  `}

  ${above.desktop`
    margin-bottom: 0;
    margin-top: -320px;
    max-width: 1156px;
  `}
`

export const BeansMask = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px 0;
  background-color: white;

  ${above.tablet`
    background-color: transparent;
  `}

  ${above.desktop`
    padding: 0;
    margin: 0 auto;
    width: 890px;
    background-color: transparent;
  `};
`
