import { ActiveCustomisations, AvailableCustomisations } from '../../types'

export function getInitialCustomisations({
  availableCustomisations,
}: {
  availableCustomisations: AvailableCustomisations
}): ActiveCustomisations {
  return {
    coffeeType: availableCustomisations.coffeeTypes[0] || null,
    extraShot: availableCustomisations.extraShots[0] || null,
    syrup: availableCustomisations.syrups[0] || null,
    extra: availableCustomisations.extras[0] || null,
  }
}
