import { useState, useEffect } from 'react'
import { useAccount, useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'

interface Options {
  location?: Location
}

export function useAccountAccess({ location }: Options) {
  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0] || {})
  const [ready, setReady] = useState(false)
  const locationHash = location?.hash ?? ''
  const accountChangedRedirect = getAccountChangedRedirect(locationHash)

  useEffect(() => {
    if (accountChangedRedirect) {
      window.location.assign(accountChangedRedirect)
    } else if (account && inProgress === InteractionStatus.None) {
      instance.setActiveAccount(account)
      setReady(true)
    }
  }, [account, inProgress, instance])

  return {
    ready,
  }
}

function getAccountChangedRedirect(hash: string) {
  const isStateHash = hash.includes('state=')

  if (isStateHash && hash.includes('password-changed')) {
    return '/costa-club/account-changed/password'
  } else if (isStateHash && hash.includes('email-changed')) {
    return '/costa-club/account-home#/details?notify=email'
  }

  return null
}
