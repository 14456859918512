import React, { Fragment, PureComponent } from 'react'
import styled from 'styled-components'
import { color, above, costaText, costaTextBold } from 'ui/src/styles/index'

interface NutritionTableProps {
  title: string
  display?: string
  headings: (string | null)[]
  rows: (string | number | null | undefined)[][]
  mobile?: boolean
}

class NutritionTable extends PureComponent<NutritionTableProps> {
  static defaultProps = {
    title: '',
    display: 'twoCol',
    headings: [],
    rows: [],
    mobile: false,
  }

  render() {
    const { title, display, headings, rows, mobile } = this.props

    return (
      <>
        {!mobile && (
          <StyledNutritionTable className={`${display}`}>
            <table>
              <caption>
                <h3>{title}</h3>
              </caption>
              {headings.length > 0 && (
                <thead>
                  <tr>
                    {headings.map(heading => (
                      <th key={heading}>{heading}</th>
                    ))}
                  </tr>
                </thead>
              )}
              <tbody>
                {rows.map(row => (
                  <tr key={row[0]}>
                    {row.map((cell, i) => (
                      <td key={`${row[0]}-${i}`}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </StyledNutritionTable>
        )}
        {mobile && (
          <StyledNutritionTable className={`${display}`}>
            <table>
              <caption>
                <h3>{title}</h3>
              </caption>
              <tbody>
                {rows.map((row, i) => (
                  <Fragment key={String(row[0]) + i}>
                    <tr className="whiteSmoke">
                      <th colSpan={2}>{row[0]}</th>
                    </tr>
                    {headings.map(
                      (heading, j) =>
                        j !== 0 && (
                          <tr key={heading} className="white">
                            <th>{heading}</th>
                            <td>{row[j]}</td>
                          </tr>
                        )
                    )}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </StyledNutritionTable>
        )}
      </>
    )
  }
}

export default NutritionTable

const StyledNutritionTable = styled.div`
  background-color: ${color.offWhite};
  margin: 0 0 40px;
  box-sizing: border-box;
  padding: 24px 24px 44px;
  width: 100%;

  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;

    ${above.tablet`
      font-size: 16px;
    `}
  }

  caption {
    text-align: left;

    h3 {
      margin: 0 0 24px;
    }
  }

  tbody tr,
  thead tr {
    color: ${color.greyDarker};
  }

  tr:nth-of-type(odd) {
    background: ${color.greyLight};
  }

  tr:nth-of-type(even) {
    background: ${color.white};
  }

  th {
    text-align: right;
    padding: 10px 12px 10px 0;
    background-color: ${color.white};
    font-weight: 400 !important;
    ${costaTextBold}

    ${above.tablet`
      padding: 12px 16px 12px 0;
      width: 18%;
      line-height: 1.2;

      &:nth-of-type(1) {
        width: auto;
      }
    `}

    ${above.tabletLarge`
      width: 22%;

      &:nth-of-type(2) {
        width: 24%;
      }
    `}
  }

  td {
    ${costaText};
    text-align: right;
    padding: 10px 12px;
    text-transform: capitalize;

    ${above.tablet`
      padding: 12px 16px;
    `}
  }

  tbody td:nth-child(1) {
    ${costaText};
    text-align: left;
    text-transform: none;
  }

  &.noHeadings {
    width: 100%;
  }

  &.withHeadings {
    width: 100%;

    .whiteSmoke {
      th {
        background: ${color.greyLight};
        text-align: left;
        padding: 10px 12px;

        ${above.tablet`
          padding: 12px 16px;
        `}
      }
    }

    .white {
      td,
      th {
        background: ${color.white};
        ${costaText};
      }

      th {
        text-align: left;
        padding: 10px 12px;

        ${above.tablet`
          padding: 12px 16px;
        `}
      }
    }
  }
`
