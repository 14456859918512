/**
 * Counts the number of active free drink coupons in the provided loyalty data.
 *
 * @param {CostaApi.Loyalty.CustomerLoyaltyResponse} loyaltyData - The loyalty data from which to
 * count the free drink coupons.
 * @returns {number} The number of active free drink coupons. If no such coupons are
 * found, 0 is returned.
 */
export function freeDrinkAmount(
  loyaltyData: CostaApi.Loyalty.CustomerLoyaltyResponse | undefined
): number {
  return (
    loyaltyData?.coupons?.filter(
      coupon => coupon.attributes.couponType === 'F' && coupon.state === 'active'
    ).length ?? 0
  )
}

/**
 * Extracts the identifiers of all active cards from the provided loyalty data.
 *
 * @param {CostaApi.Loyalty.CustomerLoyaltyResponse} loyaltyData - The loyalty data from which
 * to extract the card identifiers.
 * @returns {string[]} An array of identifiers for all active cards. If no active cards are
 * found, an empty array is returned.
 */
export function cardIds(
  loyaltyData: CostaApi.Loyalty.CustomerLoyaltyResponse | undefined
): string[] {
  return (
    loyaltyData?.loyalty?.cards
      ?.filter(card => card.status === 'active')
      .map(card => card.identifier) ?? []
  )
}

/**
 * Returns true or false when given a transaction type to decide whether that
 * type is valid for showing in the rewards list
 *
 * @returns {boolean}
 */
export const validNotification = (key?: CostaApi.Loyalty.TransactionType): boolean => {
  return [
    'registration',
    'referral',
    'referrerReferral',
    'freeDrinkRedeemed',
    'freeDrinkCoupon',
    'beansEarned',
    'greenBeansEarned',
    'expressBeansEarned',
    'bonusBeansEarned',
    'promotionBeansEarned',
    'couponUsed',
    'gameBeanEarned',
  ].includes(key || '')
}

/**
 * Returns a valid string for the reward title depending on the transaction effect type
 *
 * @returns {string}
 */
export const transformEffectTitle = (key?: CostaApi.Loyalty.TransactionType): string => {
  switch (key) {
    case 'registration':
      return 'Registration bonus'
    case 'referral':
      return 'Refer a friend bonus'
    case 'referrerReferral':
    case 'freeDrinkCoupon':
    case 'beansEarned':
    case 'greenBeansEarned':
    case 'expressBeansEarned':
    case 'bonusBeansEarned':
    case 'promotionBeansEarned':
    case 'gameBeanEarned':
      return 'You earned'
    default:
      return 'You redeemed'
  }
}

/**
 * Transforms the body of a loyalty transaction based on its type. The response in most cases
 * should just be a number as a string, but we need to accommodate when this is also not the case.
 *
 * @param {CostaApi.Loyalty.TransactionType} [key] - The type of the transaction.
 * @param {string} [body] - The body of the transaction.
 * @returns {string} The transformed body of the transaction.
 */
export const transformEffectBody = (key?: CostaApi.Loyalty.TransactionType, body?: string) => {
  switch (key) {
    // Handle free drinks
    case 'freeDrinkRedeemed':
    case 'freeDrinkCoupon':
      return 'Free Drink'

    // Handle when a coupon is used and we need to show the coupon
    case 'couponUsed':
      return body

    // Handle a Referrer earns referral Bean
    case 'referrerReferral': {
      const match = body?.match(/(^|\s)\d+(\s|$)/)

      if (!match) {
        return body
      } else {
        const num = parseInt(match[0], 10)
        return `${num} Referral Bean${num !== 1 ? 's' : ''}`
      }
    }

    // Handle the default when we should just get a number as a string
    default: {
      const match = body?.match(/(^|\s)\d+(\s|$)/)

      if (!match) {
        return body
      } else {
        const num = parseInt(match[0], 10)
        return `${num} ${key === 'greenBeansEarned' ? 'Green ' : ''}Bean${num !== 1 ? 's' : ''}`
      }
    }
  }
}
