import React, { useState, ReactNode } from 'react'
import styled from 'styled-components'
import { color, costaTextBold } from '../styles'
import accordionTitleIcon from '../assets/Accordion_Icon_Plus.svg'

interface CompactAccordionProps {
  children: ReactNode
  title: string
}

interface CreateIdProps {
  prefix: string
  text: string
}

const createId = ({ prefix, text }: CreateIdProps): string => {
  // Convert 'text' to lowercase and replace spaces with '-'
  const sanitizedText = text.toLowerCase().replace(/\s+/g, '-')
  return `${prefix}-${sanitizedText}`
}

function CompactAccordion({ title, children }: CompactAccordionProps) {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  const contentId = createId({ prefix: 'content', text: title })

  return (
    <AccordionContainer className={isOpen ? 'open' : 'closed'}>
      <AccordionHeader onClick={toggleAccordion} aria-expanded={isOpen} aria-controls={contentId}>
        {title}
        <AccordionTitleIcon
          className={isOpen ? 'accordionOpen' : undefined}
          src={accordionTitleIcon}
          alt="Accordion toggle icon"
        />
      </AccordionHeader>
      {isOpen && (
        <AccordionContent id={contentId} aria-hidden={!isOpen}>
          {children}
        </AccordionContent>
      )}
    </AccordionContainer>
  )
}

const AccordionContainer = styled.div`
  margin-bottom: 16px;
`

const AccordionHeader = styled.button`
  color: ${color.costaRed};
  ${costaTextBold}
  line-height: 24px;
  font-size: 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 40px 12px 16px;
  border-radius: 8px;
  background-color: ${color.greyLight};
  cursor: pointer;
  user-select: none;
  margin: 0;
  appearance: none;
  border: 0;
  width: 100%;

  &:hover {
    color: ${color.lightRed};
  }
`

const AccordionTitleIcon = styled.img`
  position: absolute;
  right: 16px;
  top: 17px;
  transition: transform 0.3s;
  transform: rotate(0deg);

  &.accordionOpen {
    transform: rotate(135deg);
  }
`

const AccordionContent = styled.div`
  padding-top: 32px;
`

export default CompactAccordion
