import axios from 'axios'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { v1 as uuidv1 } from 'uuid'
import { delay } from '../delay'
import { msalInstance } from '../msal/wrapMsalProvider'
import { loginRequest } from '../../config/authConfig'
import { ApiError } from './CustomErrors'

export enum Status {
  None = 'none',
  Loading = 'loading',
  Updating = 'updating',
  Success = 'success',
  Error = 'error',
}

/**
 * Asynchronously retrieves the token for the active account. If no active account is found,
 * an error is thrown. If the token retrieval fails due to an InteractionRequiredAuthError
 * it attempts to acquire the token using a redirect.
 *
 * @returns {Promise<string | undefined>} The JWT token, or undefined if the response is undefined.
 * @throws {Error} If no active account is found.
 */
const getToken = async (): Promise<string | undefined> => {
  const account = msalInstance.getActiveAccount()
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    )
  }

  const request = {
    ...loginRequest,
    account: account || undefined,
  }

  const response = await msalInstance.acquireTokenSilent(request).catch(error => {
    if (error instanceof InteractionRequiredAuthError) {
      return msalInstance.acquireTokenRedirect(request)
    }
  })

  if (process.env.GATSBY_DEBUG) {
    console.info(`AccessToken: ${response?.accessToken}`)
  }

  return response?.accessToken
}

/**
 * Handles the response of a HTTP request.
 *
 * If the request was made and the server responded with a status code
 * that falls out of the range of 2xx, it logs the status and the response data.
 *
 * If the request was made but no response was received, it logs the request.
 *
 * If an error occurred while setting up the request, it logs the error message.
 *
 * @param {any} error - The error object from the HTTP request.
 */
function handleResponse(error: any, verb: RequestOptions['verb']) {
  if (error.response) {
    console.error(`Response failed with status: ${error.response.status}`, error.response.data)

    /**
     * Feedback error to UI when updating
     */
    if (['PATCH'].includes(verb)) {
      throw new ApiError('Response failed', error.response)
    }
  } else if (error.request) {
    console.error(error.request)

    /**
     * Feedback error to UI when updating
     */
    if (['PATCH'].includes(verb)) {
      throw new ApiError('Request failed', error.request)
    }
  } else {
    console.error('Error', error.message)
  }
}

/**
 * Asynchronously generates headers for a HTTP request.
 *
 * It retrieves the JWT token for the active account and includes it in the Authorization header.
 * It also generates a unique transaction ID and includes it in the 'x-transaction-id' header.
 *
 * @returns {Promise<Record<string, string>>} An object containing the headers for a HTTP request.
 * @throws {Error} If no active account is found or if the token retrieval fails.
 */
async function getHeaders(): Promise<Record<string, string>> {
  const accessToken = await getToken()

  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-transaction-id': uuidv1(),
    Authorization: `Bearer ${accessToken}`,
  }
}

export interface RequestOptions {
  path: string
  verb: 'GET' | 'PATCH'
  body?: object
  params?: Record<string, unknown>
}

/**
 * Makes an HTTP request and retries on failure for GET requests.
 *
 * Here's how the timing would work out with 5 retries and a 500ms initial backoff:
 *
 * - First attempt: no delay
 * - First retry: 500ms delay
 * - Second retry: 1000ms delay (500ms * 2)
 * - Third retry: 2000ms delay (1000ms * 2)
 * - Fourth retry: 4000ms delay (2000ms * 2)
 * - Fifth retry: 8000ms delay (4000ms * 2)
 *
 * So, the maximum length of time it will retry for, with 5 retries and a 500ms initial backoff,
 * is the sum of these delays, which is 15500 milliseconds, or 15.5 seconds.
 *
 * @template L The expected return type of the HTTP request.
 * @param {RequestOptions} options The options for the HTTP request.
 * @param {Record<string, (data: any) => boolean>} [dataValidFn = {}] A keyed object that returns
 * a function that returns takes data and returns true or false.
 * @param {number} [retries=3] The number of times to retry the request on failure.
 * @param {number} [backoff=300] The amount of time (in ms) to wait before retrying the request.
 * @param {number} [startTime=Date.now()] The time at which the request started.
 * @returns {Promise<L | undefined>} A promise that resolves with the response data, or undefined if
 * the request fails and cannot be retried.
 * @throws Will throw an error if the request fails and cannot be retried.
 */
export const makeRequest = async <L>(
  options: RequestOptions,
  dataValidFn: Record<string, (data: any) => boolean> = {},
  retries: number = 5,
  backoff: number = 500,
  startTime: number = Date.now()
): Promise<L | undefined> => {
  const headers = await getHeaders()

  try {
    const response = await axios({
      method: options.verb,
      url: `${process.env.GATSBY_B2C_API_URL}${options.path}`,
      headers,
      data: options.body,
      params: options.params,
    })

    const invalidResponseData = dataValidFn[options.path]
    if (invalidResponseData?.(response.data)) {
      throw new Error('API data missing!')
    }

    if (process.env.GATSBY_DEBUG) {
      const endTime = Date.now()
      console.info(`Request completed in ${(endTime - startTime) / 1000} seconds`)
    }

    return response.data
  } catch (error: any) {
    if (retries > 0 && options.verb === 'GET') {
      await delay(backoff)
      return makeRequest(options, dataValidFn, retries - 1, backoff * 2, startTime)
    } else {
      handleResponse(error, options.verb)
    }
  }
}
