import React from 'react'
import { color } from 'ui/src/styles/index'

export const FreeDrinkUsedIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g clipPath="url(#clip0_378_1745)">
        <path
          d="M18.2402 24.24C17.8302 24.24 17.4902 24.58 17.4902 24.99C17.4902 25.4 17.8302 25.74 18.2402 25.74H23.7402C24.1502 25.74 24.4902 25.4 24.4902 24.99C24.4902 24.58 24.1502 24.24 23.7402 24.24H18.2402Z"
          fill={color.costaRed}
        />
        <path
          d="M20.99 17.98C20.99 17.98 20.89 17.98 20.85 17.98L21.51 10.21H21.59C22.53 10.21 23.3 9.44 23.3 8.5V6.05C23.3 5.11 22.54 4.35 21.61 4.34V3.63C21.61 2.64 20.8 1.83 19.86 1.83L6.49 1C5.5 1 4.69 1.81 4.69 2.8V4.36C3.76 4.36 3 5.13 3 6.07V8.52C3 9.46 3.77 10.23 4.71 10.23H4.79L6.12 25.78C6.21 26.86 7.16 27.7 8.28 27.7H14.53C15.59 30.23 18.08 32 20.99 32C24.86 32 28 28.86 28 24.99C28 21.12 24.86 17.98 20.99 17.98ZM6.19 2.8C6.19 2.64 6.33 2.5 6.44 2.5L19.8 3.35C19.96 3.35 20.1 3.49 20.1 3.65V4.36H6.19V2.8ZM4.71 8.72C4.6 8.72 4.5 8.63 4.5 8.51V6.06C4.5 5.95 4.59 5.85 4.71 5.85H21.6C21.71 5.85 21.81 5.94 21.81 6.06V8.51C21.81 8.62 21.72 8.72 21.6 8.72H4.71ZM7.61 25.65L6.3 10.22H20.01L19.33 18.19C16.27 18.94 13.99 21.7 13.99 24.99C13.99 25.4 14.03 25.8 14.1 26.2H8.28C7.95 26.2 7.64 25.94 7.61 25.65ZM20.08 18.05C20.08 18.05 20.08 18.05 20.07 18.05C20.07 18.05 20.07 18.05 20.08 18.05ZM14.27 26.95C14.27 26.95 14.27 26.96 14.27 26.97C14.27 26.97 14.27 26.96 14.27 26.95ZM20.99 30.5C17.95 30.5 15.49 28.03 15.49 24.99C15.49 21.95 17.96 19.48 20.99 19.48C24.02 19.48 26.49 21.95 26.49 24.99C26.49 28.03 24.02 30.5 20.99 30.5Z"
          fill={color.costaRed}
        />
      </g>
      <defs>
        <clipPath id="clip0_378_1745">
          <rect width="25" height="31" fill="none" transform="translate(3 1)" />
        </clipPath>
      </defs>
    </svg>
  )
}
