import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { color, costaText, above } from 'ui/src/styles/index'

class NutritionTable extends PureComponent {
  static defaultProps = {
    title: '',
    display: 'twoCol',
    headings: [],
    rows: [],
  }

  render() {
    const { title, display, headings, rows } = this.props

    return (
      <StyledNutritionTable
        className={display}
        data-cy={`nutrition-table__${title.toLowerCase().replaceAll(' ', '-')}`}
      >
        <table>
          <caption>
            <h2>{title}</h2>
          </caption>
          {headings.length > 0 && (
            <thead>
              <tr>
                {headings.map(heading => (
                  <th key={heading}>{heading}</th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {rows.map(row => (
              <tr key={row[0]} data-cy={`row-${row[0]}`}>
                {row.map((cell, i) => (
                  <td key={row[0] + i} data-cy={`row-${row[0]}-${i}`}>
                    {' '}
                    {cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </StyledNutritionTable>
    )
  }
}

export default NutritionTable

const StyledNutritionTable = styled.div`
  background-color: ${color.offWhite};
  margin: 0 0 40px;
  box-sizing: border-box;
  padding: 20px 10px 40px;
  font-size: 14px;

  ${above.smallMobile`
    margin: 0 0 40px;
    font-size: 16px;
  `}

  @media screen and (min-width: 400px) {
    padding: 20px 30px 40px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  caption {
    margin: 0 5px 10px;
    padding: 0;
    text-align: left;
    background-color: ${color.offWhite};
  }

  caption h2 {
    margin: 0;
    font-size: 22px;
    line-height: 1.1;
    ${above.tablet`
      font-size: 30px;
    `}
  }

  tbody tr {
    color: ${color.greyDarker};
  }
  thead tr {
    color: ${color.greyDarker};
  }

  tr:nth-of-type(odd) {
    background: ${color.greyLight};
  }
  tr:nth-of-type(even) {
    background: ${color.white};
  }

  th {
    text-align: right;
    padding: 20px 10px 20px;
    background-color: ${color.white};
    ${costaText};
  }
  td {
    text-align: right;
    ${costaText};
    width: 40px;
    padding: 10px 5px;
    text-transform: capitalize;

    ${above.smallMobile`
      padding: 10px 15px;
    `}
  }

  tbody td:nth-child(1) {
    ${costaText};
    text-transform: none;
    width: auto;
    text-align: left;

    ${above.tablet`
      width: 258px;
    `}
  }

  tbody td:nth-child(2),
  tbody td:nth-child(3) {
    width: 20%;

    ${above.tablet`
      width: 120px;
    `}
  }

  &.twoCol {
    width: 100%;
    max-width: 488px;
    ${above.tablet`
      width: 488px;
    `}
  }

  &.threeCol {
    width: 100%;
    max-width: 614px;
    ${above.tablet`
      width: 614px;
    `}
  }
`
