import React, { createContext, useState, useContext, useEffect } from 'react'
import { Status } from '../utils/api/fetch'
import { getCustomerTransactions } from '../utils/api/loyalty'
import {
  transformEffectBody,
  transformEffectTitle,
  validNotification,
} from '../helpers/loyaltyHelper'

/**
 * NOTE the differences between the shape of the data we set vs what we
 * return. This is because setting is always done with data from the API
 * response which is in a very verbose shape so not ideal for using so
 * we convert it into a better shape to use in the app. This should really
 * be something the API does!
 */
export interface TransactionsContextType {
  state: Status
  hasMore: boolean
  transactions: CostaApi.Loyalty.TransactionsInternal | undefined
  setTransactions: (transactions: CostaApi.Loyalty.TransactionsResponse | undefined) => void
  fetchMoreTransactions: () => Promise<void>
}

export const TransactionsContext = createContext<TransactionsContextType>({
  state: Status.None,
  hasMore: false,
  transactions: undefined,
  setTransactions: () => {},
  fetchMoreTransactions: async () => {},
})

export const TransactionsProvider = ({ children }: { children: React.ReactNode }) => {
  const [historyInfo, setHistoryInfo] = useState<CostaApi.Loyalty.TransactionsResponse | undefined>(
    undefined
  )
  const [state, setState] = useState(Status.None)
  const historyTransformed = convertDataApiToInternal(historyInfo)
  const setTransactions = (data: CostaApi.Loyalty.TransactionsResponse | undefined) => {
    setHistoryInfo(data)
  }
  const appendTransactions = (data: CostaApi.Loyalty.TransactionsResponse | undefined) => {
    if (data) {
      setHistoryInfo(prevState => ({
        data: [...(prevState?.data || []), ...data.data],
        hasMore: data.hasMore,
      }))
    }
  }

  const fetchMoreTransactions = async () => {
    setState(Status.Updating)
    const data = await getCustomerTransactions({ skip: historyInfo?.data.length })
    appendTransactions(data)
    setState(Status.None)
  }

  useEffect(() => {
    async function fetchData() {
      setState(Status.Loading)
      const transactionData = await getCustomerTransactions()

      if (transactionData) {
        setTransactions(transactionData)
        setState(Status.None)
      }
    }

    fetchData()
  }, [])

  const value = {
    state,
    hasMore: !!historyInfo?.hasMore,
    transactions: historyTransformed,
    setTransactions,
    fetchMoreTransactions,
  }

  return <TransactionsContext.Provider value={value}>{children}</TransactionsContext.Provider>
}

export const useTransactions = () => useContext(TransactionsContext)

/**
 * Mapping based on:
 * https://wiki.salmon.com/display/CCFE/Mapping+Transactions+to+App+Reward+History
 */
export function convertDataApiToInternal(
  resp: CostaApi.Loyalty.TransactionsResponse | undefined
): CostaApi.Loyalty.TransactionsInternal {
  return (resp?.data || [])
    .filter(item => item.effects?.length)
    .reduce((acc, transaction) => {
      const date = new Date(transaction.created).toDateString(); // Convert to string to remove time
      const effects =
        transaction.effects?.filter(effect => effect.effectType === 'showNotification') ?? [];
      const location = effects.find(effect => effect.props?.title === 'location')?.props.body;
      const rewards = effects
        .filter(effect => validNotification(effect.props?.title))
        .map(effect => {
          const data: CostaApi.Loyalty.RewardInternal = {
            key: effect.props.title,
            title: transformEffectTitle(effect.props.title),
            label: transformEffectBody(effect.props.title, effect.props.body),
            location,
          };

          return data;
        });

      // If the date already exists in the accumulator, append the new rewards
      // Otherwise, create a new array for the date
      acc[date] = acc[date]
        ? [...acc[date], ...rewards]
        : [...rewards];

      return acc;
    }, {} as Record<string, CostaApi.Loyalty.RewardInternal[]>);
}
