import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../../layout/layout'
import Menu from '../../legacy/productNutrition/menu'
import SEO from '../../components/seo'

const useMenuHero = () => {
  const { allContentfulHero } = useStaticQuery(graphql`
    query UkMenuHeroQuery {
      allContentfulHero(filter: { cmsLabel: { eq: "UK Menu Hero - Do not delete" } }) {
        edges {
          node {
            id
            heroContent {
              raw
            }
            node_locale
            megaHero
            background {
              gatsbyImageData(layout: CONSTRAINED, width: 1600, quality: 100)
              file {
                contentType
                url
              }
            }
            tabletBackground {
              gatsbyImageData(layout: CONSTRAINED, width: 1600, quality: 100)
              file {
                contentType
                url
              }
            }
            mobileBackground {
              gatsbyImageData(layout: CONSTRAINED, width: 1600, quality: 100)
              file {
                contentType
                url
              }
            }
            heroTextColour
            mobileTextAlignment
            desktopTextAlignment
            mobileGradient
            fallbackImage {
              gatsbyImageData(layout: CONSTRAINED, width: 1600, quality: 100)
              file {
                contentType
                url
              }
            }
            multiHero
            multiHeroUpperHeading
            multiHeroUpperBodyText
            multiHeroLowerHeading
            multiHeroLowerBodyText
            multiHeroUpperlinkCTA {
              ...LinkCtaFragment
            }
            multiHeroLowerlinkCTA {
              ...LinkCtaFragment
            }
            multiHeroUpperBackground {
              gatsbyImageData(layout: CONSTRAINED, width: 1600, quality: 100)
              file {
                contentType
                url
              }
            }
            multiHeroUpperTabletBackground {
              gatsbyImageData(layout: CONSTRAINED, width: 1600, quality: 100)
              file {
                contentType
                url
              }
            }
            multiHeroUpperMobileBackground {
              gatsbyImageData(layout: CONSTRAINED, width: 1600, quality: 100)
              file {
                contentType
                url
              }
            }
            multiHeroLowerBackgroundBrandColour
            multiHeroLowerBackgroundColour
            multiHeroUpperTextColour
            multiHeroUpperCtaColour
            multiHeroLowerCtaColour
            multiHeroLowerImage {
              gatsbyImageData(layout: CONSTRAINED, width: 1600, quality: 100)
              file {
                contentType
                url
              }
            }
          }
        }
      }
    }
  `)
  return allContentfulHero.edges.find(element => element).node
}

function MenuPage({ location }) {
  const contentfulMenuHero = useMenuHero()

  const hero = contentfulMenuHero

  hero.__typename = 'ContentfulHero'

  return (
    <Layout hero={hero} location={location}>
      <SEO title="Menu | Costa Coffee" />
      <Menu />
    </Layout>
  )
}

export default MenuPage
