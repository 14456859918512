import React from 'react'
import { color } from 'ui/src/styles/index'

export const BeanEarnedIcon = () => {
  return (
    <svg fill="none" viewBox="0 0 32 33">
      <g clipPath="url(#a)">
        <path
          fill={color.costaRed}
          fillRule="evenodd"
          d="M15 3c6.627 0 12 5.332 12 11.91a11.78 11.78 0 0 1-1.524 5.8 6.911 6.911 0 0 1 2.526 5.343c0 3.837-3.134 6.947-7 6.947-3.621 0-6.6-2.73-6.962-6.229C7.863 26.284 3 21.165 3 14.91 3 8.332 8.373 3 15 3Zm6.002 17.594c-3.033 0-5.5 2.449-5.5 5.459 0 3.009 2.467 5.458 5.5 5.458s5.5-2.45 5.5-5.458c0-3.01-2.467-5.459-5.5-5.459Zm0 1.985c.413 0 .75.335.75.744v1.985h2c.413 0 .75.335.75.745a.75.75 0 0 1-.75.744h-2v1.985a.75.75 0 0 1-1.5 0v-1.985h-2a.75.75 0 0 1-.75-.744.75.75 0 0 1 .75-.745h2v-1.985a.75.75 0 0 1 .75-.744ZM15 4.49c-5.79 0-10.5 4.674-10.5 10.42 0 5.332 4.057 9.736 9.267 10.345l.279.028.031-.234c.48-3.282 3.269-5.822 6.68-5.938l.245-.004c1.162 0 2.255.284 3.22.782a10.297 10.297 0 0 0 1.278-4.979c0-5.746-4.71-10.42-10.5-10.42Zm1.205 4.738c1.403.316 2.061 2.024 2.061 2.024s1.93.371 2.424 2.094c.493 1.72-1.29 4.7-4.142 5.995-2.455 1.116-4.774.706-5.613.309-.187-.087-.173-.273.04-.382 1.8-.921 3.02-2.458 3.804-3.582.578-.829.93-1.332 1.41-1.935.804-1.018 1.635-1.884 1.484-2.007-.099-.08-.884.142-2 1.362-.55.598-.925 1.09-1.606 2.028-.992 1.369-1.859 2.654-3.337 3.883-.303.25-.447.18-.563-.09-.28-.652-.383-1.212-.395-2.185-.037-3.251 1.358-4.84 1.48-5.003.144-.195 2.251-3.114 4.953-2.51Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M3 3h25v30H3z" />
        </clipPath>
      </defs>
    </svg>
  )
}
