import type { Product, AvailableVariants } from '../../types'

export function getAvailableVariants(product: Product): AvailableVariants {
  const { nutrition } = product

  const filters = {
    milk: new Set<string | null>(),
    size: new Set<string | null>(),
  }

  const filtersAvailable = nutrition
    .map(variant => variant.variations)
    //  Filter out the "Ultimate Blend" milkType per business requirements
    .filter(variation => variation.milkType !== 'Ultimate Blend')

  filtersAvailable.forEach(filter => {
    filters.milk.add(filter.milkType)
    filters.size.add(filter.size)
  })

  return {
    milk: Array.from(filters.milk).filter(v => v?.toLowerCase() !== 'na'),
    size: Array.from(filters.size),
  } as AvailableVariants
}
