import React from 'react'
import { CssSpinner } from 'ui/src/CssSpinner'
import { useAccountLoyalty } from 'cw-frontend/src/contexts/LoyaltyContext'
import { BeansMask, StyledAccountHeader } from './AccountHead.styles'
import LoyaltyCard from './loyalty/LoyaltyCard'

const UI_MAX_STAMPS = 10

export default function AccountHead() {
  const { loading, beans, freeDrinksAmount } = useAccountLoyalty()

  return (
    <StyledAccountHeader>
      <BeansMask>
        {loading ? (
          <CssSpinner size="60px" />
        ) : (
          <LoyaltyCard rewardCount={freeDrinksAmount} stamps={beans} stampTotal={UI_MAX_STAMPS} />
        )}
      </BeansMask>
    </StyledAccountHeader>
  )
}
