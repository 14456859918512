import styled from 'styled-components'
import { above, color, costaTextBold } from 'ui/src/styles/index'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Heading = styled.div`
  ${costaTextBold}
  color: ${color.costaRed};
  font-size: 25px;
  line-height: 28px;
  margin: 35px 0 8px 0;
  text-align: center;
  ${above.tablet`
    font-size: 40px;
    line-height: 44px;
    margin: 51px 0 16px 0;
  `}
  ${above.desktop`
    font-size: 50px;
    line-height: 55px;
    margin: 0 0 8px 0;
  `}
`

export const BelowDesktop = styled.div`
  text-align: center;

  ${above.desktop`
    display: none;
  `}
`
