import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Status } from '../utils/api/fetch'
import { patchCustomerAccount } from '../utils/api/loyalty'
import { useAccountDetail } from '../contexts/UserContext'
import { ApiError } from '../utils/api/CustomErrors'

export interface FormData extends CostaApi.Accounts.CustomerAccountResponse {
  dateOfBirth: Date
}

/**
 * When you clear the HTML Date field using the native UI it sends an
 * empty string to the form. The API does not handle these so we convert
 * to null which is does handle.
 */
function convertEmptyStringToNull(obj: Record<string, any>) {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, value === '' ? null : value])
  )
}

export function useAccountForm() {
  const { user, setUser, loading } = useAccountDetail()
  const { register, handleSubmit, formState, setValue, setFocus } = useForm<FormData>({
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      dateOfBirth: user?.dateOfBirth,
    },
  })
  const { errors } = formState
  const [state, setState] = useState(Status.None)
  const onSubmit = handleSubmit(data => {
    const submitData = async () => {
      try {
        const userData = await patchCustomerAccount(convertEmptyStringToNull(data))

        if (userData) {
          setUser({ ...user, ...userData })
          setState(Status.Success)
        }
      } catch (error) {
        if (error instanceof ApiError) {
          setState(Status.Error)
        }
      }
    }

    setState(Status.Updating)
    submitData()
  })

  useEffect(() => {
    setFocus('firstName', { shouldSelect: false })
  }, [])

  useEffect(() => {
    if (user) {
      setValue('firstName', user.firstName)
      setValue('lastName', user.lastName)
      setValue('dateOfBirth', user.dateOfBirth)
    }
  }, [user])

  return {
    onSubmit,
    register,
    handleSubmit,
    formState,
    errors,
    state,
    setState,
    user,
    loading,
  }
}
