import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { color } from 'ui/src/styles/index'
import Icon from '../Icon/icon'
import ICONS from '../../resources/ICONS'

export default class PageSelect extends PureComponent {
  static defaultProps = {
    activePage: '',
  }

  render() {
    const { setCategories, activePage } = this.props

    return (
      <StyledPageSelect>
        <button
          type="button"
          onClick={() => setCategories('drinks', activePage)}
          className={activePage === 'drinks' ? 'active' : null}
          data-cy="drink-select"
        >
          <Icon
            icon={ICONS.page_drinks}
            w={60}
            h={60}
            wrapped
            className={`iconWrapper ${
              activePage && activePage !== 'food' ? 'activeIconWrapper' : ''
            }`}
          />
          <span>Drinks</span>
        </button>
        <button
          type="button"
          onClick={() => setCategories('food', activePage)}
          className={activePage === 'food' ? 'active' : null}
          data-cy="food-select"
        >
          <Icon
            icon={ICONS.page_food}
            w={60}
            h={60}
            wrapped
            className={`iconWrapper ${
              activePage && activePage !== 'drinks' ? 'activeIconWrapper' : ''
            }`}
          />
          <span>Food</span>
        </button>
      </StyledPageSelect>
    )
  }
}

const StyledPageSelect = styled.div`
  text-align: center;

  button {
    margin: 0 20px;
    &:focus {
      box-shadow: none;
      appearance: none;
      outline: none;
    }
  }

  span {
    color: ${color.costaRed};
    display: inline-block;
    padding: 0 5px 7px;
    border-bottom: 5px solid transparent;
    border-radius: 4px;
  }

  .active {
    span {
      color: ${color.lightRed};
      border-bottom: 5px solid ${color.lightRed};
    }
  }

  .iconWrapper {
    width: 80px;
    height: 80px;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: ${color.costaRed};
    }
  }

  .activeIconWrapper {
    svg {
      fill: ${color.lightRed};
    }
  }
`
