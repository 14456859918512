import React from 'react'
import { StampRow, StyledStamp } from './Stamps.styles'

import { StampBeanGreenIcon } from 'cw-frontend/src/svg/StampBeanGreenIcon'
import { StampBeanSkeletonIcon } from 'cw-frontend/src/svg/StampBeanSkeletonIcon'
import { StampBeanStandardIcon } from 'cw-frontend/src/svg/StampBeanStandardIcon'

const GREEN_BEAN = 'greenBeans' as CostaApi.Loyalty.Beans.Green
const STANDARD_BEAN = 'standardBeans' as CostaApi.Loyalty.Beans.Standard

interface Props {
  totalCount: number
  stamps: string[]
}

export default function Stamps({ totalCount, stamps }: Props) {
  if (totalCount % 2) {
    totalCount += 1
  }

  const renderStampRow = (start: number) => {
    const stampComponents = {
      [GREEN_BEAN]: StampBeanGreenIcon,
      [STANDARD_BEAN]: StampBeanStandardIcon,
    }

    return (
      <StampRow>
        {[...Array(totalCount / 2)].map((_, index) => {
          const stampIndex = index + start
          const stamp = stamps[stampIndex]
          const StampComponent =
            stampComponents[stamp as keyof typeof stampComponents] || StampBeanSkeletonIcon

          return (
            <StyledStamp key={stampIndex.toString()}>
              <StampComponent />
            </StyledStamp>
          )
        })}
      </StampRow>
    )
  }

  return (
    <div>
      {renderStampRow(0)}
      {renderStampRow(totalCount / 2)}
    </div>
  )
}
