import React from 'react'
import styled from 'styled-components'
import { color, above, costaTextBold } from 'ui/src/styles/index'

import AllergenInfo from '../AllergenInfo/allergenInfo'

const ProductItem = ({ setActiveProduct, product, productPage }) => {
  const userClick = () => {
    setActiveProduct(product, productPage)
  }

  const productThumbnail =
    product.images &&
    product.images.find(image => {
      if (image.imageStyle === 'web-thumbnail') {
        return image
      }
      return null
    })

  const productName = (product?.productDisplayName || product?.name) ?? ''

  return (
    product && (
      <Product
        onClick={() => userClick(product)}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            userClick(product)
          }
        }}
        tabIndex={0}
        role="button"
      >
        {productThumbnail?.imageUrl && (
          <div className="image">
            <img src={`${productThumbnail?.imageUrl}`} alt={productName} />
          </div>
        )}
        <div className="header">{productName}</div>
        {(product.vegan || product.vegetarian || product.containsNuts) && (
          <AllergenInfo activeNutrition={product} />
        )}
      </Product>
    )
  )
}

export default ProductItem

const Product = styled.div`
  min-height: 244px;
  width: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  outline: none;
  appearance: none;

  ${above.tablet`
    width: 180px;
  `}

  ${above.desktopLarge`
    width: 275px;
  `}

  &:focus {
    box-shadow: rgb(207, 168, 202) 0px 0px 8px 2px;
    z-index: 1;
  }

  .image {
    height: 150px;
    display: flex;
    justify-content: center;

    ${above.mobile`
      height: 190px;
    `}

    ${above.tablet`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
    `}

    ${above.desktopLarge`
      height: 295px;
    `}

    img {
      width: auto;
      height: 100%;
    }
  }

  .header {
    ${costaTextBold};
    font-size: 16px;
    color: ${color.costaRed};
    margin-bottom: 9px;
    padding: 0 10px 0 10px;
    text-align: center;
    overflow-wrap: break-word;
    max-width: 200px;

    ${above.tablet`
      font-size: 18px;
    `}
  }
`
