import type { FilterIconMapping } from '../../types'

export const filterIconMapping: FilterIconMapping = {
  coffeeTypeFilters: [
    ['signature', 'Signature'],
    ['decaf', 'Decaf'],
  ],
  extraShotsFilters: [
    ['none', 'None'],
    ['one', 'One'],
    ['two', 'Two'],
  ],
  milkFilters: [
    ['None', 'None'],
    ['Whole', 'Whole'],
    ['Semi-Skimmed', 'Semi Skimmed'],
    ['Skimmed', 'Skimmed'],
    ['Soya', 'Soya'],
    ['Oat', 'Oat'],
    ['Ultimate', 'Ultimate Milk'],
    ['Coconut', 'Coconut'],
    ['Almond', 'Almond'],
    ['lacto_free', 'Lacto-free'],
  ],
  sizeFilters: [
    ['None', 'None'],
    ['Small', 'Small'],
    ['Medium', 'Medium'],
    ['Large', 'Large'],
    ['Single', 'Single'],
    ['Double', 'Double'],
  ],
  syrupFilters: [
    ['None', 'none'],
    ['Caramel', 'caramel'],
    ['Cinnamon Bun', 'cinnamon_bun'],
    ['Sugar Free Caramel', 'sugar_free_caramel'],
    ['Vanilla', 'vanilla'],
    ['Sugar Free Vanilla', 'sugar_free_vanilla'],
    ['Hazelnut', 'hazelnut'],
    ['Sugar Free Hazelnut', 'sugar_free_hazelnut'],
    ['Gingerbread', 'gingerbread'],
    ['Sugar Free Gingerbread', 'sugar_free_gingerbread'],
  ],
}
