import React from 'react'
import { Wrapper } from './RewardMessage.styles'

interface Props {
  rewardCount: number
}

export default function RewardMessage({ rewardCount }: Props) {
  return (
    <Wrapper>
      <span>You have a Free Drink</span>
      <span>{rewardCount > 1 && ` (x${rewardCount})`}</span>
    </Wrapper>
  )
}
