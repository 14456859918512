import styled from 'styled-components'
import { above, color, costaTextBold } from 'ui/src/styles'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  fieldset {
    border: none;
    background-color: ${color.greyLight};
    padding: 28px;
    margin-bottom: 48px;

    ${above.tablet`
      padding: 48px;
    `}

    p {
      margin-bottom: 28px;
    }

    p.terms {
      font-size: 12px;
      margin-left: 40px;
      margin-bottom: 0;

      a {
        ${costaTextBold};
        color: ${color.costaRed};
      }
    }

    ul {
      list-style-type: none;
      margin-bottom: 30px;
      padding: 0;
    }

    li {
      display: flex;
      align-items: center;
      ${costaTextBold};
      font-size 12px;
      line-height: 24px;
      margin-bottom: 18px;
    }
  }
`

export const Label = styled.label`
  position: relative;
  padding-left: 40px;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    border: 2px solid ${color.greyCore};
    border-radius: 2px;
  }

  svg {
    position: absolute;
    left: 5px;
    top: 5px;
    visibility: hidden;
    width: 15px;
    height: 15px;
  }
`

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;

  :checked + ${Label}:before {
    background-color: ${color.costaRed};
  }

  :checked + ${Label} svg {
    visibility: visible;
    background-color: ${color.costaRed};
  }
`
