import React from 'react'
import CategoryView from './containers/CategoryView/categoryView'
import ProductView from './containers/ProductView/productView'

export default class Menu extends React.Component {
  state = {
    activeProduct: null,
    activeProductType: 'drink',
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    if ('scrollRestoration' in history) {
      history.scrollRestoration = 'manual'
    }
  }

  setActiveProduct = (product, productPage) => {
    this.setState({
      activeProduct: product,
      activeProductType: productPage,
    })
    if (product !== null) {
      history.pushState({}, '', '')
    }
    window.onpopstate = () => {
      const { activeProductType } = this.state
      if (window.location.pathname === '/menu/' && this.state.activeProduct) {
        this.setState({
          activeProductType,
          activeProduct: null,
        })

        window.onpopstate = () => null
      }
    }
  }

  render() {
    const { activeProduct, activeProductType } = this.state

    return (
      <>
        <section {...(activeProduct ? { inert: 'true' } : {})}>
          <CategoryView setActiveProduct={this.setActiveProduct} />
        </section>

        {activeProduct && (
          <ProductView
            product={activeProduct}
            type={activeProductType}
            onClose={() => this.setState({ activeProduct: null })}
          />
        )}
      </>
    )
  }
}
