import React, { createContext, useState, useContext, useEffect } from 'react'
import { cardIds, freeDrinkAmount } from '../helpers/loyaltyHelper'
import { getBeansHistory, getCustomerLoyalty } from '../utils/api/loyalty'

export interface LoyaltyContextType {
  freeDrinksAmount: number
  cards: string[]
  beans: CostaApi.Loyalty.BeanHistoryResponse['beans']
  loading: boolean
}

export const LoyaltyContext = createContext<LoyaltyContextType>({
  freeDrinksAmount: 0,
  cards: [],
  beans: [],
  loading: true,
})

export const LoyaltyProvider = ({ children }: { children: React.ReactNode }) => {
  const [loyaltyData, setLoyaltyDataInfo] = useState<CostaApi.Loyalty.CustomerLoyaltyResponse>()
  const [beansData, setBeansData] = useState<CostaApi.Loyalty.BeanHistoryResponse>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetchData() {
      const [beansData, loyaltyData] = await Promise.all([getBeansHistory(), getCustomerLoyalty()])

      if (beansData && loyaltyData) {
        setLoading(false)
        setLoyaltyDataInfo(loyaltyData)
        setBeansData(beansData)
      }
    }

    fetchData()
  }, [])

  const value = {
    freeDrinksAmount: freeDrinkAmount(loyaltyData),
    cards: cardIds(loyaltyData),
    beans: beansData?.beans ?? [],
    loading,
  }

  return <LoyaltyContext.Provider value={value}>{children}</LoyaltyContext.Provider>
}

export const useAccountLoyalty = () => useContext(LoyaltyContext)
