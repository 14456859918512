import styled from 'styled-components'
import { color, above, costaText } from 'ui/src/styles/index'

export const StyledCustomiseDrink = styled.div`
  h3 {
    margin: 0;
  }

  .filterGroup {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-start;
    gap: 14px 24px;

    ${above.tablet`
    gap: 24px;
    `}
  }

  button {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    background: ${color.white};
    border: none;
    margin: 0;
    cursor: pointer;
    padding: 0;
    width: 30%;
    max-width: 68px;
    align-items: center;

    ${above.mobile`
      max-width: 80px;
    `}

    &:hover {
      background: ${color.white};
    }

    &.disabled,
    &:disabled {
      display: none !important;
    }

    span {
      color: ${color.greyDarker};
      font-size: 12px;
      ${costaText};
      width: 100%;
      text-align: center;
      word-wrap: break-word;

      ${above.mobile`
        font-size: 14px;
      `}

      ${above.tablet`
        font-size: 16px;
      `}
    }
  }

  .iconWrapper {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    svg {
      width: 26px;
      height: 26px;

      ${above.mobile`
        width: 44px;
        height: 44px;
      `}
    }

    ${above.mobile`
      width: 80px;
      height: 80px;
    `}
  }

  .available {
    background: ${color.white};
    border: 1px solid #d5d2d2;
    fill: ${color.costaRed};

    ${above.mobile`
      border: 2px solid transparent;
    `}
  }

  .active {
    background: ${color.tertiaryLightest};
    fill: ${color.costaRed};
    border: none;
  }
`

export const DrinkCaption = styled.p`
  margin: 40px 0 20px;
`
