import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { color, costaTextBold } from 'ui/src/styles/index'
import Icon from '../Icon/icon'
import ICONS from '../../resources/ICONS'
import { printValue } from '../../helpers/nutritionTableHelpers'

export default class AllergenInfo extends PureComponent {
  allergenInfo = (nutritionData, allergenList) => {
    const flatAllergensData = nutritionData
    flatAllergensData.allergens.vegetarian = nutritionData.dietaryChoices.suitableForVegetarians
    flatAllergensData.allergens.vegan = nutritionData.dietaryChoices.suitableForVegans
    return allergenList.map(allergen => {
      if (flatAllergensData && flatAllergensData.allergens[allergen[0]] === 'Yes') {
        if (
          (allergen[0] === 'treeNuts' || allergen[0] === 'peanuts') &&
          printValue(nutritionData.allergens.treeNuts) === 'Cross Contamination Risk'
        ) {
          return (
            <li className="mayContainNuts" key="mayContainNuts">
              <Icon
                icon={ICONS[`allergen_${allergen[0]}`]}
                color={color.burntOrange}
                w={40}
                h={40}
                wrapped
                className="iconWrapper"
              />
              <div>May Contain Nuts</div>
            </li>
          )
        }

        return (
          <li className={allergen[0]} key={allergen[0]}>
            <Icon
              icon={ICONS[`allergen_${allergen[0]}`]}
              color={color.burntOrange}
              w={40}
              h={40}
              wrapped
              className="iconWrapper"
            />
            <div className="allergenName">{allergen[1]}</div>
          </li>
        )
      }
      return null
    })
  }

  render() {
    const { activeNutrition } = this.props
    const allergenList = [
      ['vegan', 'Vegan'],
      ['vegetarian', 'Vegetarian'],
      ['treeNuts', 'Contains Nuts'],
    ]

    return (
      <StyledAllergenInfo data-cy="product-allergens-info">
        <h3>Allergen Information</h3>
        <ul>{this.allergenInfo(activeNutrition, allergenList)}</ul>
      </StyledAllergenInfo>
    )
  }
}

const StyledAllergenInfo = styled.div`
  margin-bottom: 40px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      margin: 0 15px;
      padding: 0;

      color: ${color.burntOrange};
      ${costaTextBold};

      font-size: 14px;
      text-transform: capitalize;
    }
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
  }
`
