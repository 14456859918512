import React, { useState } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import { useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'

import Button from 'ui/src/Button'
import { above, color, costaDisplayWaveBold, costaText } from 'ui/src/styles'
import PromoAppButtons from '@cw-monorepo/cw-gatsby/src/components/PromoAppButtons/promoAppButtons'
import cardStatic from '@cw-monorepo/cw-gatsby/src/images/club-card.png'
import androidImage from 'cw-frontend/src/images/google-store.jpg'
import iosImage from 'cw-frontend/src/images/apple-store.png'
import PopupCTA from 'ui/src/PopupCTA/popupCTA'
import type { Microcopy } from 'utils/src/microcopy'
import { registerRequest } from 'cw-frontend/src/config/authConfig'

export interface IProps {
  microcopy: Microcopy
}

export function RegisterContent(props: IProps) {
  const { microcopy } = props
  const [hasCard, setHasCard] = useState<boolean>()
  const { inProgress, instance } = useMsal()
  const [hasError, setHasError] = useState(false)

  async function handleRegister() {
    try {
      setHasError(false)
      await instance.loginRedirect(registerRequest)
    } catch (error) {
      setHasError(true)
    }
  }

  return (
    <Container>
      <Heading className="heading">{microcopy.costaClub.register.heading}</Heading>
      <Text>{microcopy.costaClub.register.copy}</Text>
      <ButtonsContainer>
        <Button
          variant="outline"
          onClick={handleRegister}
          disabled={inProgress !== InteractionStatus.None}
        >
          {microcopy.costaClub.register.buttonYes}
        </Button>
        <Button
          variant="outline"
          className={classNames({ focus: hasCard === false })}
          onClick={() => setHasCard(false)}
        >
          {microcopy.costaClub.register.buttonNo}
        </Button>
      </ButtonsContainer>
      {hasCard === undefined && (
        <CardContainer>
          <Image src={cardStatic} alt={microcopy.costaClub.register.cardDescription} />
        </CardContainer>
      )}
      {hasCard === false && (
        <PromoContainer>
          <PromoAppButtons
            content={
              <>
                <h3>{microcopy.costaClub.register.appsHeading}</h3>
                <p>{microcopy.costaClub.register.appsCopy}</p>
              </>
            }
            appButtons={[
              {
                title: microcopy.costaClub.register.iosImageDescription,
                platform: 'ios',
                appLinkUrl: microcopy.costaClub.register.iosLink,
                image: {
                  file: { url: iosImage },
                },
              },
              {
                title: microcopy.costaClub.register.androidImageDescription,
                platform: 'android',
                appLinkUrl: microcopy.costaClub.register.androidLink,
                image: {
                  file: { url: androidImage },
                },
              },
            ]}
            standardStyleBgColour="Grey"
            campaignStyleBgColour={null}
            slim
          />
        </PromoContainer>
      )}
      <PopupCTA
        type="button"
        content={
          <div>
            <h3>{microcopy.costaClub.register.error.heading}</h3>
            <p>{microcopy.costaClub.register.error.message}</p>
          </div>
        }
        buttonValue={microcopy.costaClub.register.error.buttonLabel}
        visible={hasError}
        icon={false}
        click={() => setHasError(false)}
      />
    </Container>
  )
}

const Container = styled.div`
  background-color: ${color.white};
  margin: 0 auto;
  padding: 40px 25px;

  ${above.tablet`
    width: 100%;
  `}

  ${above.tabletLarge`
    width: 90%;
  `}

  ${above.desktop`
    width: 1274px;
  `}
`

const Heading = styled.h1`
  ${costaDisplayWaveBold}
  color: ${color.costaRed};
  margin-top: 0px;
  margin-bottom: 15px;
  text-align: center;
`

const Text = styled.p`
  ${costaText}
  margin-bottom: 35px;
  text-align: center;
`

const ButtonsContainer = styled.div`
  display: grid;
  grid-auto-columns: 100px;
  grid-auto-flow: column;
  gap: 30px;
  justify-content: center;
  margin-bottom: 40px;

  a {
    margin-bottom: 0px;
    width: 100%;
  }
`

const CardContainer = styled.figure`
  width: 288px;
  height: 182px;
  margin: 16px auto 72px;
  display: block;

  ${above.tablet`
    width: 366px;
    height: 234px;
    margin: 16px auto 96px;
  `}

  ${above.tabletLarge`
    margin: 16px auto 72px;
  `}
`

const Image = styled.img`
  width: 100%;
`

const PromoContainer = styled.div`
  article {
    border-radius: 8px;
    margin: 0px auto;
    overflow: hidden;

    ${above.mobile`
      max-width: 560px;
    `}
  }
`
