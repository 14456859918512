import styled from 'styled-components'
import { above, color, costaText, costaTextBold } from 'ui/src/styles/index'

export const Tabs = styled.div`
  margin: 0 auto -50px;
  width: 100%;
  background: ${color.white};

  ${above.tablet`
    margin: 0 auto -92px;
  `}

  ${above.tabletLarge`
    padding: 0;
    max-width: 824px;
    margin-bottom: 92px;
  `}

  ${above.desktop`
    max-width: 1274px;
  `}

  .subHeading {
    ${costaText}
    color: ${color.greyDarker};
    text-align: center;
    font-size: 18px;
    line-height: 28px;
    margin: 0 auto;
    padding: 48px 0 24px 0;
    max-width: 288px;

    ${above.tablet`
      max-width: 566px;
      padding: 48px 0;
    `}
  }

  .tabButtonsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tabButtons {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 32px;
    ${costaText}
    color: ${color.costaRed};
    width: 242px;
    padding: 16px 0;
    height: 132px;
    margin-bottom: 24px;

    ${above.tablet`
      width: 496px;
    `}

    .tab {
      text-align: center;
      display: inline-block;
    }

    .tabSelected {
      text-align: center;
      display: inline-block;
      outline: none;
      ${costaTextBold}
      color: ${color.lightRed};
      padding-bottom: 6px;
      border-bottom: 4px solid ${color.lightRed};
      border-radius: 2px;
    }

    .tab,
    .tabSelected {
      width: 136px;
      transform: scale(0.9);

      ${above.tablet`
        transform: scale(1);
      `}
    }

    .svgWrapper {
      height: 60px;
      transform: scale(0.8);

      ${above.tablet`
        transform: scale(1);
      `}
    }

    a:focus {
      outline: none;
      box-shadow: none;
    }

    .caption {
      text-align: center;
      font-size: 16px;
    }
  }
`

export const TabWrapper = styled.div`
  margin: 0 16px;

  ${above.tablet`
    margin: 0;
  `}

  form {
    width: 100%;

    ${above.tablet`
      width: 688px;
    `}

    ${above.desktop`
      width: 560px;
    `}
  }

  .submitGroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 90px;

    input {
      width: auto;
    }

    .success,
    .error {
      display: block;
      padding-top: 10px;
      color: ${color.successGreen};
    }

    .error {
      color: ${color.alertError};
    }
  }

  .saveButton {
    display: flex;
    justify-content: center;
    height: 55px;
    align-items: center;
    padding: 0 28px;
    border-radius: 32px;
    border-width: 0;
    color: ${color.offWhite};
    background-color: ${color.costaRed};
    cursor: pointer;
  }

  p.errors {
    font-size: 12px;
    color: ${color.alertError};
  }

  section,
  fieldset {
    margin-bottom: 24px;

    input {
      width: 100%;
      margin-bottom: 10px;
      padding: 12px 16px;
      border: 1px solid ${color.greyCore};
      border-radius: 3px;
    }

    h4 {
      font-size: 16px;
      color: ${color.costaRed};
      margin: 0 0 8px 0;
      padding: 0;
    }

    p {
      font-size: 16px;
      margin: 0 0 8px 0;
      line-height: 24px;
      padding: 0;
    }
  }
`
