import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { color, above, costaTextBold } from 'ui/src/styles/index'
import Icon from '../Icon/icon'
import ICONS from '../../../nutrition/NutritionView/resources/ICONS'

export default class AllergenInfo extends PureComponent {
  allergenInfo = (nutritionData, allergenList) => {
    const flatAllergensData = nutritionData
    flatAllergensData.allergens.vegetarian = nutritionData.dietaryChoices.suitableForVegetarians
    flatAllergensData.allergens.vegan = nutritionData.dietaryChoices.suitableForVegans

    return allergenList.map(allergen => {
      if (flatAllergensData && flatAllergensData.allergens[allergen[0]] === 'Yes') {
        if (
          (allergen[0] === 'treeNuts' || allergen[0] === 'peanuts') &&
          flatAllergensData.allergens.treeNuts === 'Cross Contamination Risk'
        ) {
          return (
            <li className="mayContainNuts" key="mayContainNuts">
              <Icon
                icon={ICONS[`allergen_${allergen[0]}`]}
                color={color.burntOrange}
                w={40}
                h={40}
                wrapped
                className="iconWrapper"
              />
              <div className="allergenName">May Contain Nuts</div>
            </li>
          )
        }

        return (
          <li className={allergen[0]} key={allergen[0]}>
            <Icon
              icon={ICONS[`allergen_${allergen[0]}`]}
              color={color.burntOrange}
              w={40}
              h={40}
              wrapped
              className="iconWrapper"
            />
            <div className="allergenName">{allergen[1]}</div>
          </li>
        )
      }
      return null
    })
  }

  render() {
    const { activeNutrition, showTitle = true } = this.props
    const allergenList = [
      ['vegan', 'Vegan'],
      ['vegetarian', 'Vegetarian'],
      ['treeNuts', 'Contains Nuts'],
    ]

    return (
      <StyledAllergenInfo>
        {showTitle && <h3>Allergen Information</h3>}
        <ul>{this.allergenInfo(activeNutrition, allergenList)}</ul>
      </StyledAllergenInfo>
    )
  }
}

const StyledAllergenInfo = styled.div`
  margin-bottom: 40px;
  padding: 0 20px 0 10px;

  ${above.tablet`
    padding: 0;
  `}

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;

    li {
      ${costaTextBold};
      display: inline-block;
      margin: 0 15px;
      padding: 0;
      color: ${color.burntOrange};
      font-size: 12px;
      text-transform: capitalize;
      text-align: center;
    }
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
  }

  .allergenName {
    max-width: 64px;
    line-height: 22px;
    font-family: 'Costa Text Regular', sans-serif;
  }
`
