import React from 'react'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { useMsal } from '@azure/msal-react'
import { InteractionStatus } from '@azure/msal-browser'
import { color } from 'ui/src/styles'
import { b2cNameValidation } from 'utils/src/validators'
import { useAccountLoyalty } from 'cw-frontend/src/contexts/LoyaltyContext'
import { changePasswordRequest, changeEmailRequest } from 'cw-frontend/src/config/authConfig'
import { useAccountForm, FormData } from '../../hooks/useAccountForm'
import { Status } from '../../utils/api/fetch'
import { CssSpinner } from 'ui/src/CssSpinner'
import PopupCTA from 'ui/src/PopupCTA/popupCTA'
import { Wrapper } from './AccountDetails.styles'
import type { Microcopy } from 'utils/src/microcopy'

import { Tick } from 'cw-frontend/src/svg/Tick'

interface IProps {
  microcopy: Microcopy
}

export default function AccountDetails({ microcopy }: IProps) {
  const { instance, inProgress } = useMsal()
  const account = instance.getActiveAccount()
  const { cards } = useAccountLoyalty()
  const { onSubmit, register, errors, state, setState, user } = useAccountForm()
  const locationHash = window.location.hash

  const handleChangePasswordRedirect = () => {
    if (inProgress === InteractionStatus.None) {
      instance.acquireTokenRedirect(changePasswordRequest).catch(error => console.log(error))
    }
  }

  const handleChangeEmailRedirect = () => {
    if (inProgress === InteractionStatus.None) {
      instance.acquireTokenRedirect(changeEmailRequest).catch(error => console.log(error))
    }
  }

  const handleFocus = () => {
    setState(Status.None)
  }

  const saveButtonState = (state: Status) => {
    switch (state) {
      case Status.Updating:
        return (
          <div className="saveButton">
            <CssSpinner size="30px" color={color.offWhite} />
          </div>
        )
      case Status.Success:
        return (
          <div className="saveButton">
            <Tick />
          </div>
        )
      default:
        return <input className="saveButton" type="submit" value="Save" />
    }
  }

  return (
    <Wrapper>
      <form onSubmit={onSubmit}>
        {cards.length > 0 && <Cards cards={cards} />}
        <>
          {user && (
            <>
              <Names register={register} errors={errors} onFocus={handleFocus} />
              <Dob register={register} errors={errors} onFocus={handleFocus} />
            </>
          )}
          <ChangeInput
            label="Email"
            value={account?.idTokenClaims?.email as string}
            onClick={handleChangeEmailRedirect}
          />
          <ChangeInput label="Password" value="**********" onClick={handleChangePasswordRedirect} />
          {user && (
            <div className="submitGroup">
              {saveButtonState(state)}
              {state === Status.Success && (
                <span className="success">Your details have been updated</span>
              )}
              {state === Status.Error && (
                <span className="error">Oops, something went wrong! Try again.</span>
              )}
            </div>
          )}
        </>
      </form>

      <PopupCTA
        type="button"
        content={
          <div>
            <h3>{microcopy.account.emailChanged.heading}</h3>
            <p>{microcopy.account.emailChanged.copy}</p>
          </div>
        }
        buttonValue={microcopy.account.emailChanged.buttonLabel}
        visible={locationHash.includes('notify=email')}
        click={() => window.location.assign('/costa-club/account-home#/details')}
        icon={false}
        customStyles=""
      />
    </Wrapper>
  )
}

function ChangeInput({
  label,
  value,
  onClick,
}: {
  label: string
  value: string
  onClick: () => void
}) {
  return (
    <section className="changeGroup">
      <h4>{label}</h4>
      <div className="group">
        <span className="truncate">{value}</span>
        <a onClick={onClick} title={`Change ${label}`}>
          Change
        </a>
      </div>
    </section>
  )
}

function Cards({ cards }: { cards: string[] }) {
  return (
    <section className="cards">
      <h4>Cards</h4>
      {cards.map(card => (
        <p key={card}>{card}</p>
      ))}
    </section>
  )
}

function isSixteen(value: string) {
  const birthDate = new Date(value)
  const today = new Date()
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age >= 16
}

function Dob({
  register,
  errors,
  onFocus,
}: {
  register: UseFormRegister<FormData>
  errors: FieldErrors<FormData>
  onFocus: () => void
}) {
  const today = new Date()
  const oneTwentyYearsAgo = new Date(today.getFullYear() - 120, today.getMonth(), today.getDate())

  return (
    <section className="dob">
      <h4>Date of birth</h4>
      <p>Let us know when your special day is and get a free slice of cake.</p>
      <input
        data-testid="dob"
        type="date"
        min={oneTwentyYearsAgo.toISOString().split('T')[0]}
        max={today.toISOString().split('T')[0]}
        {...register('dateOfBirth', {
          validate: (value: unknown) => {
            if (!value) return
            if (!isSixteen(value as string)) {
              return "Sorry, you're too young for Costa Club"
            }
          },
        })}
        onFocus={onFocus}
      />
      <p className="errors">{errors.dateOfBirth?.message}</p>
    </section>
  )
}

function Names({
  register,
  errors,
  onFocus,
}: {
  register: UseFormRegister<FormData>
  errors: FieldErrors<FormData>
  onFocus: () => void
}) {
  return (
    <section className="names">
      <h4>Name</h4>
      <input
        placeholder="Enter your first name"
        {...register('firstName', {
          required: { value: true, message: 'First name is required' },
          pattern: {
            value: b2cNameValidation,
            message: 'First name invalid',
          },
          maxLength: {
            value: 255,
            message: 'First name too long',
          },
          minLength: {
            value: 1,
            message: 'First name too short',
          },
        })}
        onFocus={onFocus}
      />
      <p className="errors">{errors.firstName?.message}</p>
      <input
        placeholder="Enter your last name"
        {...register('lastName', {
          required: { value: true, message: 'Last name is required' },
          pattern: {
            value: b2cNameValidation,
            message: 'Last name invalid',
          },
          maxLength: {
            value: 255,
            message: 'Last name too long',
          },
          minLength: {
            value: 1,
            message: 'Last name too short',
          },
        })}
        onFocus={onFocus}
      />
      <p className="errors">{errors.lastName?.message}</p>
    </section>
  )
}
