import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { costaTextBold } from 'ui/src/styles/index'
import AllergenInfo from '../AllergenInfo/allergenInfo'
import NutritionTable from '../NutritionTable/nutritionTable'

import {
  infoTableRows,
  allergensTableRows,
  cerealsTableRows,
} from '../../helpers/nutritionTableHelpers'

export default class FoodNutrition extends PureComponent {
  static defaultProps = {
    activeNutrition: null,
    productName: null,
  }

  render() {
    const { activeNutrition, productName } = this.props
    if (!activeNutrition || !productName) {
      return (
        <div
          style={{
            display: 'none',
          }}
        />
      )
    }

    const ingredientsWithCaps = activeNutrition.ingredients
      ? activeNutrition.ingredients.replace(/([A-Z]{2,})/g, '<strong>$1</strong>')
      : null

    return (
      <div data-cy="food-product-info">
        <h1 data-cy="product-name">
          {activeNutrition.nutritionPerPortion?.portionWeight
            ? `${productName} - ${activeNutrition.nutritionPerPortion.portionWeight}g`
            : productName}
        </h1>

        {activeNutrition.ingredients && (
          <div data-cy="product-ingredients">
            <h3>Ingredients</h3>
            <IngredientsList dangerouslySetInnerHTML={{ __html: ingredientsWithCaps }} />
          </div>
        )}
        {(activeNutrition.dietaryChoices?.suitableForVegetarians === 'Yes' ||
          activeNutrition.dietaryChoices?.suitableForVegans === 'Yes' ||
          activeNutrition.allergens?.treeNuts === 'Yes') && (
          <AllergenInfo activeNutrition={activeNutrition} />
        )}

        {activeNutrition.allergens && (
          <>
            <NutritionTable
              title="Cereals Containing Gluten"
              display="twoCol"
              rows={cerealsTableRows(activeNutrition)}
            />
            <NutritionTable
              title="Allergens Present"
              display="twoCol"
              rows={allergensTableRows(activeNutrition)}
            />
          </>
        )}

        {activeNutrition.nutritionPerPortion && (
          <NutritionTable
            title="Nutritional Information"
            display="threeCol"
            headings={[
              null,
              'Per 100g/ml',
              `Per portion (${activeNutrition.nutritionPerPortion.portionWeight}g)`,
            ]}
            rows={infoTableRows(activeNutrition)}
          />
        )}
      </div>
    )
  }
}

const IngredientsList = styled.div`
  margin-bottom: 40px;
  b,
  strong {
    font-family: ${costaTextBold};
  }

  &.none {
    display: none;
  }
`
