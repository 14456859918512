import axios from 'axios'
import settingsHelper from 'utils/src/settings'

async function mdmGraphqlRequest(query, env, vars = {}) {
  const request = {
    method: 'POST',
    credentials: 'same-origin',
    headers: { 'content-type': 'application/json', 'X-Channel': 'web' },
    data: JSON.stringify({ query, vars }),
  }

  const response = await axios({
    url: `${settingsHelper.getBaseUrl(env, ['nutrition'])}`,
    ...request,
  })
  const responseBody = await response.data
  if (responseBody.errors) {
    const message = responseBody.errors.map(error => error.message).join('\n')
    throw new Error(message)
  }
  return responseBody.data
}

async function getCategories(env) {
  const query = `
    query WebProductsAndMasterProducts {
      webProductTypes {
        items {
          id
          name
          priority
          webProductCategories {
            id
            name
            priority
            masterProducts {
              id
              isActive
              groupCode
              webProductPriority
              allowedVariations {
                categoryName
                variationCategoryOptions {
                  variationName
                  masterProductId
                  masterProductCode
                  masterProductDisplayName
                  masterProduct {
                    nutritionProducts {
                      id
                      productCode
                      effectiveFromDate
                      ingredients
                      variations {
                        coffeeType
                        milkSuffix
                        milkType
                        serviceDelivery
                        size
                      }
                      dietaryChoices {
                        suitableForVegetarians
                        suitableForVegans
                      }
                      allergens {
                        celery
                        cereals {
                          wheat
                          rye
                          barley
                          oat
                        }
                        crustacean
                        egg
                        fish
                        lupin
                        milk
                        mollusc
                        mustard
                        peanut
                        sesame
                        soya
                        sulphite
                        treeNuts
                        treeNutSource
                      }
                      nutritionPer100g {
                        carbohydrates
                        energykCal
                        energykJ
                        fat
                        fibre
                        protein
                        salt
                        saturates
                        sugars
                        vitaminB6
                        vitaminB12
                        vitaminC
                        zinc
                      }
                      nutritionPerPortion {
                        portionWeight
                        carbohydrates
                        energykCal
                        energykJ
                        fat
                        fibre
                        protein
                        salt
                        saturates
                        sugars
                        vitaminB6
                        vitaminB12
                        vitaminC
                        zinc
                        portionWeight
                      }
                    }
                  }
                }
                defaultValue
              }
            }
          }
          
        }
      }
    }
  `
  const { webProductTypes } = await mdmGraphqlRequest(query, env, {})
  return webProductTypes.items
}

async function getProductsForCategory(productList, effectiveFromDate, env) {
  const query = `
  query MasterProduct {
    masterProducts(groupCodes: [${productList.map(item => `"${item}"`)}], effectiveFromDate: ${
    effectiveFromDate ? `"${effectiveFromDate}"` : null
  }){
      items {
        id
        brandName
        productDisplayName
        productDescription
        productCode
        groupCode
        images {
          imageStyle
          imageUrl
        }
        variations {
            variationCategoryName
            variationName
        }
        nutritionProducts {
          id
          productCode
          effectiveFromDate
          ingredients
          variations {
            coffeeType
            milkSuffix
            milkType
            serviceDelivery
            size
          }
          dietaryChoices {
            suitableForVegetarians
            suitableForVegans
          }
          allergens {
            celery
            cereals {
              wheat
              rye
              barley
              oat
            }
            crustacean
            egg
            fish
            lupin
            milk
            mollusc
            mustard
            peanut
            sesame
            soya
            sulphite
            treeNuts
            treeNutSource
          }
          nutritionPer100g {
            carbohydrates
            energykCal
            energykJ
            fat
            fibre
            protein
            salt
            saturates
            sugars
            vitaminB6
            vitaminB12
            vitaminC
            zinc
          }
          nutritionPerPortion {
            portionWeight
            carbohydrates
            energykCal
            energykJ
            fat
            fibre
            protein
            salt
            saturates
            sugars
            vitaminB6
            vitaminB12
            vitaminC
            zinc
            portionWeight
          }
        }
      }
    }
  }
`
  const {
    masterProducts: { items },
  } = await mdmGraphqlRequest(query, env, {})

  return items
}

export { getCategories, getProductsForCategory }
