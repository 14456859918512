import React from 'react'
import { LoyaltyProvider } from 'cw-frontend/src/contexts/LoyaltyContext'
import { UserProvider } from 'cw-frontend/src/contexts/UserContext'
import { TransactionsProvider } from 'cw-frontend/src/contexts/TransactionContext'
import { PreferencesProvider } from 'cw-frontend/src/contexts/PreferenceContext'

export const AccountProviders = ({ children }: { children: React.ReactNode }) => (
  <LoyaltyProvider>
    <UserProvider>
      <PreferencesProvider>
        <TransactionsProvider>{children}</TransactionsProvider>
      </PreferencesProvider>
    </UserProvider>
  </LoyaltyProvider>
)
