import styled from 'styled-components'
import { above, color } from 'ui/src/styles/index'

export const StampRow = styled.div`
  display: flex;
  justify-content: center;
  & + & {
    margin-top: 9px;

    ${above.tablet`
      margin-top: 11px;
    `}

    ${above.desktop`
      margin-top: 24px;
    `}
  }
`

export const StyledStamp = styled.div`
  & + & {
    margin-left: 6px;

    ${above.tablet`
      margin-left: 9px;
    `}

    ${above.desktop`
      margin-left: 19px;
    `}
  }

  svg {
    height: 26px;
    width: 26px;

    ${above.tablet`
      height: 34px;
      width: 34px;
    `}

    ${above.desktop`
      height: 64px;
      width: 64px;
    `}

    color: ${color.white};
  }
`
