import React from 'react'
import styled from 'styled-components'
import { color, costaText, above } from 'ui/src/styles/index'

import arrowDownRed from '../../../../resources/nutrition/arrow-down-red.svg'
import arrowDownDisabled from '../../../../resources/nutrition/arrow-down-disabled.svg'

function SelectDropdown({
  title = null,
  htmlId = null,
  onChange = null,
  defaultOption = null,
  options = null,
}) {
  return (
    <StyledSelectDropdown>
      <h5 className={!options ? 'disabled' : ''}>{title}</h5>
      <select
        name={htmlId}
        id={htmlId}
        onChange={onChange}
        disabled={!options}
        data-cy={`select__${title.toLowerCase()}`}
      >
        <option>{defaultOption}</option>
        {options &&
          options.map(item => (
            <option value={item.id} key={item.id}>
              {item.name || item.productName}
            </option>
          ))}
      </select>
    </StyledSelectDropdown>
  )
}

export default SelectDropdown

const StyledSelectDropdown = styled.div`
  text-align: left;
  margin: 50px 0;

  h5 {
    display: inline-block;
    margin: 0 30px 20px 0;
    color: ${color.costaRed};
    font-size: 24px;

    &.disabled {
      color: ${color.greyCore};
    }
  }

  select {
    display: inline-block;
    width: 100%;
    height: 56px;
    border: 2px solid ${color.greyCore};
    border-radius: 30px;
    color: ${color.greyDarker};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 16px;
    background-color: ${color.white};
    ${costaText};
    padding: 3px 50px 0 25px;
    overflow: hidden;
    cursor: pointer;
    background-image: url(${arrowDownRed});
    background-size: 14px 11px;
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;

    ${above.mobile`
      font-size: 18px;
    `}

    &:focus {
      outline: none;
      box-shadow: 0px 0px 3px 1px ${color.lightRed};
      appearance: none;
      background-color: $white;
    }

    &:disabled {
      color: ${color.greyCore};
      border: 2px solid ${color.greyCore};
      background-color: $white;
      background-image: url(${arrowDownDisabled});
      cursor: not-allowed;
      /* IE11 */
      &::-ms-value {
        background: none;
        color: ${color.greyCore};
      }
    }

    /* IE11 */
    &::-ms-expand {
      display: none;
    }
    &::-ms-value {
      background: none;
      color: ${color.greyDarker};
    }
  }
`
