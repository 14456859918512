import { useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { IPublicClientApplication } from '@azure/msal-browser'

function LogoutPage() {
  const { instance } = useMsal()
  const handleLogout = (instance: IPublicClientApplication) => instance.clearCache()

  useEffect(() => {
    handleLogout(instance)
  }, [instance])

  return null
}

export default LogoutPage
