import styled from 'styled-components'
import { above, color, costaTextBold } from 'ui/src/styles'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .changeGroup {
    margin-bottom: 30px;

    .group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${color.greyCore};

      .truncate {
        display: inline-block;
        width: 290px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        ${above.tablet`
          width: 350px;
        `}

        ${above.tabletLarge`
          width: 350px;
        `}
      }

      a {
        ${costaTextBold};
        color: ${color.costaRed};
        font-size: 12px;
        line-height: 18px;
        border-bottom: 3px solid ${color.costaRed};
        cursor: pointer;
      }
    }
  }
`
