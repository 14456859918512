import { Product, AvailableCustomisations, Customisation } from '../../types'

function getCustomisations({
  product,
  category,
}: {
  product: Product
  category: string
}): Customisation[] {
  const variations =
    product.allowedVariations.find(variationCategory => variationCategory.categoryName === category)
      ?.variationCategoryOptions || []

  return variations.map(variation => ({
    id: variation.masterProductId || `${category}-none`,
    name: variation.variationName || 'None',
    nutrition: variation.masterProduct?.nutritionProducts,
  }))
}

export function getAvailableCustomisations(product: Product): AvailableCustomisations {
  //  Currently all available coffee types should be displayed
  const coffeeTypes = getCustomisations({ product, category: 'Coffee Choices' })

  //  Currently all extra shots should be displayed
  const extraShots = getCustomisations({ product, category: 'Extra Shots' })

  //  Syrup options without nutrition data (other than 'no syrup') should be removed
  const syrups = getCustomisations({ product, category: 'Syrups' }).filter(
    syrup => syrup.name === 'None' || syrup.nutrition?.length > 0
  )

  //  Extras options without nutrition data (other than 'no extra') should be removed
  const extras = getCustomisations({ product, category: 'Other Drink Extras' })
    .filter(extra => extra.name === 'None' || extra.nutrition?.length > 0)
    //  Per business logic, marshmallow is currently the only extra we currently want to display
    .filter(extra => ['None', 'Marshmallow'].includes(extra.name))

  return {
    coffeeTypes,
    extraShots,
    syrups,
    extras,
  }
}
