import React from 'react'
import { PageProps } from 'gatsby'

import { AccountChangedLayout } from 'cw-frontend/src/components/account/changed/layout'
import { getMicrocopyObject, useMicrocopy } from 'utils/src/microcopy'

interface PageContext {
  locale?: string
}

export default function AccountChangedPassword(props: PageProps<unknown, PageContext>) {
  const {
    location,
    pageContext: { locale = process.env.GATSBY_DEFAULT_LOCALE },
  } = props
  const microcopy = getMicrocopyObject(useMicrocopy(locale))

  return (
    <AccountChangedLayout
      location={location}
      seoTitle={microcopy.account.passwordChanged.seoTitle}
      heading={microcopy.account.passwordChanged.heading}
      copy={microcopy.account.passwordChanged.copy}
      buttonLabel={microcopy.account.accountChanged.buttonLabel}
    />
  )
}
