import React from 'react'
import { color } from 'ui/src/styles/index'

export const StampBeanStandardIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26">
      <defs>
        <linearGradient x1="15.67%" y1="21.234%" x2="93.046%" y2="86.162%" id="kebrah2saa">
          <stop stopColor={color.yellow100} offset="1%" />
          <stop stopColor={color.yellow200} offset="5%" />
          <stop stopColor={color.yellow300} offset="15%" />
          <stop stopColor={color.yellow400} offset="22%" />
          <stop stopColor={color.yellow500} offset="25%" />
          <stop stopColor={color.yellow600} offset="31%" />
          <stop stopColor={color.yellow700} offset="42%" />
          <stop stopColor={color.yellow800} offset="58%" />
          <stop stopColor={color.yellow900} offset="66%" />
          <stop stopColor={color.yellow1100} offset="72%" />
          <stop stopColor={color.yellow1200} offset="77%" />
          <stop stopColor={color.yellow1300} offset="81%" />
          <stop stopColor={color.yellow1400} offset="85%" />
          <stop stopColor={color.yellow1500} offset="89%" />
          <stop stopColor={color.yellow1600} offset="93%" />
          <stop stopColor={color.yellow500} offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M23.079 2.931c-.125-.125-.259-.228-.388-.343a1.25 1.25 0 0 0-.125-.115C20.374.576 17.44-.277 14.187.08 10.78.455 7.434 2.13 4.78 4.787c-5.556 5.552-6.39 13.756-1.858 18.28C4.84 24.981 7.441 26 10.361 26c.484 0 .969-.028 1.45-.081 3.41-.375 6.753-2.051 9.41-4.709 5.556-5.55 6.39-13.756 1.858-18.279"
          fill="url(#kebrah2saa)"
          fillRule="nonzero"
        />
        <path
          d="M19.624 19.617c-2.297 2.296-5.161 3.738-8.062 4.06a9.823 9.823 0 0 1-3.258-.167c1.399-.807 2.211-1.74 2.434-2.023.427-.448 1.978-2.408 2.38-7.509.08-1.229.642-4.78 2.316-6.497.024-.03 2.435-2.8 5.998-3 .016.016.034.03.05.044 3.652 3.65 2.819 10.42-1.858 15.092M6.376 6.382c2.297-2.297 5.161-3.738 8.062-4.06.404-.046.802-.068 1.191-.068 1.066 0 2.071.174 2.995.498-2.987 1.024-4.803 3.142-4.86 3.214-2.538 2.597-2.886 7.654-2.898 7.851-.364 4.634-1.745 6.096-1.743 6.096a1.204 1.204 0 0 0-.147.164c-.012.017-1.196 1.543-3.507 2.183a7.426 7.426 0 0 1-.951-.787C.866 17.824 1.699 11.054 6.376 6.383M23.079 2.93c-.123-.123-.259-.228-.388-.343-.039-.043-.083-.077-.128-.114C20.371.576 17.43-.279 14.188.08 10.78.46 7.439 2.13 4.779 4.787c-5.556 5.552-6.39 13.752-1.858 18.28C4.84 24.983 7.442 26 10.361 26c.476 0 .96-.027 1.451-.08 3.408-.38 6.749-2.052 9.409-4.709 5.556-5.55 6.39-13.752 1.858-18.28"
          fill={color.costaRed}
        />
      </g>
    </svg>
  )
}
