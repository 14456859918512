import React from 'react'
import styled from 'styled-components'
import { color, costaTextBold } from 'ui/src/styles/index'
import ProductList from '../ProductList/productList'

const ProductCategories = ({ categories, setActiveProduct, productPage }) => {
  const hasWebProductCategories = categories.length > 0 && categories[0].webProductCategories
  const hasMasterProducts = categories.length > 0 && categories[0].masterProducts
  const hasNoProducts =
    (hasWebProductCategories &&
      categories[0].webProductCategories.every(category => category.masterProducts.length === 0)) ||
    (hasMasterProducts && categories.every(category => category.masterProducts.length === 0))

  return (
    <>
      {hasNoProducts ? (
        // Display an empty state message when there are no products
        <EmptyStateMessage>
          Unfortunately, no products are available that meet your dietary requirements.
        </EmptyStateMessage>
      ) : // Render ProductList for categories with non-empty masterProducts
      hasMasterProducts ? (
        categories.map(
          category =>
            category.masterProducts.length > 0 && (
              <ProductList
                category={category}
                key={category.id}
                setActiveProduct={setActiveProduct}
                productPage={productPage}
              />
            )
        )
      ) : (
        hasWebProductCategories &&
        categories[0].webProductCategories.map(
          category =>
            category.masterProducts.length > 0 && (
              <ProductList
                category={category}
                key={category.id}
                setActiveProduct={setActiveProduct}
                productPage={productPage}
              />
            )
        )
      )}
    </>
  )
}

const EmptyStateMessage = styled.p`
  ${costaTextBold}
  margin: 96px auto 72px;
  text-align: center;
  color: ${color.costaRed};
  max-width: 400px;
`

export default ProductCategories
