import React from 'react'
import { color } from 'ui/src/styles/index'

interface SvgProps {
  active: boolean
}

export const DetailsIcon = (props: SvgProps) => {
  const { active } = props
  const activeColor = active ? color.lightRed : color.costaRed

  return (
    <svg width="40" height="50" viewBox="0 0 40 50">
      <g fill="none" fillRule="evenodd">
        <g fill={activeColor} stroke={activeColor}>
          <path
            d="M18.35 24h.291c2.67-.046 4.83-.996 6.424-2.813 3.507-4.003 2.924-10.866 2.86-11.52-.227-4.917-2.523-7.269-4.418-8.366C22.094.48 20.445.037 18.605 0h-.155c-1.011 0-2.998.166-4.902 1.264-1.913 1.097-4.246 3.45-4.473 8.402-.064.655-.647 7.518 2.86 11.521 1.586 1.817 3.745 2.767 6.415 2.813zM11.507 9.897c0-.027.01-.055.01-.074.3-6.613 4.938-7.323 6.924-7.323h.109c2.46.055 6.642 1.07 6.925 7.323 0 .028 0 .056.009.074.009.064.647 6.337-2.25 9.639-1.149 1.31-2.68 1.955-4.693 1.973h-.091c-2.004-.018-3.544-.663-4.683-1.973-2.888-3.284-2.269-9.584-2.26-9.639zM37.997 38.232v-.03c0-.078-.009-.157-.009-.245-.055-1.948-.176-6.502-4.19-7.958l-.093-.03c-4.172-1.13-7.642-3.688-7.679-3.718-.564-.423-1.341-.275-1.739.325-.398.6-.26 1.426.305 1.85.158.117 3.84 2.842 8.447 4.101 2.155.817 2.396 3.266 2.46 5.509 0 .088 0 .167.01.246.009.885-.047 2.252-.194 3.039-1.5.905-7.374 4.033-16.31 4.033-8.9 0-14.812-3.138-16.32-4.043-.148-.787-.212-2.154-.194-3.04 0-.078.01-.157.01-.245.064-2.243.305-4.692 2.46-5.509 4.607-1.259 8.29-3.993 8.446-4.102.565-.422.704-1.249.306-1.849-.398-.6-1.175-.747-1.74-.325-.036.03-3.487 2.587-7.678 3.719-.037.01-.065.02-.093.03C.187 31.454.067 36.01.012 37.946c0 .089 0 .167-.01.246v.03c-.009.511-.018 3.137.472 4.455.093.256.26.472.481.62.278.197 6.93 4.702 18.059 4.702 11.129 0 17.781-4.515 18.058-4.702.213-.148.389-.364.481-.62.463-1.308.454-3.934.444-4.446z"
            transform="translate(-692.000000, -1160.000000) translate(644.000000, 1161.000000) translate(49.000000, 0.000000)"
          />
        </g>
      </g>
    </svg>
  )
}
