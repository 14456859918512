import React from 'react'
import { color } from 'ui/src/styles/index'

export const StampBeanGreenIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26">
      <defs>
        <linearGradient x1="100%" y1="50%" x2=".123%" y2="50%" id="5ll7j5quaa">
          <stop stopColor={color.green400} offset="0%" />
          <stop stopColor={color.green300} offset="36.399%" />
          <stop stopColor={color.green200} offset="63.218%" />
          <stop stopColor={color.green100} offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M20.34 1.419c-6.565.197-11.148 2.483-11.322 2.57-6.48 3.115-7.6 8.332-7.711 8.955-.897 3.65.078 7.407.74 9.326a3.078 3.078 0 0 0 1.625 1.786c1.602.732 3.163.999 4.587.999 3.716 0 6.503-1.818 6.673-1.931 3.942-2.735 4.905-6.236 5.02-6.709.126-.424 1.035-3.738.121-7.942-.505-2.326.408-3.83 1.264-4.682.401-.401.519-1 .3-1.527a1.336 1.336 0 0 0-1.296-.845z"
          fill="url(#5ll7j5quaa)"
          transform="translate(1)"
        />
        <path
          d="M20.116 8.249c.932 4.228-.086 7.577-.12 7.7-.008.035-.832 3.428-4.658 6.051-.045.03-4.277 2.714-9.07 1.32 1.162-.698 1.833-1.521 2.03-1.794.376-.431 1.476-2.022 1.61-5.97.016-.908.305-3.543 1.476-4.868.02-.023 1.814-2.3 4.551-2.413a1.124 1.124 0 0 0 1.073-1.172 1.14 1.14 0 0 0-1.164-1.08c-3.804.157-6.135 3.163-6.179 3.226-1.864 2.102-1.987 5.845-1.994 6.248-.115 3.389-1.038 4.523-1.035 4.523a1.182 1.182 0 0 0-.138.169c-.032.047-.761 1.077-2.284 1.625-.558-1.704-1.473-5.29-.632-8.623.008-.033.015-.067.02-.1.008-.052.88-5.156 7.36-8.233.045-.022 4.153-2.033 10.094-2.515-.84 1.317-1.518 3.279-.94 5.906M24.042.93A1.488 1.488 0 0 0 22.599 0c-7.311.217-12.416 2.73-12.61 2.828-7.216 3.426-8.463 9.164-8.587 9.849-.998 4.014.087 8.148.823 10.259a3.406 3.406 0 0 0 1.812 1.965A12.31 12.31 0 0 0 9.144 26c4.138-.001 7.242-1.999 7.433-2.124 4.39-3.009 5.462-6.86 5.59-7.38.14-.466 1.153-4.111.135-8.735-.564-2.56.453-4.214 1.406-5.151.448-.441.579-1.1.334-1.68"
          fill={color.green500}
        />
      </g>
    </svg>
  )
}
