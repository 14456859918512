import React from 'react'
import RewardHint from './RewardHint'
import { BelowDesktop, Heading, Wrapper } from './LoyaltyCard.styles'
import StampsCard from './StampsCard'

interface Props {
  rewardCount: number
  stamps: string[]
  stampTotal: number
}

export default function LoyaltyCard(props: Props) {
  const { rewardCount, stamps, stampTotal } = props

  return (
    <Wrapper>
      <BelowDesktop>
        <Heading>Beans total</Heading>
        <RewardHint stampTotal={stampTotal} stampCount={stamps.length} />
      </BelowDesktop>
      <StampsCard stampTotal={stampTotal} rewardCount={rewardCount} stamps={stamps} />
    </Wrapper>
  )
}
