import React from 'react'
import { color } from 'ui/src/styles/index'

interface SvgProps {
  active: boolean
}

export const PreferencesIcon = (props: SvgProps) => {
  const { active } = props
  const activeColor = active ? color.lightRed : color.costaRed

  return (
    <svg width="61" height="55" viewBox="0 0 61 55">
      <defs>
        <path id="mzl6zeb2pa" d="M0 0L16.505 0 16.505 16.555 0 16.555z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-859.000000, -1314.000000) translate(821.000000, 1314.387324) translate(38.000077, 0.000000) translate(0.254402, 0.000000)">
                <mask id="dvwf9yr16b" fill={color.offWhite}>
                  <use xlinkHref="#mzl6zeb2pa" />
                </mask>
                <path
                  fill={activeColor}
                  d="M8.252 3.871c-1.12 0-2.242.427-3.098 1.282-.831.83-1.29 1.935-1.293 3.112-.003 1.177.451 2.285 1.279 3.118.828.835 1.93 1.296 3.103 1.298h.01c1.17 0 2.27-.456 3.098-1.283.832-.83 1.29-1.936 1.293-3.113.003-1.177-.451-2.284-1.28-3.119-.856-.863-1.983-1.295-3.112-1.295m.001 12.684h-.019c-2.204-.006-4.275-.872-5.83-2.439C.85 12.549-.005 10.468 0 8.256c.005-2.211.868-4.288 2.43-5.848 3.224-3.221 8.46-3.21 11.67.026 1.556 1.567 2.41 3.648 2.405 5.86-.005 2.211-.868 4.288-2.43 5.849-1.558 1.555-3.625 2.412-5.822 2.412"
                  mask="url(#dvwf9yr16b)"
                />
              </g>
              <path
                fill={activeColor}
                d="M52.16 41.561c-1.17 0-2.27.456-3.098 1.284-.832.83-1.29 1.935-1.293 3.112-.003 1.177.451 2.285 1.279 3.118.827.835 1.93 1.295 3.102 1.298h.01c1.17 0 2.27-.455 3.098-1.284.832-.83 1.291-1.935 1.294-3.112.002-1.177-.452-2.285-1.28-3.118-.827-.834-1.929-1.295-3.102-1.298h-.01zm0 12.685h-.018c-2.205-.005-4.275-.87-5.83-2.438-1.555-1.567-2.41-3.648-2.404-5.86.005-2.211.868-4.288 2.43-5.848 1.558-1.556 3.625-2.412 5.822-2.412h.019c2.204.005 4.275.87 5.83 2.438 1.555 1.567 2.409 3.648 2.404 5.86-.006 2.211-.868 4.288-2.43 5.848-1.558 1.556-3.626 2.412-5.822 2.412zM30.344 22.715c-1.169 0-2.27.456-3.098 1.284-.832.83-1.29 1.936-1.293 3.112-.003 1.177.451 2.285 1.28 3.119 1.707 1.721 4.494 1.728 6.21.014.831-.83 1.29-1.936 1.293-3.113.003-1.177-.451-2.284-1.28-3.118-.827-.835-1.929-1.295-3.102-1.298h-.01zm0 12.681c-2.119 0-4.238-.811-5.848-2.433-1.555-1.568-2.41-3.649-2.404-5.86.005-2.212.868-4.29 2.43-5.85 1.558-1.555 3.625-2.411 5.822-2.411h.02c2.204.005 4.274.871 5.829 2.438 1.555 1.567 2.409 3.648 2.404 5.86-.005 2.211-.868 4.288-2.43 5.848-1.608 1.606-3.715 2.408-5.822 2.408zM58.83 10.212h-44c-1.067 0-1.931-.867-1.931-1.937s.864-1.936 1.93-1.936h44c1.067 0 1.93.867 1.93 1.936 0 1.07-.863 1.937-1.93 1.937"
                transform="translate(-859.000000, -1314.000000) translate(821.000000, 1314.387324) translate(38.000077, 0.000000)"
              />
              <path
                fill={activeColor}
                d="M24.058 29.058H1.93C.864 29.058 0 28.19 0 27.12s.864-1.936 1.93-1.936h22.128c1.066 0 1.93.867 1.93 1.936 0 1.07-.864 1.937-1.93 1.937M58.83 29.058h-22.2c-1.066 0-1.93-.867-1.93-1.937s.864-1.936 1.93-1.936h22.2c1.066 0 1.93.867 1.93 1.936 0 1.07-.864 1.937-1.93 1.937M45.038 47.904H1.93c-1.066 0-1.93-.868-1.93-1.937 0-1.07.864-1.937 1.93-1.937h43.108c1.067 0 1.93.868 1.93 1.937 0 1.07-.863 1.937-1.93 1.937"
                transform="translate(-859.000000, -1314.000000) translate(821.000000, 1314.387324) translate(38.000077, 0.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
