import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { color, above, costaTextBold, costaText } from 'ui/src/styles/index'

import Icon from '../Icon/icon'
import ICONS from '../../../nutrition/NutritionView/resources/ICONS'

export default class PageSelect extends PureComponent {
  static defaultProps = {
    activePage: '',
  }

  render() {
    const { selectPage, activePage, disabledFood } = this.props

    return (
      <StyledPageSelect>
        <button
          type="button"
          onClick={() => selectPage('drinks', activePage)}
          className={activePage === 'drinks' ? 'active' : null}
          data-cy="page-select__drinks"
        >
          <Icon
            icon={ICONS.page_drinks}
            wrapped
            w={60}
            h={60}
            className={`iconWrapper ${
              activePage && activePage !== 'food' ? 'activeIconWrapper' : ''
            }`}
          />
          <span>Drinks</span>
          <div className={activePage && activePage !== 'food' ? 'underline' : ''} />
        </button>
        <button
          type="button"
          onClick={() => selectPage('food', activePage)}
          className={activePage === 'food' ? 'active' : null}
          disabled={disabledFood}
          data-cy="page-select__food"
        >
          <Icon
            icon={ICONS.page_food}
            w={60}
            h={60}
            wrapped
            className={`iconWrapper ${
              activePage && activePage !== 'drinks' ? 'activeIconWrapper' : ''
            }`}
          />
          <span>Food</span>
          <div className={activePage && activePage !== 'drinks' ? 'underline' : ''} />
        </button>
      </StyledPageSelect>
    )
  }
}

const StyledPageSelect = styled.div`
  text-align: center;
  padding-top: 10px;

  ${above.tablet`
    padding-top: 18px;
  `}

  button {
    margin: 0 20px;
    ${costaText};
    display: inline-block;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;

    &:disabled {
      span {
        color: ${color.greyLight};
      }
      svg {
        fill: ${color.greyLight};
      }
      .underline {
        border-color: ${color.greyLight};
      }
    }

    &:focus {
      outline: none;
      box-shadow: rgb(207, 168, 202) 0px 0px 8px 2px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }

  span {
    ${costaTextBold}
    color: ${color.costaRed};
    display: inline-block;
    padding: 0 5px 7px;
    border-radius: 4px;

    ${above.tablet`
      font-size: 18px;
    `}
  }

  .active {
    span {
      color: ${color.lightRed};
    }
    .underline {
      width: 50px;
      margin: auto;
      background-color: ${color.lightRed};
      border-top: 3px solid ${color.lightRed};

      ${above.tablet`
        width: 56px;
      `}
    }
  }

  .iconWrapper {
    width: 40px;
    height: 40px;
    margin: 30px auto 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${above.tablet`
      width: 51px;
      height: 51px;
    `}

    svg {
      fill: ${color.costaRed};
    }
  }

  .activeIconWrapper {
    svg {
      fill: ${color.lightRed};
    }
  }
`
