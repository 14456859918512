import React, { Component } from 'react'
import styled from 'styled-components'
import { color, costaText, above } from 'ui/src/styles/index'
import Icon from '../../components/Icon/icon'
import ICONS from '../../resources/ICONS'
import tick from '../../../../resources/nutrition/tick.png'

export default class DrinkFilters extends Component {
  showDeprecated = env => {
    switch (env) {
      case 'master':
        return !!process.env.GATSBY_DEPRECATE_CUP_SIZES_PROD === 'true'

      default:
        return !!process.env.GATSBY_DEPRECATE_CUP_SIZES_DEV === 'true'
    }
  }

  getIconButtons = (filterList, type) =>
    filterList.map(filter => {
      const { enabledFilters, disabledFilters, activeFilters, setFilter } = this.props
      const filter_1 = enabledFilters[type]?.has(filter[0]) && !disabledFilters.includes(filter[0])
      const filter_2 = enabledFilters[type]?.has(filter[1]) && !disabledFilters.includes(filter[1])
      const isEnabled = enabledFilters[type] && (filter_1 || filter_2)
      const filterCode = isEnabled ? (filter_1 ? 0 : 1) : 0

      return (
        <button
          key={filter[filterCode]}
          type="button"
          onClick={() => setFilter(type, isEnabled ? filter[filterCode] : null)}
          value={filter[filterCode]}
          disabled={!isEnabled}
        >
          <StyledIcon
            icon={ICONS[`${type}_${filter[filterCode]}`]}
            w={40}
            h={50}
            wrapped
            className={`iconWrapper ${isEnabled ? 'available' : 'disabled'} ${
              activeFilters[type] && activeFilters[type] === filter[filterCode] ? 'active' : null
            }`}
          />
          <span>{type === 'size' ? filter[2] : filter[1]}</span>
        </button>
      )
    })

  getCheckboxes = (filterList, type) =>
    filterList.map(filter => {
      const { enabledFilters, disabledFilters, activeFilters, setFilter } = this.props
      const isEnabled =
        enabledFilters[type] &&
        enabledFilters[type].has(filter[0]) &&
        !disabledFilters.includes(filter[0])

      return (
        <Checkbox key={filter[0]}>
          <input
            type="checkbox"
            id={filter[0]}
            name={filter[0]}
            value={filter[0]}
            checked={activeFilters[type] === filter[0]}
            onChange={() => setFilter(type, isEnabled ? filter[0] : null)}
            disabled={!isEnabled}
            className={activeFilters[type] === filter[0] ? 'checked' : null}
          />
          <label htmlFor={filter[0]}>{filter[1]}</label>
        </Checkbox>
      )
    })

  render() {
    const { enabledFilters } = this.props
    const milkFilters = [
      ['Whole', 'Whole'],
      ['Skimmed', 'Skimmed'],
      ['Semi-Skimmed', 'Semi Skimmed'],
      ['Coconut', 'Coconut'],
      ['Almond', 'Almond'],
      ['Soya', 'Soya'],
      ['Oat', 'Oat'],
      ['Ultimate', 'Ultimate Blend'],
    ]
    const sizeFiltersDeprecate = [
      ['primo', 'small', 'Primo'],
      ['medio', 'medium', 'Medio'],
      ['massimo', 'large', 'Massimo'],
      ['solo', 'solo', 'Solo'],
      ['doppio', 'doppio', 'Doppio'],
    ]

    const sizeFilters = [
      ['Mini', 'mini', 'Mini'],
      ['Small', 'primo', 'Small'],
      ['Medium', 'medio', 'Medium'],
      ['Large', 'massimo', 'Large'],
      ['Single', 'solo', 'Single'],
      ['Double', 'doppio', 'Double'],
    ]

    const diningFilters = [
      ['Takeaway', 'Takeaway'],
      ['Eat In', 'In store'],
    ]

    return (
      <>
        <StyledDrinkFilters data-cy="drink-filters">
          <h5
            className={
              enabledFilters.size && enabledFilters.size.size !== 0
                ? enabledFilters.size
                : 'disabledTitle'
            }
          >
            Size
          </h5>
          <div className="filterGroup size" data-cy="filter-group__cup-sizes">
            {this.getIconButtons(
              this.showDeprecated(process.env.GATSBY_CONTENTFUL_ENVIRONMENT)
                ? sizeFiltersDeprecate
                : sizeFilters,
              'size'
            )}
          </div>
        </StyledDrinkFilters>
        <StyledDrinkFilters data-cy="milk-filters">
          <h5
            className={
              enabledFilters.milk && enabledFilters.milk.size !== 0
                ? enabledFilters.milk
                : 'disabledTitle'
            }
          >
            Milk
          </h5>
          <div className="filterGroup" data-cy="filter-group__milk">
            {this.getIconButtons(milkFilters, 'milk')}
          </div>
        </StyledDrinkFilters>
        {enabledFilters &&
          enabledFilters.serviceDelivery &&
          enabledFilters.serviceDelivery.size > 0 && (
            <div
              style={{
                'text-align': 'center',
              }}
            >
              {this.getCheckboxes(diningFilters, 'serviceDelivery')}
            </div>
          )}
      </>
    )
  }
}

const StyledDrinkFilters = styled.div`
  h5 {
    display: inline-block;
    margin: 0 30px 20px 0;
    color: ${color.costaRed};
    vertical-align: top;

    &.disabledTitle {
      color: ${color.greyCore};
    }
  }

  .filterGroup {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;

    margin: 10px 0 10px 0;
  }

  button {
    display: inline-block;
    background: ${color.white};
    border: none;
    margin: 0 20px 0 0;
    cursor: pointer;
    width: 20%;
    text-align: center;

    ${above.tablet`
      width: auto;
    `}

    &:hover {
      background: ${color.white};
    }

    &:focus {
      outline: none;
      appearance: none;
      box-shadow: none;
    }

    span {
      display: inline-block;
      margin-top: 10px;

      color: ${color.greyDarker};
      ${costaText};

      width: 48px;
      height: 60px;
      text-align: center;
      font-size: 14px;

      @media screen and (min-width: 500px) {
        width: 80px;
        height: 60px;
        font-size: 16px;
      }
    }
  }
`

const Checkbox = styled.div`
  display: block;
  margin: 0 25px 10px 0;
  ${costaText};
  font-size: 18px;
  line-height: 40px;
  color: ${color.greyDarker};
  position: relative;
  text-align: left;

  @media screen and (min-width: 400px) {
    display: inline-block;
    margin: 25px 25px;
    text-align: center;
  }

  input {
    display: none;
  }

  input + label {
    padding-left: 52px;
  }

  input:disabled + label {
    opacity: 0.3;

    &::before {
      background-color: ${color.greyCore};
      background-image: none;
    }
  }

  & input + label::before {
    content: '';
    width: 32px;
    height: 32px;
    border-radius: 0.125rem;
    border: 0.125rem solid ${color.greyCore};
    background: transparent;
    position: absolute;
    left: 0;
    background-image: url(${tick});
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center center;
    cursor: pointer;
    top: 0;
  }

  & input:checked + label::before {
    background-color: ${color.costaRed};
    border-color: ${color.costaRed};
  }
`

const StyledIcon = styled(Icon)`
  &.iconWrapper {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    svg {
      width: 60%;
      height: 60%;
    }

    @media screen and (min-width: 500px) {
      width: 80px;
      height: 80px;
    }
  }
  &.disabled {
    background: ${color.greyCore};
    fill: ${color.white};
    cursor: not-allowed;
    border: none;
  }
  &.available {
    background: ${color.white};
    border: 1px solid ${color.greyCore};
    fill: ${color.costaRed};
  }
  &.active {
    background: ${color.lightRed};
    fill: ${color.white};
    border: none;
  }
`
