import styled from 'styled-components'
import { color, above, costaDisplayWaveBold, costaTextBold } from 'ui/src/styles/index'

export const StyledProductView = styled.div`
  box-sizing: border-box;
  position: relative;
  position: fixed;
  overflow-y: auto;
  overscroll-behavior: contain;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99999;
  background: ${color.white};
  height: 100dvh;
  &::-webkit-scrollbar {
    display: none;
  }

  ${above.tabletLarge`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
  `}

  h1,
  h2,
  h3 {
    color: ${color.costaRed};
    ${costaDisplayWaveBold};
    margin: 0;
  }

  h1 {
    font-size: 30px;
    line-height: 1.2;
    margin: 0 0 24px 0;

    ${above.tablet`
      font-size: 48px;
      line-height: 1.08;
    `}

    ${above.tabletLarge`
      margin-right: 36px;
    `}
  }

  h2 {
    font-size: 30px;
    line-height: 1.2;
    margin: 0 0 16px 0;

    ${above.tablet`
      font-size: 36px;
      line-height: 1.11;
    `}
  }

  h3 {
    font-size: 24px;
    line-height: 1.25;
    margin: 0 0 16px 0;

    ${above.tablet`
      font-size: 30px;
      line-height: 1.2;
    `}
  }
`

export const DataWrapper = styled.div`
  position: relative;
  margin: 30px 24px 64px;
  padding: 0px;

  ${above.tablet`
    margin: 50px 64px;
  `}

  ${above.tabletLarge`
    position: inherit;
    margin: 0;
    padding: 50px 40px;
    width: 56%;
    position: absolute;
    right: 0;
    min-height: 100%;
  `}

  ${above.desktop`
    width: 56%;
    padding: 50px 60px;
  `}

  ${above.desktopLarge`
    width: 50%;
  `}

  .content {
    margin: 0 auto;
    max-width: 660px;

    ${above.tabletLarge`
      max-width: 600px;
    `}
  }
`

export const CloseButton = styled.button`
  display: block;
  position: fixed;
  top: 24px;
  right: 24px;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 100;

  ${above.tablet`
    top: 32px;
    right: 32px;
  `}

  ${above.tabletLarge`
    top: 32px;
    right: 24px;
  `}
`

export const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    filter: brightness(1.5) saturate(1.5);
  }

  ${above.tablet`
    width: 32px;
    height: 32px;
  `}
`

export const ImageWrapper = styled.div`
  width: 100%;
  height: 268px;
  background-color: ${color.offWhite};
  display: flex;
  align-items: center;
  justify-content: center;

  ${above.tablet`
    height: 360px;
  `}

  ${above.tabletLarge`
    width: 44%;
    position: fixed;
    height: 100%;
  `}

  ${above.desktopLarge`
    width: 50%;
  `}

  .fixedImage {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    ${above.tabletLarge`
      width: 452px;
      height: 768px;
    `}

    ${above.desktopLarge`
      width: 640px;
    `}
  }

  img {
    max-height: 268px;
    max-width: 100%;

    ${above.tablet`
      height: 360px;
      max-height: 360px;
    `}

    ${above.tabletLarge`
      height: auto;
      max-width: 100%;
      max-height: 100%;
    `}
  }
`

export const ProductDescription = styled.div`
  p {
    margin-bottom: 20px;

    ${above.tablet`
      font-size: 18px;
    `}
  }

  a {
    color: ${color.costaRed};
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 4px;
    transition: color 0.3s ease;

    &:hover {
      color: ${color.lightRed};
    }
  }
`

export const AccordionsWrapper = styled.div`
  margin: 48px 0;
`

export const AllergensAdvice = styled.div`
  .dataButtons {
    padding: 12px 24px;
    font-size: 12px;
    letter-spacing: 0;
    margin: 32px 0 0;

    ${above.tablet`
      font-size: 18px;
      padding: 14px 24px 14px 28px;

      &:nth-of-type(2) {
        margin-left: -20px;
      }
    `}

    ${above.tabletLarge`
      margin: 40px 0 0;
    `}

    ${above.desktopLarge`
      margin: 48px 0 0;
    `}
  }
`

export const AllergenIconsWrapper = styled.div`
  margin: 32px -15px;

  &.reducedMargin {
    margin: 24px -15px;
  }

  > div {
    margin: 0 20px 0 0;
    padding: 0;
  }
`

export const SmallPrint = styled.div`
  p {
    font-size: 12px;
    margin-bottom: 32px;
    line-height: 1.33;

    ${above.tablet`
      font-size: 14px;
    `}
  }
`

export const Ingredients = styled.div`
  margin-bottom: 24px;
  line-height: 1.5;

  b,
  strong {
    ${costaTextBold};
  }
`

export const CalorieMessage = styled.div`
  margin-top: 48px;

  .calorieMessageWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0;

    ${above.tablet`
      margin: 0px 0px 32px 0px;
    `}

    ${above.desktopLarge`
      margin: 0px 0px 40px 0px;
      height: 16px;
  `}
  }
  .calorieMessageIcon {
    padding-right: 12px;
  }

  .calorieMessageText {
    font-size: 14px;
    margin: 0;
  }
`
