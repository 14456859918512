import styled from 'styled-components'
import { color, costaTextBold, above } from 'ui/src/styles/index'

export const Wrapper = styled.div`
  background: ${color.secondaryCoreLight};
  border-radius: 25px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  padding: 20px 0 18px 0;
  margin-bottom: 16px;

  ${above.tablet`
    padding: 24px 0 14px 0;
    max-width: 487px;
    border-radius: 37.1px;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 48px;
  `}

  ${above.desktop`
    padding: 26px 0 35px 0;
    max-width: 890px;
    border-radius: 67.8px;
    box-shadow: 0 27px 54px 0 rgba(0, 0, 0, 0.1);
  `}
`

export const AboveDesktop = styled.div`
  display: none;

  ${above.desktop`
    display: block;
  `}
`

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) 0fr minmax(0, 1fr);
  align-items: center;
`

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0 0 0;

  ${above.tablet`
    margin: 16px 0 0 0;
  `}

  ${above.desktop`
    margin: 32px 0 0 0;
  `}
`

export const CoffeeCupIcon = styled.img`
  position: absolute;
  right: 0;
  width: 86px;
  height: 100px;
  align-self: start;

  ${above.tablet`
    width: 115px;
    height: 133px;
  `}

  ${above.desktop`
    align-self: center;
    width: 233px;
    height: 272px;
  `}
`

export const ViewHistoryCTA = styled.button`
  ${costaTextBold}

  &:hover {
    color: ${color.lightRed};
  }

  &:focus {
    box-shadow: none;
  }

  border-style: none;
  padding: 0;
  background: none;
  color: ${color.costaRed}};
  cursor: pointer;

  ${above.tablet`
	  font-size: 18px;
	  line-height: 28px;
  `}

  ${above.desktop`
    font-size: 24px;
  `}
`

export const StampCountWrapper = styled.div`
  justify-self: center;
  color: ${color.costaRed};
  ${costaTextBold}
  line-height: 0.8;
  font-size: 60px;

  ${above.tablet`
    font-size: 88px;
  `}

  ${above.desktop`
    font-size: 156px;
  `}
`

export const SmallText = styled.span`
  font-size: 16px;

  ${above.tablet`
    font-size: 24px;
  `}

  ${above.desktop`
    font-size: 50px;
  `}
`
