import React, { Component } from 'react'
import styled from 'styled-components'
import { above, color, costaTextBold, costaText } from 'ui/src/styles/index'
import CompactAccordion from 'ui/src/CompactAccordion/CompactAccordion'
import Icon from '../Icon/icon'
import ICONS from '../../../nutrition/NutritionView/resources/ICONS'

export default class DietFilters extends Component {
  getIconButtons = (filterList, type) =>
    filterList.map(filter => {
      const { activeFilters, setFilter, selectedIds, activePage } = this.props
      let cerealsFilterActive = false

      if (filter[0] === 'cereals' && activeFilters['cereals.wheat']) {
        cerealsFilterActive = true
      }

      return (
        <button
          key={filter[0]}
          type="button"
          onClick={() => setFilter(filter[0], selectedIds, activePage)}
          value={filter[0]}
          data-cy="filter"
          data-cy-id={`filter__${filter[0]}`}
          className="filterButton"
        >
          <Icon
            icon={ICONS[`${type}_${filter[0]}`]}
            wrapped
            w={60}
            h={60}
            className={`iconWrapper available ${
              activeFilters[filter[0]] || cerealsFilterActive ? 'active' : null
            }`}
          />
          <span>{filter[1]}</span>
        </button>
      )
    })

  render() {
    const { setFilter, selectedIds, activePage } = this.props
    const dietFiltersPrimary = [
      ['vegetarian', 'Vegetarian'],
      ['vegan', 'Vegan'],
    ]
    const dietFiltersSecondary = [
      ['milk', 'Milk'],
      ['treeNuts', 'Tree Nut'],
      ['peanut', 'Peanut'],
      ['soya', 'Soybeans'],
    ]
    const dietFiltersTertiary = [
      ['egg', 'Egg'],
      ['fish', 'Fish'],
      ['crustacean', 'Crustacean'],
      ['mollusc', 'Mollusc'],
      ['sulphite', 'Sulphite'],
      ['sesame', 'Sesame'],
      ['celery', 'Celery'],
      ['mustard', 'Mustard'],
      ['lupin', 'Lupin'],
      ['cereals', 'Cereals containing gluten'],
    ]

    return (
      <DrinkFilters>
        <h5>Dietary Preferences</h5>
        <div className="filterGroup" data-cy="filters__diet">
          {this.getIconButtons(dietFiltersPrimary, 'dietfilter')}
          <button
            onClick={() => setFilter(null, selectedIds, activePage, true)}
            className="clearButton"
            tabIndex="0"
            data-cy="filter__clear-btn"
          >
            Clear
          </button>
        </div>
        <h5>
          Allergens – Show options that do <span className="underline">not</span> contain…
        </h5>
        <div className="filterGroup" data-cy="filters__diet">
          {this.getIconButtons(dietFiltersSecondary, 'dietfilter')}
          <button
            onClick={() => setFilter(null, selectedIds, activePage, true)}
            className="clearButton"
            tabIndex="0"
            data-cy="filter__clear-btn"
          >
            Clear
          </button>
        </div>
        <CompactAccordion title="Show Full Allergens List">
          <div className="filterGroup" data-cy="filters__diet">
            {this.getIconButtons(dietFiltersTertiary, 'dietfilter')}
            <button
              onClick={() => setFilter(null, selectedIds, activePage, true)}
              className="clearButton"
              tabIndex="0"
              data-cy="filter__clear-btn"
            >
              Clear
            </button>
          </div>
        </CompactAccordion>
      </DrinkFilters>
    )
  }
}

const DrinkFilters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  ${above.mobile`
    padding: 0 24px;
  `}

  ${above.tablet`
    max-width: 560px;
    padding: 0;
  `}

  h5 {
    ${costaTextBold}
    display: inline-block;
    margin: 0;
    font-size: 16px;
    color: ${color.costaRed};

    .underline {
      text-decoration: underline;
    }

    ${above.tablet`
      font-size: 18px;
    `}
  }

  .filterGroup {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    align-items: flex-start;
    row-gap: 8px;

    ${above.tablet`
      row-gap: 24px;
    `}
  }

  button {
    &.filterButton {
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: ${color.white};
      border: none;
      margin: 0;
      cursor: pointer;

      ${above.mobile`
        width: 20%;
      `}

      ${above.tablet`
        padding: 0;
      `}

      &:hover {
        background: ${color.white};
      }

      &:focus {
        z-index: 1;
      }
    }

    &.clearButton {
      width: 25%;
      ${costaTextBold};
      font-size: 14px;
      color: ${color.costaRed};
      text-align: center;
      border-radius: unset;
      text-decoration: underline;
      display: inline-block;
      background: none;
      border: none;
      cursor: pointer;
      margin-top: 14px;

      &:focus {
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      ${above.mobile`
        width: 20%;
      `}

      ${above.tablet`
        font-size: 18px;
        margin-top: 26px;
      `}
    }

    span {
      ${costaText};
      margin-top: 10px;
      color: ${color.greyDarker};
      width: 110%;
      text-align: center;
      font-size: 12px;
      max-width: 80px;

      @media screen and (min-width: 380px) {
        font-size: 14px;
      }

      ${above.tablet`
        font-size: 16px;
        width: auto;
        max-width: 100px;
        line-height: 1.5;
      `}
    }
  }

  .iconWrapper {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: 78px;

    ${above.tablet`
      width: 78px;
      height: 78px;
    `}

    svg {
      width: 26px;
      height: 26px;

      ${above.tablet`
        width: 44px;
        height: 44px;
      `}
    }
  }

  .available {
    background: ${color.white};
    border: 1px solid #d5d2d2;
    fill: ${color.costaRed};

    ${above.tablet`
      border: 2px solid #d5d2d2;
    `}
  }

  .active {
    background: ${color.tertiaryLightest};
    fill: ${color.costaRed};
    border: none;
  }
`
