import React, { PureComponent } from 'react'
import ReactMarkdownWithHtml from 'react-markdown/with-html'
import styled from 'styled-components'
import { color, costaText, above } from 'ui/src/styles/index'
import AllergenInfo from '../AllergenInfo/allergenInfo'
import NutritionTable from '../NutritionTable/nutritionTable'

import {
  infoTableRows,
  allergensTableRows,
  cerealsTableRows,
} from '../../helpers/nutritionTableHelpers'

export default class DrinkNutrition extends PureComponent {
  static defaultProps = {
    activeNutrition: null,
    productName: null,
    activeProduct: {},
  }

  render() {
    const { activeNutrition, productName, activeProduct } = this.props
    return (
      <DrinkNutritionStyles data-cy="drink-product">
        <h1 data-cy="product-name">
          {productName} - {activeNutrition.nutritionPerPortion.portionWeight}ml
        </h1>

        {activeProduct.productDescription && (
          <ReactMarkdownWithHtml
            className="description"
            allowDangerousHtml
            data-cy="product-description"
          >
            {activeProduct.productDescription}
          </ReactMarkdownWithHtml>
        )}

        {(activeNutrition.dietaryChoices.suitableForVegetarians === 'Yes' ||
          activeNutrition.dietaryChoices.suitableForVegans === 'Yes' ||
          activeNutrition.allergens.treeNuts === 'Yes') && (
          <AllergenInfo activeNutrition={activeNutrition} />
        )}

        <NutritionTable
          title="Cereals Containing Gluten"
          display="twoCol"
          rows={cerealsTableRows(activeNutrition)}
        />

        <NutritionTable
          title="Allergens Present"
          display="twoCol"
          rows={allergensTableRows(activeNutrition)}
        />

        <NutritionTable
          title="Nutritional Information"
          display="threeCol"
          headings={[
            null,
            'Per 100g/ml',
            `Per portion (${activeNutrition.nutritionPerPortion.portionWeight}ml)`,
          ]}
          rows={infoTableRows(activeNutrition)}
        />
      </DrinkNutritionStyles>
    )
  }
}

const DrinkNutritionStyles = styled.div`
  .description {
    p {
      font-size: 16px;
      line-height: 1.56;
      box-sizing: border-box;

      ${costaText};
      color: ${color.greyDarker};

      margin-bottom: 20px;

      ${above.tablet`
        font-size: 18px;
      `}
    }

    a {
      ${costaText};
      color: ${color.costaRed};

      &:hover {
        color: ${color.lightRed};
      }
    }
  }
`
