import styled from 'styled-components'
import { above, color, costaTextBold, baseBodyText } from 'ui/src/styles/index'

export const Wrapper = styled.div`
  color: ${color.costaRed};
  ${baseBodyText};
  ${costaTextBold};

  ${above.mobile`
    font-size: 14px;
  `}

  ${above.desktop`
    font-size: 21px;
  `}
`
