import React from 'react'
import { color } from 'ui/src/styles/index'

export const GreenBeanEarnedIcon = () => {
  return (
    <svg width="32" height="32" fill="none" viewBox="0 0 32 33">
      <path
        fill={color.costaRed}
        d="M6.26 26.227a.75.75 0 1 0 1.145-.968l-1.146.968ZM27.25 18c0 6.213-5.037 11.25-11.25 11.25v1.5c7.042 0 12.75-5.708 12.75-12.75h-1.5Zm-22.5 0C4.75 11.787 9.787 6.75 16 6.75v-1.5C8.958 5.25 3.25 10.958 3.25 18h1.5ZM16 6.75c6.213 0 11.25 5.037 11.25 11.25h1.5c0-7.042-5.708-12.75-12.75-12.75v1.5ZM7.405 25.259A11.202 11.202 0 0 1 4.75 18h-1.5c0 3.135 1.132 6.007 3.01 8.227l1.145-.968Z"
      />
      <path
        fill={color.costaRed}
        d="M12.806 21.807s-2.137-.524-2.727-2.149c-1.132-3.13 2.027-6.087 2.238-6.288.177-.17 1.857-2.076 5.74-2.527 1.161-.134 1.845-.098 2.664.137.34.097.447.258.194.658-1.24 1.95-2.64 3.18-4.12 4.57-1.015.956-1.545 1.477-2.174 2.224-1.284 1.517-1.428 2.487-1.319 2.593.17.161 1.077-.962 2.167-2.077.646-.663 1.192-1.16 2.092-1.975 1.22-1.107 2.867-2.796 3.69-5.083.098-.269.317-.314.45-.104.601.94 1.444 3.642.488 6.74-1.11 3.597-4.39 6.177-6.517 5.852-2.13-.326-2.866-2.57-2.866-2.57Z"
      />
      <path
        stroke={color.costaRed}
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M13.5 21.5c-2 1.5-2.087 4.032-1.5 5.5 1 2.5 2.5 3 4 3"
      />
    </svg>
  )
}
