import React from 'react'
import { color } from 'ui/src/styles'
import { usePreferencesForm } from '../../hooks/usePreferencesForm'
import { CssSpinner } from 'ui/src/CssSpinner'
import { Status } from '../../utils/api/fetch'
import { Checkbox, Label, Wrapper } from './AccountPreferences.styles'

import { Tick } from 'cw-frontend/src/svg/Tick'

export default function AccountPreferences() {
  const { onSubmit, register, state, setState, preferences } = usePreferencesForm()

  const saveButtonState = (state: Status) => {
    switch (state) {
      case Status.Updating:
        return (
          <div className="saveButton">
            <CssSpinner size="30px" color={color.offWhite} />
          </div>
        )
      case Status.Success:
        return (
          <div className="saveButton">
            <Tick />
          </div>
        )
      default:
        return <input className="saveButton" type="submit" value="Save" />
    }
  }

  const handleFocus = () => {
    setState(Status.None)
  }

  const CheckboxWithTick = ({
    name,
    label,
  }: {
    name: CostaApi.Accounts.PermissionInternalKey
    label: string
  }) => {
    return (
      <li>
        <Checkbox id={name} value="yes" {...register(name)} />
        <Label htmlFor={name} onClick={handleFocus}>
          <Tick color={color.offWhite} />
          {label}
        </Label>
      </li>
    )
  }

  return (
    <Wrapper>
      {preferences && (
        <form onSubmit={onSubmit}>
          <fieldset>
            <h4>Show some love</h4>
            <p>
              Receive some extra beans and exclusive offers from Costa Ltd by updating your contact
              preferences.
            </p>
            <ul>
              <CheckboxWithTick name="marketingEmail" label="By email" />
              <CheckboxWithTick name="marketingAppPush" label="By push notifications" />
              <CheckboxWithTick
                name="survey"
                label="Participate in surveys to improve our service"
              />
            </ul>
            <p className="terms">
              Please allow 48 hours for your change to take effect. For further information of how
              Costa Ltd manages, protects and uses your information, please check our{' '}
              <a href="/privacy-policy">Privacy Policy</a> or{' '}
              <a href="/docs/your_costa_data.pdf" target="_blank">
                click here
              </a>{' '}
              for more details.
            </p>
          </fieldset>
          <div className="submitGroup">
            {saveButtonState(state)}
            {state === Status.Success && (
              <span className="success">Your preferences have been updated</span>
            )}
            {state === Status.Error && (
              <span className="error">Oops, something went wrong! Try again.</span>
            )}
          </div>
        </form>
      )}
    </Wrapper>
  )
}
