import React from 'react'
import Layout from '../../../../layout/layout'
import NutritionView from '../../../../legacy/nutrition/NutritionView/nutritionView'

function NutritionData({ location }) {
  return (
    <Layout location={location}>
      <NutritionView
        nutritionSmallPrint="<p><b>Yes&nbsp;</b>The allergen is present in the product.</p>
<p><b>C</b>&nbsp;Although this allergen is not an ingredient of the product, it is
present in the manufacturing site / factory / supply-chain and therefore there is a
risk of cross-contamination.</p>
<p><b>No&nbsp;</b>The allergen is not an ingredient of this product.</p>
<p><b>Yes/No</b>&nbsp;The column entitled 'Dietary Choices' details information supplied by the product
manufacturer. It does not take into account the in-store storage / preparation
methods where there is a risk of cross-contamination.</p>
<p>The allergens listed in this guide are those we are required to declare in
accordance with EU Food Information Regulations.</p>
<p>Please note that we cannot guarantee the absence&nbsp;of any allergen. As we used shared equipment in a busy environment, some products may not be suitable for those with severe allergies. Our menu constantly changes; please advise our Team every time you visit as we may be using substituted ingredients.</p>
"
      />
    </Layout>
  )
}

export default NutritionData
