import React, { useEffect, useState } from 'react'
import ReactMarkdownWithHtml from 'react-markdown/with-html'

import {
  infoTableRows,
  infoTableRowsJoint,
  allergensTableRows,
} from '../../helpers/nutritionTableHelpers'
import { CustomiseDrink } from '../../components/CustomiseDrink/customiseDrink'
import AllergenInfo from '../../components/AllergenInfo/allergenInfo'
import NutritionTable from '../../components/NutritionTable/nutritionTable'
import ButtonCTA from '../../../generic/ButtonCTA/buttonCTA'
import CompactAccordion from 'ui/src/CompactAccordion/CompactAccordion'
import infoIcon from '../../../../assets/icons/info-icon.svg'
import closeIcon from 'ui/src/assets/close-icon.svg'

import { getAvailableVariants } from './getAvailableVariants'
import { getActiveNutrition } from './getActiveNutrition'
import { getAvailableCustomisations } from './getAvailableCustomisations'
import { getInitialVariant } from './getInitialVariant'
import {
  StyledProductView,
  DataWrapper,
  CloseButton,
  CloseIcon,
  ImageWrapper,
  ProductDescription,
  AccordionsWrapper,
  AllergensAdvice,
  AllergenIconsWrapper,
  SmallPrint,
  Ingredients,
  CalorieMessage,
} from './productView.styles'
import type {
  ActiveNutrition,
  AvailableVariants,
  Product,
  ProductType,
  ActiveVariant,
  VariantType,
  ActiveCustomisations,
  Customisation,
  CustomisationType,
} from '../../types'
import { getInitialCustomisations } from './getInitialCustomisations'

type Props = {
  product: Product
  type: ProductType
  onClose: () => void
}

export default function ProductView(props: Props) {
  const { product, type: productType, onClose } = props
  const [isMobile, setIsMobile] = useState(false)

  //  Variants are the core size / milk combinations, derived from the product data 'nutrition' array
  const availableVariants = getAvailableVariants(product)
  const initialVariant = getInitialVariant({
    product,
    availableVariants,
  })
  const [activeVariant, setActiveVariant] = useState<ActiveVariant>(initialVariant)

  //  Customisations are the other possible variations & add-ons, derived from the product data 'allowedVariations' array
  const availableCustomisations = getAvailableCustomisations(product)
  const initialCustomisations = getInitialCustomisations({
    availableCustomisations,
  })
  const [activeCustomisations, setActiveCustomisations] =
    useState<ActiveCustomisations>(initialCustomisations)

  //  Nutriton is the nutritional data for the active variant combined with any active customisations
  const activeNutrition = getActiveNutrition({
    product,
    activeVariant,
    activeCustomisations,
  })

  useEffect(() => {
    if (!activeNutrition) {
      onClose()
    }
  }, [activeNutrition])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    document.body.style.overflow = 'hidden'
    window.costaDataLayer = window.costaDataLayer || {}
    window.costaDataLayer.product = { name: product.name }
    window._satellite?.track('nutrition-product-view')

    return () => {
      document.body.style.overflow = 'auto'
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const hasIngredients = activeNutrition?.ingredients
  // just checking two arbitrary allergens here that should always be "Yes", "No" or null
  const hasAllergens =
    activeNutrition?.dietaryChoices?.suitableForVegetarians && activeNutrition?.allergens?.peanut
  const hasNutritionPer100g = Object.values(activeNutrition?.nutritionPer100g || {}).some(v => v)
  const hasNutritionPerPortion = Object.values(activeNutrition?.nutritionPerPortion || {}).some(
    v => v
  )
  const hasNutrition = hasNutritionPer100g || hasNutritionPerPortion
  const showMarksAndSpencerNutritionalCta =
    product.brandName === 'M&S' && (!hasIngredients || !hasAllergens || !hasNutrition)

  const productImage = product.images.find((image: any) => {
    if (image.imageStyle === 'web-image') {
      return image
    }
    return null
  })

  function setVariant(variantType: VariantType, variant: string) {
    const updatedActiveVariant = { ...activeVariant }
    if (variant) {
      updatedActiveVariant[variantType] = variant
      setActiveVariant(updatedActiveVariant)
    }
  }

  function setCustomisation(customisationType: CustomisationType, customisation: Customisation) {
    const updatedCustomisations = Object.assign({}, activeCustomisations, {
      [customisationType]: customisation,
    })
    setActiveCustomisations(updatedCustomisations)
  }

  function handleResize() {
    setIsMobile(window.innerWidth < 768)
  }

  if (!activeNutrition) return null

  return (
    <StyledProductView>
      <CloseButton onClick={onClose}>
        <CloseIcon src={closeIcon} alt="Close icon" />
      </CloseButton>
      {productImage?.imageUrl && (
        <ImageWrapper>
          <div className="fixedImage">
            <img src={`${productImage?.imageUrl}`} alt={product.name} />
          </div>
        </ImageWrapper>
      )}
      <DataWrapper>
        <div className="content">
          <h1>{product.name}</h1>

          {/* Allergen Icons for drinks (only for mobile) */}
          {isMobile && productType === 'drinks' && hasAllergens && (
            <AllergenIconsWrapper className="reducedMargin">
              <AllergenInfo activeNutrition={activeNutrition} showTitle={false} />
            </AllergenIconsWrapper>
          )}

          <ProductDescription>
            {product.description && (
              <ReactMarkdownWithHtml allowDangerousHtml>
                {product.description}
              </ReactMarkdownWithHtml>
            )}
            <p>
              Check the{' '}
              {product.brandName === 'M&S' ? (
                <a href="https://swf3j.app.goo.gl/mands-menu" target="_blank" rel="noreferrer">
                  Costa Coffee app
                </a>
              ) : (
                <a href="https://swf3j.app.goo.gl/menu" target="_blank" rel="noreferrer">
                  Costa Coffee app
                </a>
              )}{' '}
              for availability at your local Costa store.
            </p>
          </ProductDescription>

          <AccordionsWrapper aria-label="Accordion Control Group Buttons">
            <CompactAccordion title="Allergens Information">
              <AllergensAdvice>
                <h2>Allergens Advice</h2>
                {showMarksAndSpencerNutritionalCta ? (
                  <>
                    <p>
                      For all non-Costa branded products that you can expect to find in our stores,
                      download the full nutrition and allergen guide here.
                    </p>
                    <ButtonCTA
                      customStyles="dataButtons"
                      type="button"
                      icon
                      link="https://www.costa.co.uk/docs/branded-nutritional-info.pdf"
                      value="See Nutritional Data (PDF)"
                      external
                      dataCy=""
                    />
                  </>
                ) : (
                  <>
                    <p>
                      We cannot guarantee that any of our products are free from allergens, due to
                      the use of shared equipment in a busy environment.
                    </p>
                    <p>Vegan products may not be suitable for persons with allergies.</p>
                    <ButtonCTA
                      customStyles="dataButtons"
                      type="button"
                      icon
                      link="https://www.costa.co.uk/behind-the-beans/nutrition"
                      value="See Our Store Data"
                      dataCy=""
                    />
                  </>
                )}
              </AllergensAdvice>

              {/* Allergen Icons */}
              {hasAllergens && (
                <AllergenIconsWrapper>
                  <AllergenInfo activeNutrition={activeNutrition} showTitle={false} />
                </AllergenIconsWrapper>
              )}

              {/* Allergens Table */}
              {hasAllergens && (
                <NutritionTable
                  title="Allergens Present"
                  display="noHeadings"
                  rows={allergensTableRows(activeNutrition)}
                />
              )}

              {/* Smallprint */}
              <SmallPrint>
                <p>
                  <strong>Yes</strong> The allergen is present in the product.
                </p>
                <p>
                  <strong>C</strong> Although this allergen is not an ingredient of the product, it
                  is present in the manufacturing site / factory / supply-chain and therefore there
                  is a risk of cross-contamination.
                </p>
                <p>
                  The allergens listed in this guide are those we are required to declare in
                  accordance with EU Food Information Regulations.
                </p>
              </SmallPrint>
            </CompactAccordion>

            {hasIngredients && (
              <CompactAccordion title="Ingredients">
                <Ingredients
                  dangerouslySetInnerHTML={{
                    __html: activeNutrition.ingredients.replace(
                      /([A-Z]{2,})/g,
                      '<strong>$1</strong>'
                    ),
                  }}
                />
              </CompactAccordion>
            )}

            {hasNutrition && (
              <CompactAccordion title="Nutritional Information">
                {hasNutritionPerPortion && (
                  <NutritionTable
                    mobile={isMobile}
                    title="Nutritional Information"
                    display="withHeadings"
                    {...nutritionalInfo(activeNutrition, availableVariants)}
                  />
                )}

                {!hasNutritionPerPortion && (
                  <NutritionTable
                    mobile={isMobile}
                    title="Nutritional Information"
                    display="withHeadings"
                    headings={[
                      null,
                      'Per 100g/ml',
                      `In-Store (${activeNutrition.inStorePortionWeight}ml)`,
                      `Take-Out (${activeNutrition.takeawayPortionWeight}ml)`,
                    ]}
                    rows={infoTableRowsJoint(activeNutrition)}
                  />
                )}
              </CompactAccordion>
            )}
          </AccordionsWrapper>

          {productType === 'drinks' &&
            ((availableVariants?.milk[0] !== undefined && availableVariants.milk[0] !== null) ||
              (availableVariants?.size[0] !== undefined && availableVariants.size[0] !== null)) && (
              <CustomiseDrink
                activeVariant={activeVariant}
                availableVariants={availableVariants}
                setVariant={setVariant}
                activeCustomisations={activeCustomisations}
                availableCustomisations={availableCustomisations}
                setCustomisation={setCustomisation}
              />
            )}

          <CalorieMessage>
            <div className="calorieMessageWrapper">
              <img src={infoIcon} alt="information icon" className="calorieMessageIcon" />
              <p className="calorieMessageText">Adults need around 2000 kcal a day</p>
            </div>
          </CalorieMessage>
        </div>
      </DataWrapper>
    </StyledProductView>
  )
}

function nutritionalInfo(activeNutrition: ActiveNutrition, availableVariants?: AvailableVariants) {
  const {
    nutritionPerPortion,
    inStoreNutrition,
    takeawayNutrition,
    inStorePortionWeight,
    takeawayPortionWeight,
  } = activeNutrition
  const weightUnit = availableVariants?.milk[0] || availableVariants?.size[0] ? 'ml' : 'g'
  const hasStoreTakeOut = Boolean(inStoreNutrition && takeawayNutrition)
  const baseHeadings = [null, 'Per 100g/ml']

  if (hasStoreTakeOut) {
    return {
      headings: [
        ...baseHeadings,
        displayWeightLabel('In Store', inStorePortionWeight, weightUnit),
        displayWeightLabel('Take-Out', takeawayPortionWeight, weightUnit),
      ],
      rows: infoTableRowsJoint(activeNutrition),
    }
  }

  return {
    headings: [
      ...baseHeadings,
      displayWeightLabel('Per Portion', nutritionPerPortion.portionWeight, weightUnit),
    ],
    rows: infoTableRows(activeNutrition),
  }
}

function displayWeightLabel(text: string, weight: number | null | undefined, unit: string) {
  return weight ? `${text} (${weight}${unit})` : ''
}
