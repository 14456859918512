import { cardIds } from 'cw-frontend/src/helpers/loyaltyHelper'
import { RequestOptions, makeRequest } from './fetch'

/**
 * This method can be used to check (crudely) the data we get back from an API call is valid.
 * This is because there are situations where the API returns a 200 but the data is not actually
 * there yet. For example when registering.
 */
const hasInvalidResponseData: Record<string, (data: any) => boolean> = {
  '/loyalty/v1/transactions': (data: CostaApi.Loyalty.TransactionsResponse) => {
    return data.data.length === 0
  },
  '/loyalty/v1/': (data: CostaApi.Loyalty.CustomerLoyaltyResponse) => {
    return cardIds(data).length === 0
  },
}

export const getBeansHistory = async () => {
  return makeRequest<CostaApi.Loyalty.BeanHistoryResponse>({
    path: '/loyalty/v1/beanhistory',
    verb: 'GET',
  })
}

export const getCustomerLoyalty = async () => {
  return makeRequest<CostaApi.Loyalty.CustomerLoyaltyResponse>(
    {
      path: '/loyalty/v1/',
      verb: 'GET',
    },
    hasInvalidResponseData
  )
}

export const getCustomerTransactions = async (params?: RequestOptions['params']) => {
  return makeRequest<CostaApi.Loyalty.TransactionsResponse>(
    {
      path: '/loyalty/v1/transactions',
      verb: 'GET',
      params: {
        pageSize: 5,
        ...params,
      },
    },
    hasInvalidResponseData
  )
}

export const getCustomerAccount = async () => {
  return makeRequest<CostaApi.Accounts.CustomerAccountResponse>({
    path: '/account/v1/',
    verb: 'GET',
  })
}

export const getCustomerPreferences = async () => {
  return makeRequest<CostaApi.Accounts.PermissionsResponse>({
    path: '/account/v1/permissions',
    verb: 'GET',
  })
}

export const patchCustomerAccount = async (body?: object) => {
  return makeRequest<CostaApi.Accounts.CustomerAccountResponse>({
    path: '/account/v1/',
    verb: 'PATCH',
    body,
  })
}

export const patchCustomerPreferences = async (body?: object) => {
  return makeRequest<CostaApi.Accounts.PermissionsResponse>({
    path: '/account/v1/permissions',
    verb: 'PATCH',
    body,
  })
}
