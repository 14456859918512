import React from 'react'

function Icon({ color = null, wrapped = false, className = '', w = 30, h = 30, icon = null }) {
  const styles = {
    svg: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    path: {
      fill: color,
    },
  }

  if (wrapped) {
    return (
      <div className={className}>
        <svg style={styles.svg} width={`${w}px`} height={`${h}px`} viewBox="0 0 1024 1024">
          <path style={styles.path} d={icon} />
        </svg>
      </div>
    )
  }
  return (
    <svg style={styles.svg} width={`${w}px`} height={`${h}px`} viewBox="0 0 1024 1024">
      <path style={styles.path} d={icon} />
    </svg>
  )
}

export default Icon
