import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Status } from '../utils/api/fetch'
import { ApiError } from '../utils/api/CustomErrors'
import { patchCustomerPreferences } from '../utils/api/loyalty'
import { convertDataInternalToApi, useAccountPreferences } from '../contexts/PreferenceContext'

export type FormData = CostaApi.Accounts.PermissionsInternal

export function usePreferencesForm() {
  const { preferences, setPreferences, loading } = useAccountPreferences()
  const { register, handleSubmit, formState, setValue } = useForm<FormData>({
    defaultValues: {
      marketingEmail: preferences?.marketingEmail,
      marketingAppPush: preferences?.marketingAppPush,
      survey: preferences?.survey,
    },
  })
  const { errors } = formState
  const [state, setState] = useState(Status.None)
  const onSubmit = handleSubmit(data => {
    const dataInApiFormat = convertDataInternalToApi(data)
    const submitData = async () => {
      try {
        const userPrefs = await patchCustomerPreferences(dataInApiFormat)

        if (userPrefs) {
          setPreferences(userPrefs)
          setState(Status.Success)
        }
      } catch (error) {
        if (error instanceof ApiError) {
          setState(Status.Error)
        }
      }
    }

    setState(Status.Updating)
    submitData()
  })

  useEffect(() => {
    if (preferences) {
      setValue('marketingEmail', preferences.marketingEmail)
      setValue('marketingAppPush', preferences.marketingAppPush)
      setValue('survey', preferences.survey)
    }
  }, [preferences])

  return {
    onSubmit,
    register,
    handleSubmit,
    formState,
    errors,
    state,
    setState,
    preferences,
    loading,
  }
}
