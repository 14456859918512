import React, { createContext, useState, useContext, useEffect } from 'react'
import { getCustomerPreferences } from '../utils/api/loyalty'

/**
 * NOTE the differences between the shape of the data we set vs what we
 * return. This is because setting is always done with data from the API
 * response which is in a very verbose shape so not ideal for using so
 * we convert it into a better shape to use in the app. This should really
 * be something the API does!
 */
export interface PreferencesContextType {
  preferences: CostaApi.Accounts.PermissionsInternal | undefined
  setPreferences: (preferences: CostaApi.Accounts.PermissionsResponse | undefined) => void
  loading: boolean
}

export const PreferencesContext = createContext<PreferencesContextType>({
  preferences: undefined,
  setPreferences: () => {},
  loading: true,
})

export const PreferencesProvider = ({ children }: { children: React.ReactNode }) => {
  const [preferencesInfo, setPreferencesInfo] = useState<
    CostaApi.Accounts.PermissionsResponse | undefined
  >(undefined)
  const [loading, setLoading] = useState(true)
  const preferencesTransformed = convertDataApiToInternal(preferencesInfo)
  const setPreferences = (data: CostaApi.Accounts.PermissionsResponse | undefined) => {
    setPreferencesInfo(data)
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      const prefData = await getCustomerPreferences()

      if (prefData) {
        setPreferences(prefData)
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const value = { preferences: preferencesTransformed, setPreferences, loading }

  return <PreferencesContext.Provider value={value}>{children}</PreferencesContext.Provider>
}

export const useAccountPreferences = () => useContext(PreferencesContext)

export function convertDataInternalToApi(data: CostaApi.Accounts.PermissionsInternal) {
  return {
    survey: {
      consent: data.survey ? 'GRANTED' : 'REFUSED',
    },
    marketing: {
      channels: {
        email: data.marketingEmail ? 'GRANTED' : 'REFUSED',
        appPush: data.marketingAppPush ? 'GRANTED' : 'REFUSED',
      },
    },
  }
}

export function convertDataApiToInternal(
  data: CostaApi.Accounts.PermissionsResponse | undefined
): CostaApi.Accounts.PermissionsInternal {
  return {
    marketingEmail: data?.marketing.channels.email === 'GRANTED',
    marketingAppPush: data?.marketing.channels.appPush === 'GRANTED',
    survey: data?.survey.consent === 'GRANTED',
  }
}
