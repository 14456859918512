import React from 'react'
import styled from 'styled-components'
import { color, above, costaText, costaTextBold } from 'ui/src/styles'

import Loading from 'ui/src/MapLoadingSpinner/mapLoadingSpinner'
import environmentsHelper from 'utils/src/environments'
import { getCategories, getProductsForCategory } from '../../helpers/GraphQL'
import { getChildren } from '../../helpers/nutritionTableHelpers'

import PageSelect from '../../components/PageSelect/pageSelect'
import ProductSelect from '../../components/ProductSelect/productSelect'
import DietFilters from '../../components/DietFilters/dietFilters'
import ProductCategories from '../../components/ProductCategories/productCategories'

import infoIcon from '../../../../assets/icons/info-icon.svg'

export default class CategoryView extends React.Component {
  state = {
    activePage: 'drinks', // SRTING of "food" or "drink"
    food: null, // ARRAY of food categories and products
    drinks: null, // ARRAY of drink categories and products
    list: { food: {}, drinks: {} },
    selectedCategory: null,
    selectedIds: null,
    activeFilters: {},
    useApiForFiltering: false,
    loadingCategories: true,
    loadingDrinks: true,
    loadingFood: true,
  }

  async componentDidMount() {
    const { food, drinks, list } = this.state
    const env = environmentsHelper.getEnvironment()

    const effectiveFromDate = new URLSearchParams(window.location.search).get('effectiveFromDate')

    if (!food && !drinks) {
      const categoryData = await getCategories(env)
      const food = getChildren('Food', categoryData)
      const drinks = getChildren('Drinks', categoryData)
      const categories = {
        food: { id: [], name: [], products: [] },
        drinks: { id: [], name: [], products: [] },
      }

      food[0].webProductCategories
        .sort((a, b) => a.priority - b.priority)
        .forEach(item => {
          categories.food.id.push(item.id)
          categories.food.name.push(item.name)
          item.masterProducts
            .sort((a, b) => a.webProductPriority - b.webProductPriority)
            .forEach(product => {
              categories.food.products.push(product)
            })
        })

      drinks[0].webProductCategories
        .sort((a, b) => a.priority - b.priority)
        .forEach(item => {
          categories.drinks.id.push(item.id)
          categories.drinks.name.push(item.name)
          item.masterProducts
            .sort((a, b) => a.webProductPriority - b.webProductPriority)
            .forEach(product => {
              categories.drinks.products.push(product)
            })
        })

      this.categoryMatch(drinks, 'drinks', categories, list, effectiveFromDate)
      this.categoryMatch(food, 'food', categories, list, effectiveFromDate)
      const selectedCategory = drinks

      this.setState({
        selectedCategory,
        selectedIds: categories.drinks.id,
        categories,
        loadingCategories: false,
        loadingDrinks: false,
      })
    }
  }

  cleanCopy = object => JSON.parse(JSON.stringify(object))

  categoryMatch = async (
    mainCategoryData,
    mainCategoryName,
    categories,
    list,
    effectiveFromDate
  ) => {
    const updatedList = { ...list }
    const updatedMainCategoryData = [...mainCategoryData]
    const products = await getProductsForCategory(
      categories[mainCategoryName].products.map(p => p.groupCode),
      effectiveFromDate
    )

    products.forEach(product => {
      const flatNutrition = product.nutritionProducts.map(nutrition => {
        const renamedDietaryObj = {
          vegetarian: nutrition.dietaryChoices?.suitableForVegetarians,
          vegan: nutrition.dietaryChoices?.suitableForVegans,
        }
        const containsNuts = nutrition.allergens?.treeNuts
        const mergedAllergens = {
          ...nutrition.allergens,
          ...{ containsNuts },
          ...renamedDietaryObj,
        }
        return {
          ...nutrition,
          allergens: { ...mergedAllergens },
        }
      })

      list[mainCategoryName][product.id] = {
        ...product,
        thumbnail: product.imageFullUri,
        nutrition: flatNutrition,
        name: product.productDisplayName,
        code: product.groupCode,
      }
    })
    mainCategoryData[0].webProductCategories.forEach(category => {
      category.masterProducts.forEach(product => {
        const listName = list[mainCategoryName][product.id]
        if (listName) listName.category = category.id
        if (product.id) {
          product.name = list[mainCategoryName][product.id]?.name
          product.images = list[mainCategoryName][product.id]?.images
          product.nutrition = list[mainCategoryName][product.id]?.nutrition || []
          product.code = list[mainCategoryName][product.id]?.code
          product.description = list[mainCategoryName][product.id]?.productDescription
          product.brandName = list[mainCategoryName][product.id]?.brandName
        }
      })
    })
    if (mainCategoryName === 'drinks') {
      this.setState({
        [mainCategoryName]: updatedMainCategoryData,
        list: updatedList,
        loadingDrinks: false,
      })
    } else {
      this.setState({
        [mainCategoryName]: updatedMainCategoryData,
        list: updatedList,
        loadingFood: false,
      })
    }
  }

  setFilter = async (filter, selectedIds, activePage, clear) => {
    const { activeFilters, categories, drinks, food } = this.state

    const categoriesToQuery =
      selectedIds.length === 1
        ? selectedIds
        : activePage === 'drinks'
        ? categories.drinks.id
        : categories.food.id

    const filterQuery = !clear ? { ...activeFilters } : {}

    if (filter) {
      if (filterQuery[filter]) {
        delete filterQuery[filter]
      } else {
        switch (filter) {
          case 'vegan':
          case 'vegetarian':
            filterQuery[filter] = 'Yes'
            break

          case 'treeNuts':
          case 'milk':
          case 'soya':
          case 'peanut':
          case 'egg':
          case 'fish':
          case 'crustacean':
          case 'mollusc':
          case 'sulphite':
          case 'sesame':
          case 'celery':
          case 'mustard':
          case 'lupin':
            filterQuery[filter] = 'No'
            break

          case 'cereals':
            if (filterQuery['cereals.wheat']) {
              delete filterQuery['cereals.wheat']
              delete filterQuery['cereals.rye']
              delete filterQuery['cereals.barley']
              delete filterQuery['cereals.oat']
            } else {
              filterQuery['cereals.wheat'] = 'No'
              filterQuery['cereals.rye'] = 'No'
              filterQuery['cereals.barley'] = 'No'
              filterQuery['cereals.oat'] = 'No'
            }
            break

          default:
            break
        }
      }
    }

    let filteredProducts = []
    const filterKeys = Object.keys(filterQuery)

    const mainCategoryData = activePage === 'drinks' ? this.cleanCopy(drinks) : this.cleanCopy(food)
    // / Create a list of products as in the API response
    selectedIds.forEach(categoryId => {
      mainCategoryData[0].webProductCategories.forEach(category => {
        if (categoryId === category.id) {
          category.masterProducts.forEach(product => {
            filteredProducts.push(product)
          })
        }
      })
    })

    // / Remove items based on filters
    // This modifies the 'nutrition' property of each product based on the currently selected filters,
    // and supports nested properties (eg. cereals allergen data).
    // The filter method keeps only those nutritionItem objects for which the value obtained from the
    // nested structure matches the corresponding value in the filterQuery object.
    filteredProducts.forEach(product => {
      filterKeys.forEach(f => {
        product.nutrition = product.nutrition?.filter(
          nutritionItem =>
            f.split('.').reduce((obj, key) => obj[key], nutritionItem.allergens) === filterQuery[f]
        )
      })
    })

    const filteredCategories = this.createSelected(activePage, filteredProducts, filterQuery)
    this.setState({
      activeFilters: !clear
        ? {
            ...filterQuery,
          }
        : {},
      selectedCategory: filteredCategories,
      selectedIds: categoriesToQuery,
      activePage,
    })
  }

  selectPageHandler = async (pageName, activePage) => {
    const { food, drinks, categories, activeFilters } = this.state
    if (pageName === activePage) {
      return
    }

    if (Object.keys(activeFilters).length === 0) {
      const selectedCategory = activePage === 'drinks' ? food : drinks
      this.setState({
        activePage: pageName,
        selectedIds: categories[pageName].id,
        selectedCategory,
      })
    } else {
      const filtersToQuery = categories[pageName].id
      this.setFilter(null, filtersToQuery, pageName)
    }
  }

  createSelected = (activePage, products, filterQuery) => {
    const { categories, list } = this.state
    const selected = categories[activePage].id.map((category, i) => ({
      id: categories[activePage].id[i],
      name: categories[activePage].name[i],
      masterProducts: [],
    }))
    products.forEach(product => {
      if (
        (product.brandName === 'M&S' && Object.keys(filterQuery).length === 0) ||
        product.nutrition.length > 0
      ) {
        const pid = product.id
        const index = selected.findIndex(category => list[activePage][pid].category === category.id)
        selected[index].masterProducts.push(product)
      }
    })
    return selected
  }

  selectCategoryHandler = id => {
    const { food, drinks, activePage, activeFilters, categories } = this.state
    const selectedCategory = activePage === 'drinks' ? drinks : food

    if (Object.keys(activeFilters).length === 0) {
      if (id !== 'All drinks' && id !== 'All food') {
        const updatedCategory = []
        updatedCategory.push(
          selectedCategory[0].webProductCategories.find(category => category.id === id)
        )
        this.setState({
          selectedCategory: updatedCategory,
          selectedIds: [id],
        })
      } else {
        this.setState({
          selectedCategory,
          selectedIds: categories[activePage].id,
        })
      }
    } else {
      this.setFilter(null, id ? [id] : categories[activePage].id, activePage)
    }
  }

  render() {
    const { setActiveProduct } = this.props
    const {
      loadingCategories,
      loadingFood,
      loadingDrinks,
      activePage,
      selectedCategory,
      selectedIds,
      activeFilters,
    } = this.state

    return (
      <NutritionWrapper>
        <NutritionView>
          {loadingCategories ? (
            <div className="loading">
              <Loading text="" />
            </div>
          ) : (
            <PageSelect
              selectPage={this.selectPageHandler}
              activePage={activePage}
              disabled={loadingDrinks}
              disabledFood={loadingFood}
            />
          )}
          <ProductSelect
            activeCategory={this.state[`${activePage}`]}
            activePage={activePage}
            selectedCategory={selectedCategory}
            selectCategory={this.selectCategoryHandler}
          />

          {loadingDrinks && (
            <div className="loading categories">
              <Loading text="Loading.." />
            </div>
          )}
          {((!loadingDrinks && activePage === 'drinks') ||
            (!loadingFood && activePage === 'food')) && (
            <>
              <DietFilters
                activeFilters={activeFilters}
                setFilter={this.setFilter}
                selectedIds={selectedIds}
                activePage={activePage}
              />
              <CalorieMessage>
                <div className="calorieMessageWrapper">
                  <h5>Allergens Advice</h5>
                  <p className="calorieMessageText">
                    We cannot guarantee that any of our products are free from allergens, due to the
                    use of shared equipment in a busy environment.
                  </p>
                  <p className="calorieMessageText">
                    Vegan products may not be suitable for persons with allergies.
                  </p>
                  <p className="calorieMessageText">
                    <img src={infoIcon} alt="information icon" className="calorieMessageIcon" />
                    Adults need around 2000 kcal a day
                  </p>
                </div>
              </CalorieMessage>
              <ProductCategories
                setActiveProduct={setActiveProduct}
                categories={selectedCategory}
                productPage={activePage}
              />
            </>
          )}
        </NutritionView>
      </NutritionWrapper>
    )
  }
}

const CalorieMessage = styled.div`
  ${costaText};
  .calorieMessageWrapper {
    display: flex;
    flex-direction: column;
    margin: 0;

    p {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 10px;
    }

    p:last-child {
      padding-top: 38px;
    }

    h5 {
      ${costaTextBold}
      font-size: 16px;
      color: ${color.costaRed};

      ${above.tablet`
      font-size: 18px;
    `}
    }

    ${above.tablet`
      width: 560px;
    `}
  }
  .calorieMessageIcon {
    padding-right: 10px;
  }

  .calorieMessageText {
    font-size: 12px;
    line-height: 20px;
    margin: 0;

    ${above.tablet`
      font-size: 14px;
    `}
  }
`

const NutritionWrapper = styled.div`
  margin: 0;

  ${above.tablet`
    padding: 0;
  `}

  .loading {
    color: ${color.lightRed};
    text-align: center;
    height: 121px;

    .categories {
      padding-top: 30px;
    }

    > div {
      position: relative !important;
      margin: 0 auto;
      top: 50px;
      left: auto;
    }
  }
`

const NutritionView = styled.div`
  ${costaText};
  background: ${color.white};
  padding: 0 16px;
  color: ${color.greyDarker};
  box-sizing: border-box;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  max-width: 674px;
  margin: auto;

  ${above.tablet`
    padding: 0;
    max-width: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  `}

  ${above.tabletLarge`
    max-width: 858px;
  `}

  ${above.desktopLarge`
    padding: 0 64px;
    max-width: 1274px;
  `}
`
