import React from 'react'
import { color as styleColor } from 'ui/src/styles/index'

interface SvgProps {
  color?: string
}

export const Tick = (props: SvgProps) => {
  const { color = styleColor.offWhite } = props

  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path fill={color} d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
    </svg>
  )
}
